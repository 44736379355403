import BaseService from "./BaseService";

class SearchToolsService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }
  GetEmployeesByEID(eid, isExact) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}profiles?eid=${eid}&isExact=${isExact}`,
      this.config
    );
  }
  GetConfigs() {
    let self = this;
    return self.axios.get(`${self.baseUrl}configs`, this.config);
  }
  GetSearchtoolsUsers() {
    return this.axios.get(`${this.baseUrl}allstusers`, this.config);
  }
  GetStUser() {
    return this.axios.get(`${this.baseUrl}stuser`, this.config);
  }
  GetStUserData(eid) {
    return this.axios.get(`${this.baseUrl}stuserdata/${eid}`, this.config);
  }
  ChangeStUserRole(reqBody) {
    return this.axios.post(
      `${this.baseUrl}changestuserrole`,
      reqBody,
      this.config
    );
  }
  SetSearchtoolsUsers(users) {
    return this.axios.post(`${this.baseUrl}setstusers`, users, this.config);
  }
  SearchCuratedDocuments(from, count, term, searchField) {
    let self = this;
    return self.axios.post(
      `${self.baseUrl}curated/documents/search`,
      { term: term, size: count, from: from, searchField: searchField },
      this.config
    );
  }
  ExportRequests(filterBy, sortField, sortBy, type, isActive = false) {
    let self = this;
    let url;

    if (isActive && type === "bestbet") {
      url = `${
        self.baseUrl
      }${type}/requests/export?filterBy=${null}&sortField=${null}&sortBy=${null}&active=${isActive}`;
    } else if (!isActive && type === "bestbet") {
      url = `${
        self.baseUrl
      }${type}/requests/export?filterBy=${filterBy}&sortField=${sortField}&sortBy=${sortBy}&active=${false}`;
    } else {
      url = `${self.baseUrl}${type}/requests/export?filterBy=${filterBy}&sortField=${sortField}&sortBy=${sortBy}`;
    }

    return self.axios({
      url: url,
      method: "GET",
      headers: this.config.headers
    });
  }
  ExportCuratedDocuments(term, filterBy, totalRows, filters = null) {
    let self = this;
    return self.axios.post(
      `${
        self.baseUrl
      }curated/documents/export?filterBy=${filterBy}&size=${totalRows}&term=${encodeURIComponent(
        term
      )}`,
      filters,
      { method: "GET", headers: this.config.headers }
    );
  }
  GetAppMode() {
    return this.axios.get(`${this.baseUrl}appmode`, this.config);
  }
  PostUserFeedback(body) {
    return this.axios.post(`${this.baseUrl}feedback`, body, this.config);
  }

  GetFeedback(fromDate, toDate) {
    return this.axios.get(
      `${this.baseUrl}rating?fromDate=${fromDate}&toDate=${toDate}`,
      this.config
    );
  }
}
export default SearchToolsService;
