import { Tooltip } from "bootstrap";

const deleteOldTooltips = () => {
  //delete unparented tooltips from the dom
  var oldTooltipTriggerList = [].slice.call(
    document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
  );

  oldTooltipTriggerList.map(function (tooltipTriggerEl) {
    tooltipTriggerEl.remove();
  });
};

const instantiateTooltips = (reqType) => {
  try {
    deleteOldTooltips();
  } catch (error) {
    console.log("no old tooltips");
  }

  // this array has the selectors for each kind of tooltip elements
  const dataBsToggleArray = [
    `[data-bs-toggle="${reqType}-tooltip"]`,
    '[data-bs-toggle="red-pills-tooltip"]',
    '[data-bs-toggle="synonym-tooltip"]',
    '[data-bs-toggle="plus-icon-tooltip"]',
    '[data-bs-toggle="synonym-suggestion-tooltip"]',
    '[data-bs-toggle="gen-ai-rocket-tooltip"]'
  ];

  let tooltipClass = "";
  let currMode = localStorage.getItem("theme");
  tooltipClass = currMode === "dark" ? "form-tooltip-dark" : "";
  let rocketTooltipClass =
    currMode === "dark" ? "gen-ai-rocket-tooltip" : "gen-ai-rocket-tooltip";

  // initialize tooltips, add as many as you need on the dataBsToggleArray
  for (let i = 0; i < dataBsToggleArray.length; i++) {
    const tooltipSelector = dataBsToggleArray[i];
    try {
      let tooltipTriggerList = null;
      tooltipTriggerList = [].slice.call(
        document.querySelectorAll(tooltipSelector)
      );

      if (tooltipSelector === `[data-bs-toggle="gen-ai-rocket-tooltip"]`) {
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new Tooltip(tooltipTriggerEl, {
            trigger: "hover focus",
            customClass: rocketTooltipClass
          });
        });
      } else {
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new Tooltip(tooltipTriggerEl, {
            trigger: "hover focus",
            customClass: tooltipClass
          });
        });
      }
    } catch (error) {}
  }
};

const createNewTooltip = (event) => {
  deleteOldTooltips();
  let tooltipClass = "";
  let currMode = localStorage.getItem("theme");
  tooltipClass = currMode === "dark" ? "form-tooltip-dark" : "";
  new Tooltip(event.target, {
    trigger: "hover focus",
    customClass: tooltipClass
  });
};

const TooltipManager = {
  instantiateTooltips,
  createNewTooltip
};

export default TooltipManager;
