import React, { useState } from "react";
import "../GenAIDescription/genAiModal&Button.css";
import FieldLabelAndIcon from "./FieldLabelAndIcon";
import rocketImage1 from "../../../../assets/images/rocket_01.svg";
import rocketImage2 from "../../../../assets/images/rocket_02.svg";
import rocketDisabled from "../../../../assets/images/rocket_disabled.svg";
import GenAiLoadingSpinner from "../GenAIDescription/GenAiLoadingSpinner";
import ModalCustom from "../../../Modal/ModalCustom";

const UrlField = ({
  inputType,
  labelText,
  reqType,
  placeholderText,
  ariaLabelText,
  tooltipText,
  isRequired,
  isDisabledProp,
  disableGenIaBtn,
  urlCheckerService,
  validateField,
  updateFormState,
  checkToloadFromUrl,
  showGenAiSpinner,
  openGenAiModal,
  showAiWarning,
  hideGenAiFeature,
  showGenAiOptions,
  setShowGenAiOptions,
  currFormData,
  cssTheme
}) => {
  const inputID = `${reqType}-${inputType}-input-field`;
  const errorMsgBoxID = `${reqType}-${inputType}-error-msg-box`;
  const genAiMsgID = `genai-warning-msg-box`;

  const rocketImage = false ? rocketImage2 : rocketImage1;

  const rocketImg = () => {
    return disableGenIaBtn ? rocketDisabled : rocketImage;
  };
  const enableGenAiOptions = () => {
    return !disableGenIaBtn;
  };
  const tooltipBody = `<div class="summarize-description" data-testid="summarize-tooltip">
        <p class="tooltip-header"><b>Gen AI</b></p>
        <p class="tooltip-info">
          Generate descriptions and keywords with Gen AI
        </p>
    </div>`;
  const genAiWarningMsg =
    "This URL cannot be reached by Gen AI. You may use the form traditionally.";
  const [timer, setTimer] = useState(null);

  const [showKwModal, setShowKwModal] = useState(false);
  const [kwModalMsg, setKwModalMsg] = useState("");

  const urlMainDivClass = `kw-div-elements-holder-selector ${reqType}-url-field-extra-margin `;

  // methods
  const updateUIElements = (_validationResult, _userInput) => {
    let domField = null;
    let errorMessageDiv = null;

    domField = document.getElementById(inputID);
    if (_validationResult.isValid) {
      try {
        errorMessageDiv = document.getElementById(errorMsgBoxID);
        errorMessageDiv.textContent = "";
        errorMessageDiv.hidden = true;
        domField.classList.remove("is-invalid");
      } catch (error) {
        console.log("error 1 on url field.");
      }

      domField.classList.add("is-valid");
    } else if (!_validationResult.isValid) {
      try {
        domField.classList.remove("is-valid");
      } catch (error) {
        console.log("error 2 on url field.");
      }

      if (_userInput.length !== 0) {
        domField.classList.add("is-invalid");
        //show error msg if any
        try {
          if (_validationResult.errorMsg.length > 0) {
            errorMessageDiv = document.getElementById(errorMsgBoxID);
            errorMessageDiv.textContent = _validationResult.errorMsg;
            errorMessageDiv.hidden = false;
          }
        } catch (error) {
          console.log("error 3 on url field.");
        }
      } else if (_userInput.length === 0) {
        try {
          domField.classList.remove("is-invalid");
          if (_validationResult.errorMsg.length > 0) {
            errorMessageDiv = document.getElementById(errorMsgBoxID);
            errorMessageDiv.textContent = "";
            errorMessageDiv.hidden = true;
          }
        } catch (error) {
          console.log("error 4 on url field.");
        }

        try {
          let divHolder = document.querySelector(
            ".kw-div-elements-holder-selector"
          );
          let divUrlAndGenAi = document.querySelector(
            ".div-url-and-gen-ai-container-selector"
          );
          if (divHolder.classList.contains("show-genai-options-position")) {
            divHolder.classList.remove("show-genai-options-position");
            divUrlAndGenAi.classList.remove("div-url-and-gen-ai-container");
            let currState = showGenAiOptions;
            setShowGenAiOptions(!currState);
          }
        } catch (error) {}
      }
    }
  };

  const processUserInput = async (_userInput) => {
    try {

      let validationResult = false;

      switch (reqType) {
        case "BB":
            validationResult = await validateField(_userInput, urlCheckerService);
          break;
        case "TC":
            validationResult = await validateField(_userInput);
            if(_userInput.length === 0){
              validationResult.isValid = false;
              validationResult.errorMsg = "hide error msg";
            }
          break;
      
        default:
          break;
      }

      updateUIElements(validationResult, _userInput);
      updateFormState(inputType, _userInput, validationResult);
    } catch (error) {
      console.log("error processUserInput url field.", error);
    }
  };

  const getFieldValue = (event) => {
    const userInput = event.target.value;
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      processUserInput(userInput);
    }, 900);

    setTimer(newTimer);
  };

  const onClickModalHandler = () => {
    setShowKwModal(false);
  };

  const genAiOptionsBox = (
    <div>
      <fieldset id="gen-ai-options-menu" className="gen-ai-options-menu">
        <div className="genai-button-div-top">
          <button
            id="gen-ai-options-close"
            className="gen-ai-options-btn"
            aria-label="Close the gen ai options menu"
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();

              let currState = showGenAiOptions;
              let cssTrigger = !currState;
              setShowGenAiOptions(!currState);

              try {
                let divHolder = document.querySelector(
                  ".kw-div-elements-holder-selector"
                );
                let divUrlAndGenAi = document.querySelector(
                  ".div-url-and-gen-ai-container-selector"
                );
                if (cssTrigger) {
                  divHolder.classList.add("show-genai-options-position");
                  divUrlAndGenAi.classList.add("div-url-and-gen-ai-container");
                } else {
                  divHolder.classList.remove("show-genai-options-position");
                  divUrlAndGenAi.classList.remove(
                    "div-url-and-gen-ai-container"
                  );
                }
              } catch (error) {}
            }}
          >
            x
          </button>
        </div>
        <div className="div-radiobtn-and-label">
          <input
            id="gen-ai-option-radio-btn-description"
            className="gen-ai-option-radio-btn"
            type="radio"
            name="optionRadioBtn"
            value="description"
            defaultChecked={true}
          />
          <label htmlFor="gen-ai-option-radio-btn-description">
            &nbsp;Generate Description
          </label>
        </div>

        <div className="div-radiobtn-and-label">
          <input
            id="gen-ai-option-radio-btn-keywords"
            className="gen-ai-option-radio-btn"
            type="radio"
            name="optionRadioBtn"
            value="keywords"
          />
          <label htmlFor="gen-ai-option-radio-btn-keywords">
            &nbsp;Extract Keywords
          </label>
        </div>
        <div className="gen-ai-options-separator-1"></div>
        <div className="genai-button-div">
          <button
            id="gen-ai-options-btn"
            className="gen-ai-options-btn"
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();

              let currLocationsSelected = null;
              let currRefinersSelected = null;
              let keywordsRadioButton = document.getElementById(
                "gen-ai-option-radio-btn-keywords"
              );

              if (currFormData.countries.hasOwnProperty("value")) {
                currLocationsSelected = [...currFormData.countries.value];
              }
              if (currFormData.refinerPages.hasOwnProperty("value")) {
                currRefinersSelected = [...currFormData.refinerPages.value];
              }

              if (
                (currRefinersSelected.length === 0 ||
                  currLocationsSelected.length === 0) &&
                keywordsRadioButton.checked
              ) {
                setKwModalMsg("Please select a Location and a Refiner first.");
                setShowKwModal(true);
                return;
              }

              let greenLen = currFormData.keywordsObj.keywords.value.length;
              let redLen =
                currFormData.keywordsObj.keywordsforreview.value.length;
              let totalLen = parseInt(greenLen) + parseInt(redLen);
              if (totalLen >= 20 && keywordsRadioButton.checked) {
                setKwModalMsg("Only 20 keywords are allowed");
                setShowKwModal(true);
                return;
              }
              openGenAiModal(e, disableGenIaBtn);
            }}
          >
            Generate
          </button>
        </div>
      </fieldset>
      <div className="gen-ai-options-separator-2"></div>
      <div className="gen-ai-options-separator-top2"></div>
    </div>
  );

  const genAIRocketBtn = (
    <>
      {showGenAiSpinner && !hideGenAiFeature && <GenAiLoadingSpinner />}
      <button
        className="gen-ai-rocket-button gen-ai-rocket-button-selector"
        onClick={(e) => {
          e.preventDefault();

          let currState = showGenAiOptions;
          let cssTrigger = !currState;
          setShowGenAiOptions(!currState);

          try {
            let divHolder = document.querySelector(
              ".kw-div-elements-holder-selector"
            );
            let divUrlAndGenAi = document.querySelector(
              ".div-url-and-gen-ai-container-selector"
            );
            if (cssTrigger) {
              divHolder.classList.add("show-genai-options-position");
              divUrlAndGenAi.classList.add("div-url-and-gen-ai-container");
            } else {
              divHolder.classList.remove("show-genai-options-position");
              divUrlAndGenAi.classList.remove("div-url-and-gen-ai-container");
            }
          } catch (error) {}
        }}
        data-testid="gen-ai-rocket-button"
        id="gen-ai-rocket-button"
        aria-label={`Generate descriptions and keywords with Gen AI`}
        aria-describedby="gen-ai-rocket-tooltip"
        disabled={disableGenIaBtn}
      >
        <img
          src={rocketImg()}
          alt="Rocket"
          role="tooltip"
          data-bs-html="true"
          data-bs-toggle="gen-ai-rocket-tooltip"
          data-bs-placement="right"
          data-bs-title={tooltipBody}
          id="rocket-image"
        />
      </button>
    </>
  );

  return (
    <div id={`${reqType}-${inputType}-input-field-div`}>
      {showKwModal ? (
        <ModalCustom
          onClick={onClickModalHandler}
          onClose={onClickModalHandler}
          modal_title={"Info"}
          modal_msg={kwModalMsg}
          secondOption={false}
          btn_1_class={
            cssTheme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
        />
      ) : (
        <div></div>
      )}
      <div className={urlMainDivClass}>
        <FieldLabelAndIcon
          reqType={reqType}
          inputType={inputType}
          inputID={`${inputID}-label`}
          isRequired={isRequired}
          labelText={labelText}
          tooltipText={tooltipText}
        />
        <div
          className="div-url-and-gen-ai-container-selector"
          style={{ display: "flex" }}
        >
          <input
            id={inputID}
            type="text"
            autoComplete="off"
            className={`form-control `}
            data-testid={`${reqType}-${inputType}-text-input-field`}
            placeholder={placeholderText}
            aria-label={ariaLabelText}
            onChange={getFieldValue}
            onBlur={checkToloadFromUrl}
            disabled={isDisabledProp}
          />
          {genAIRocketBtn}
          {showGenAiOptions && enableGenAiOptions() ? genAiOptionsBox : <></>}
        </div>
      </div>
      {hideGenAiFeature ? (
        <></>
      ) : (
        <div
          id={genAiMsgID}
          style={{
            visibility: showAiWarning ? "visible" : "hidden",
            color: "var(--light-mode-genaiwarning-text-color)"
          }}
          className={`invalid-field-div${showAiWarning ? "" : "-hidden"}`}
        >
          {genAiWarningMsg}
        </div>
      )}
      <div id={errorMsgBoxID} className="invalid-field-div"></div>
    </div>
  );
};

export default UrlField;
