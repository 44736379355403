export const copyToClipboard = (event) => {
  let newTextInput = document.createElement("input");
  newTextInput.value = event.target.textContent;
  newTextInput.style.position = "fixed";
  newTextInput.style.top = "0";
  newTextInput.style.left = "0";

  document.body.appendChild(newTextInput);
  newTextInput.focus();
  newTextInput.select(newTextInput.value);
  newTextInput.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(newTextInput);
};
