const PostComments = (request) => {
  let comments = [];
  let autoimplement = `This request was submitted and implemented by ${request.user}`;
  let submitMessage = `This request was submitted by ${request.user}`;
  let removalMessage = `This request was removed by ${request.user}`;
  let status =
    request.action === "Save Changes"
      ? "modified"
      : request.status.toLowerCase();
  let typeStatusMessage = `This request was ${status} by ${request.user}`;

  switch (request.action) {
    case "Request Best Bet":
    case "Request How-To":
    case "Request Update":
    case "Submit for Removal":
    case "Request Topic Card":
      comments.unshift(SaveComment(submitMessage, "System"));
      break;
    case "Implement":
      if (!request.requestId) {
        comments.unshift(SaveComment(autoimplement, "System"));
      } else if (request.reqType === "Removal") {
        comments.unshift(SaveComment(removalMessage, "System"));
      } else {
        comments.unshift(SaveComment(typeStatusMessage, "System"));
      }
      break;
    case "Remove Best Bet":
    case "Remove":
    case "Remove Topic Card":
      comments.unshift(SaveComment(removalMessage, "System"));
      break;
    case "Cancel Request":
    case "Cancel":
    case "Reject":
      comments.unshift(SaveComment(typeStatusMessage, "System"));
      break;
    case "Save Changes":
      comments.unshift(SaveComment(typeStatusMessage, "System"));
      break;
  }
  return comments;
};
const PostDelegatesComments = (delegatesInfo) => {
  let addedUsers = [];
  let removedUsers = [];
  let newDelegates = delegatesInfo.newDelegates;
  let originalDelegates = delegatesInfo.originalDelegates;
  let user = delegatesInfo.user;
  let form = delegatesInfo.formType;

  for (let i = 0; i < newDelegates.length; i++) {
    const newEid = newDelegates[i];
    if (originalDelegates.indexOf(newEid) === -1) {
      addedUsers.push(newEid);
    }
  }

  for (let i = 0; i < originalDelegates.length; i++) {
    const oldEid = originalDelegates[i];
    if (newDelegates.indexOf(oldEid) === -1) {
      removedUsers.push(oldEid);
    }
  }

  let finalMsg = `Delegates were modified : \n${user} `;
  let addedUsersComment = ` added ${form} delegate${
    addedUsers.length > 1 ? "s: " : ": "
  } ${addedUsers.join(", ")} \n`;
  let removedUsersComment = ` removed ${form} delegate${
    removedUsers.length > 1 ? "s: " : ": "
  } ${removedUsers.join(", ")} \n`;

  if (addedUsers.length > 0) {
    finalMsg += addedUsersComment;
  }

  if (removedUsers.length > 0) {
    finalMsg += removedUsersComment;
  }
  let comment = SaveComment(finalMsg, "System");
  return comment;
};
const SaveComment = (message, user) => {
  let comment = {
    comment: message,
    user: user,
    dateUTC: new Date().toISOString()
  };
  return comment;
};

export { SaveComment, PostComments, PostDelegatesComments };
