import BaseService from "./BaseService";

class HowToService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  GetHowToCardByID(id) {
    let self = this;
    return self.axios.get(`${self.baseUrl}howto/${id}`, this.config);
  }

  GetHowToRequestById(id) {
    return this.axios.get(`${this.baseUrl}howtoget/request/${id}`, this.config);
  }

  GetHowToRequests(eid) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}howto/requests/owner/${eid}`,
      self.config
    );
  }

  GetHTDataMetrics(fromDate, toDate) {
    return this.axios.get(
      `${this.baseUrl}ht/metrics?fromDate=${fromDate}&toDate=${toDate}`,
      this.config
    );
  }

  GetHowToCardsByKeyword(keyword, aid, countries) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}howtocards?keyword=${encodeURIComponent(
        keyword
      )}&aid=${aid}&countries=${countries}`,
      this.config
    );
  }

  GetHowToByTitle(title) {
    let self = this;
    return self.axios.post(`${self.baseUrl}howto`, { title }, self.config);
  }

  URLRegExp = /^(http:\/\/|https:\/\/)/;
  URLRegExp2 =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  internalSiteRx =
    /(http|https):\/\/(in.accenture.com+|in.ciostage.accenture.com+)/;
  kxContributionRx =
    /(http|https):\/\/(kxdocuments.accenture.com|kxdocuments.ciostage.accenture.com)\/(contribution)/;
  collectionsRx =
    /(http|https):\/\/(collections.accenture.com|collections.ciostage.accenture.com)\/(collection.aspx[?]b=)/;
  myLearningRx = /(https?):\/\/(mylearning(-pi)?.accenture.com)/;

  GetInternalSitesData(url, internalSiteHome) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}internalsites?url=${url}&internalSiteHome=${internalSiteHome}`,
      self.config
    );
  }
  GetKXContributionHits(url) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}kxdocument/contribution?url=${url}`,
      self.config
    );
  }
  GetCollectionHits(url) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}urlchecker/collection?url=${url}`,
      self.config
    );
  }
  GetMyLearningHits(url) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}urlchecker/mylearning?url=${url}`,
      self.config
    );
  }
  GetUrlDates(urlsArr) {
    let self = this;
    return self.axios.post(`${self.baseUrl}urldate`, urlsArr, self.config);
  }
  SaveHowTo(howtorequest, reqid, htid) {
    let self = this;
    if (reqid) {
      return self.axios.post(
        `${self.baseUrl}howto/request/${reqid}?htid=${htid}`,
        howtorequest,
        this.config
      );
    } else {
      return self.axios.post(
        `${self.baseUrl}howto/request`,
        howtorequest,
        this.config
      );
    }
  }
  GetRequests(from, size, filteredBy, sortField, sortBy) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}howto/requests?from=${from}&size=${size}&filterBy=${filteredBy}&sortField=${sortField}&sortBy=${sortBy}`,
      this.config
    );
  }

  GenerateHtmlContent(reqData) {
    return this.axios.post(
      `${this.baseUrl}howto/preview`,
      reqData,
      this.config
    );
  }
}

export default HowToService;
