import "./Metrics.css";
import Checkbox from "./Checkbox";
import MetricsDate from "./MetricsDate";
import MetricsButton from "./MetricsButton";
import MetricsMenu from "./MetricsMenu";
import StoreContext from "../../store/storecontext";
import { useState, useEffect, useContext } from "react";
import ModalCustom from "../Modal/ModalCustom";
import { ThemeContext } from "../../ThemeContext";
import { formatDate as customFormatDate } from "../FormatDate/DateFormat";
import { UrlTable } from "./components/UrlTable";
import BarChart from "./charts/BarChart";
import CsvDownloadBtn from "./csvDownload/CsvDownloadBtn";
import { Tooltip } from "bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

const Metrics = () => {
  const theme = useContext(ThemeContext);
  const [openModal, setOpenModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [htRequests, setHtRequests] = useState({});
  const [tcRequests, setTcRequests] = useState({});
  const [bbRequests, setBbRequests] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [bbOption, setBbOption] = useState(false);
  const [tcOption, setTcOption] = useState(false);
  const [htOption, setHtOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const store = useContext(StoreContext);
  const minDate = new Date("2022");
  const maxDate = !fromDate ? new Date() : new Date(fromDate + 12);

  if (toDate) {
    //if user selected a maximum date from picker
    let yearBefore = toDate.getMonth() - 12;
    minDate.setMonth(yearBefore);
  }
  if (fromDate) {
    //if user selected minimum date from picker
    let yearAfter = fromDate.getMonth() + 12;
    maxDate.setMonth(yearAfter);
  }
  const formatDate = (date, type) => {
    if (!date) return "";
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${type === "from" ? "01" : 31}`;
  };

  const getHTDataMetrics = async () => {
    await store.services.howToService
      .GetHTDataMetrics(formatDate(fromDate, "from"), formatDate(toDate, "to"))
      .then((r) => {
        let htReq = r.data.otherData.aggregations;
        let urls = r?.data?.urls?.hits?.hits;
        let htRequests = {
          refinerPage: htReq.refinerpage.buckets,
          countries: htReq.cleancountry.buckets,
          uniqueUser: htReq.unique_users.buckets,
          urls: urls
            ?.filter(
              (data) => data._source.cleanurl && data._source.cleancreationdate
            )
            .map((data) => ({
              url: data._source.cleanurl,
              submittedDate: customFormatDate(data._source.cleancreationdate)
            })),
          htReqStatus: htReq.requeststatus.buckets,
          creationDate: htReq.creation.buckets,
          lastModDate: htReq.lastmodificationdate.buckets
        };
        setHtRequests(htRequests);
      })
      .catch((error) => {
        console.log("error on getting tc request data");
        throw error;
      });
  };

  const getTCDataMetrics = async () => {
    await store.services.topicCardService
      .GetTCDataMetrics(formatDate(fromDate, "from"), formatDate(toDate, "to"))
      .then((r) => {
        let tcReq = r.data.otherData.aggregations;
        let urls = r?.data?.urls?.hits?.hits;
        let tcRequests = {
          refinerPage: tcReq.refinerpage.buckets,
          countries: tcReq.cleancountry.buckets,
          uniqueUser: tcReq.unique_users.buckets,
          urls: urls
            ?.filter((data) => data._source.url && data._source.creationdate)
            .map((data) => ({
              url: data._source.url,
              submittedDate: customFormatDate(data._source.creationdate)
            })),
          tcReqStatus: tcReq.requeststatus.buckets,
          creationDate: tcReq.creation.buckets,
          lastModDate: tcReq.lastmodificationdate.buckets
        };
        setTcRequests(tcRequests);
      })
      .catch((error) => {
        console.log("error on getting tc request data");
        throw error;
      });
  };

  const getBBDataMetrics = async () => {
    await store.services.bestBetService
      .GetBBDataMetrics(formatDate(fromDate, "from"), formatDate(toDate, "to"))
      .then((r) => {
        let bbReq = r.data.otherData.aggregations;
        let urls = r?.data?.urls?.hits?.hits;
        let bbRequests = {
          refinerPage: bbReq.refinerpage.buckets,
          countries: bbReq?.cleancountry?.buckets,
          uniqueUser: bbReq.unique_users.buckets,
          urls: urls
            ?.filter(
              (data) => data._source.cleanurl && data._source.cleancreationdate
            )
            .map((data) => ({
              url: data._source.cleanurl,
              submittedDate: customFormatDate(data._source.cleancreationdate)
            })),
          bbReqStatus: bbReq.requeststatus.buckets,
          creationDate: bbReq.creation.buckets,
          lastModDate: bbReq.lastmodificationdate.buckets
        };
        setBbRequests(bbRequests);
      })
      .catch((error) => {
        console.log(error + " error on getting bb request data");
        throw error;
      });
  };

  const resetMenuTabs = () => {
    try {
      let myActiveTab = document.querySelector(
        ".nav-link.menu-tab.active-menu-tab"
      );
      myActiveTab.classList.remove("active-menu-tab");
    } catch (error) {}
  };
  const setMenuTabAsActive = (_value) => {
    try {
      let selectedTab = null;
      switch (_value) {
        case "url":
          selectedTab = document.querySelector(".nav-link.menu-tab.m-opt-url");
          selectedTab.classList.add("active-menu-tab");
          break;
        case "submitedRqsts":
          selectedTab = document.querySelector(
            ".nav-link.menu-tab.m-opt-submittedrq"
          );
          selectedTab.classList.add("active-menu-tab");
          break;
        case "targetRefinerPage":
          selectedTab = document.querySelector(
            ".nav-link.menu-tab.m-opt-targetrefiner"
          );
          selectedTab.classList.add("active-menu-tab");
          break;
        case "targetCountry":
          selectedTab = document.querySelector(
            ".nav-link.menu-tab.m-opt-targetcountry"
          );
          selectedTab.classList.add("active-menu-tab");
          break;
        case "status":
          selectedTab = document.querySelector(
            ".nav-link.menu-tab.m-opt-status"
          );
          selectedTab.classList.add("active-menu-tab");
          break;
        case "uniqueUsers":
          selectedTab = document.querySelector(
            ".nav-link.menu-tab.m-opt-uniqueusers"
          );
          selectedTab.classList.add("active-menu-tab");
          break;

        default:
          break;
      }
    } catch (error) {}
  };

  const handleMenuChange = (value) => {
    resetMenuTabs();
    setMenuTabAsActive(value);
    setShowChart(true);
    setSelectedOption(value);
  };

  const isMetricsBtnDisabled = () => {
    if (!toDate || !fromDate) {
      return true;
    }
    if (!bbOption && !tcOption && !htOption) {
      return true;
    }
    return false;
  };

  const showMetrics = async () => {
    setIsLoading(true);
    try {
      if (tcOption) {
        await getTCDataMetrics();
      }
      if (bbOption) {
        await getBBDataMetrics();
      }
      if (htOption) {
        await getHTDataMetrics();
      }
      setShowMenu(true);
      setIsLoading(false);
      try {
        let myActiveTab = document.querySelector(
          ".nav-link.menu-tab.active-menu-tab"
        );
        myActiveTab.click();
      } catch (error) {}
    } catch (error) {
      setIsLoading(false);
      setOpenModal(true);
      console.log("An error occurred", error);
    }
  };

  const onClickModal = () => {
    setOpenModal(false);
  };

  const toggleCheckboxLabelStyle = (_option, _labelEl) => {
    try {
      _option
        ? _labelEl.classList.add("checkbox-selected-bold")
        : _labelEl.classList.remove("checkbox-selected-bold");
    } catch (error) {}
  };

  const setCheckboxStyles = () => {
    let elId_bb = `metrics-st-Best Bet-label`;
    let labelEl_bb = document.getElementById(elId_bb);
    toggleCheckboxLabelStyle(bbOption, labelEl_bb);

    let elId_tc = `metrics-st-Topic Card-label`;
    let labelEl_tc = document.getElementById(elId_tc);
    toggleCheckboxLabelStyle(tcOption, labelEl_tc);

    let elId_ht = `metrics-st-How-To-label`;
    let labelEl_ht = document.getElementById(elId_ht);
    toggleCheckboxLabelStyle(htOption, labelEl_ht);
  };

  useEffect(() => {
    if (showMenu) {
      let getMetricsBtn = document.getElementById("get-metrics");
      getMetricsBtn.disabled = true;
      setToDate(null);
      setFromDate(null);
      setShowChart(false);
      setShowMenu(false);
    }

    setCheckboxStyles();
  }, [bbOption, tcOption, htOption, fromDate, toDate]);

  useEffect(() => {
    setCheckboxStyles();
  }, [isLoading]);

  useEffect(() => {
    setToDate(null);
  }, [fromDate]);

  useEffect(() => {
    const tooltipClass = theme === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  useEffect(() => {
    try {
      let myActiveTab = document.querySelector(
        ".nav-link.menu-tab.active-menu-tab"
      );
      myActiveTab.click();
    } catch (error) {}
  }, [showMenu]);

  return (
    <div className="overlay">
      {isLoading ? (
        <div className="overlay-spinner" id="loading">
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div
              className="spinner-border text-light-mode-purple"
              role="status"
            ></div>
            <strong className="loading-message">Processing data</strong>
          </div>
        </div>
      ) : (
        <>
          {openModal ? (
            <ModalCustom
              darkMode={theme === "dark"}
              onClose={onClickModal}
              onClick={onClickModal}
              modal_msg={"An error occurred. Please try again later."}
              btn_1_class={
                theme === "dark"
                  ? "btn btn-dark-mode-purple"
                  : "btn btn-light-mode-purple"
              }
              btn_2_class={
                theme === "dark"
                  ? "btn btn-dark-mode-purple"
                  : "btn btn-light-mode-purple"
              }
              modalSize=""
              secondOption={true}
              secondOptText={"Close"}
              onClickSecondOpt={onClickModal}
              noHeader={true}
            />
          ) : (
            <div></div>
          )}
          {<MetricsMenu handleMenuChange={handleMenuChange} />}
          <div className="metrics-container">
            <div className="metrics-side-menu-select-options">
              <div
                id="metrics-st-select-req-type-li"
                className="label-text metric-section required info-icon-space-div"
              >
                <AiOutlineInfoCircle
                  id={"metrics-menu-tooltip-icon"}
                  role="tooltip"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  className="info-icon info-icon-space"
                  data-testid="metrics-menu-tooltip-icon-reqtype"
                  data-bs-title={"Select one or more types of request."}
                />
                Select Request Type:
              </div>

              <div className="metrics-options">
                <Checkbox
                  option={"Best Bet"}
                  name="bestBets"
                  isChecked={bbOption}
                  handleChange={() => setBbOption(!bbOption)}
                />
                <Checkbox
                  option={"Topic Card"}
                  name="topicCards"
                  isChecked={tcOption}
                  handleChange={() => setTcOption(!tcOption)}
                />
                <Checkbox
                  option={"How-To"}
                  name="howTo"
                  isChecked={htOption}
                  handleChange={() => setHtOption(!htOption)}
                />
              </div>

              <div
                id="metrics-st-select-range-date-li"
                className="label-text metric-section required info-icon-space-div"
              >
                <AiOutlineInfoCircle
                  id={"metrics-menu-tooltip-icon"}
                  role="tooltip"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  className="info-icon info-icon-space"
                  data-testid="metrics-menu-tooltip-icon-reqtype"
                  data-bs-title={
                    "Select a date range in months, choose start and end."
                  }
                />
                Select Range Date:
              </div>

              <div className="metrics">
                <MetricsDate
                  sectionName={"st-usage-from"}
                  dateLabelTitle={"From:"}
                  name={"fromDate"}
                  minDate={minDate}
                  handleChange={setFromDate}
                  selected={fromDate}
                />
                <MetricsDate
                  sectionName={"st-usage-to"}
                  dateLabelTitle={"To:"}
                  name={"toDate"}
                  maxDate={maxDate}
                  handleChange={setToDate}
                  selected={toDate}
                />
                <MetricsButton
                  showMetrics={showMetrics}
                  isMetricsBtnDisabled={isMetricsBtnDisabled()}
                  reSize={"metrics-btn-resize"}
                />
              </div>
            </div>
            <div id="metrics-tables-div" className="metrics-tables-container">
              {showChart && (
                <div className="charts_box">
                  {selectedOption !== "url" && showMenu && (
                    <CsvDownloadBtn
                      currTheme={theme}
                      selectedOption={selectedOption}
                      bbRequests={bbRequests}
                      tcRequests={tcRequests}
                      htRequests={htRequests}
                      bbOption={bbOption}
                      tcOption={tcOption}
                      htOption={htOption}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  )}
                  {selectedOption === "url" && showMenu && (
                    <>
                      {bbOption && (
                        <UrlTable
                          testid="metrics-table"
                          urls={bbRequests.urls}
                          title="Best Bet"
                        />
                      )}
                      {tcOption && (
                        <UrlTable
                          testid="metrics-table"
                          urls={tcRequests.urls}
                          title="Topic Card"
                        />
                      )}
                      {htOption && (
                        <UrlTable
                          testid="metrics-table"
                          urls={htRequests.urls}
                          title="How to"
                        />
                      )}
                    </>
                  )}

                  {selectedOption === "targetCountry" &&
                    bbOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: bbRequests?.countries?.map(
                            (country) => country.key
                          ),
                          datasets: [
                            {
                              label: "Target Country Best Bets",
                              data: bbRequests?.countries?.map(
                                (country) => country.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "targetRefinerPage" &&
                    bbOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: bbRequests?.refinerPage?.map(
                            (refPage) => refPage.key
                          ),
                          datasets: [
                            {
                              label: "Target Refiner Page Best Bets",
                              data: bbRequests?.refinerPage?.map(
                                (refPage) => refPage.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "status" && bbOption && showMenu && (
                    <BarChart
                      chartData={{
                        labels: bbRequests?.bbReqStatus?.map(
                          (rqStatus) => rqStatus.key
                        ),
                        datasets: [
                          {
                            label: "Status Best Bets",
                            data: bbRequests?.bbReqStatus?.map(
                              (rqStatus) => rqStatus.doc_count
                            )
                          }
                        ]
                      }}
                    />
                  )}
                  {selectedOption === "submitedRqsts" &&
                    bbOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: bbRequests?.lastModDate?.map((date) => {
                            const dateObj = new Date(date.key_as_string);
                            return dateObj.toLocaleDateString("en-US", {
                              month: "short",
                              year: "numeric",
                              timeZone: "UTC"
                            });
                          }),
                          datasets: [
                            {
                              label: "Submited Requests Best Bets",
                              data: bbRequests?.lastModDate?.map(
                                (date) => date.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "uniqueUsers" && bbOption && showMenu && (
                    <BarChart
                      chartData={{
                        labels: bbRequests?.uniqueUser?.map((date) => {
                          const dateObj = new Date(date.key_as_string);
                          return dateObj.toLocaleDateString("en-US", {
                            month: "short",
                            year: "numeric",
                            timeZone: "UTC"
                          });
                        }),
                        datasets: [
                          {
                            label: "Unique Users Best Bets",
                            data: bbRequests?.uniqueUser?.map((bucket) => {
                              return bucket.amount_users.value;
                            })
                          }
                        ]
                      }}
                    />
                  )}
                  {selectedOption === "targetRefinerPage" &&
                    tcOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: tcRequests?.refinerPage?.map(
                            (refPage) => refPage.key
                          ),
                          datasets: [
                            {
                              label: "Target Refiner Page Topic Cards",
                              data: tcRequests?.refinerPage?.map(
                                (refPage) => refPage.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "status" && tcOption && showMenu && (
                    <BarChart
                      chartData={{
                        labels: tcRequests?.tcReqStatus?.map(
                          (rqStatus) => rqStatus.key
                        ),
                        datasets: [
                          {
                            label: "Status Topic Cards",
                            data: tcRequests?.tcReqStatus?.map(
                              (rqStatus) => rqStatus.doc_count
                            )
                          }
                        ]
                      }}
                    />
                  )}
                  {selectedOption === "submitedRqsts" &&
                    tcOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: tcRequests?.lastModDate?.map((date) => {
                            const dateObj = new Date(date.key_as_string);
                            return dateObj.toLocaleDateString("en-US", {
                              month: "short",
                              year: "numeric",
                              timeZone: "UTC"
                            });
                          }),
                          datasets: [
                            {
                              label: "Submited Requests Topic Cards",
                              data: tcRequests?.lastModDate?.map(
                                (date) => date.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "targetCountry" &&
                    tcOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: tcRequests?.countries?.map(
                            (country) => country.key
                          ),
                          datasets: [
                            {
                              label: "Target Country Topic Cards",
                              data: tcRequests?.countries?.map(
                                (country) => country.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "uniqueUsers" && tcOption && showMenu && (
                    <BarChart
                      chartData={{
                        labels: tcRequests?.uniqueUser?.map((date) => {
                          const dateObj = new Date(date.key_as_string);
                          return dateObj.toLocaleDateString("en-US", {
                            month: "short",
                            year: "numeric",
                            timeZone: "UTC"
                          });
                        }),
                        datasets: [
                          {
                            label: "Unique Users Topic Cards",
                            data: tcRequests?.uniqueUser?.map((bucket) => {
                              return bucket.amount_users.value;
                            })
                          }
                        ]
                      }}
                    />
                  )}
                  {selectedOption === "targetCountry" &&
                    htOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: htRequests?.countries?.map(
                            (country) => country.key
                          ),
                          datasets: [
                            {
                              label: "Target Country How-To",
                              data: htRequests?.countries?.map(
                                (country) => country.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "status" && htOption && showMenu && (
                    <BarChart
                      chartData={{
                        labels: htRequests?.htReqStatus?.map(
                          (rqStatus) => rqStatus.key
                        ),
                        datasets: [
                          {
                            label: "Status How-To",
                            data: htRequests?.htReqStatus?.map(
                              (rqStatus) => rqStatus.doc_count
                            )
                          }
                        ]
                      }}
                    />
                  )}
                  {selectedOption === "submitedRqsts" &&
                    htOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: htRequests?.lastModDate?.map((date) => {
                            const dateObj = new Date(date.key_as_string);
                            return dateObj.toLocaleDateString("en-US", {
                              month: "short",
                              year: "numeric",
                              timeZone: "UTC"
                            });
                          }),
                          datasets: [
                            {
                              label: "Submited Requests How-To",
                              data: htRequests?.lastModDate?.map(
                                (date) => date.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "targetRefinerPage" &&
                    htOption &&
                    showMenu && (
                      <BarChart
                        chartData={{
                          labels: htRequests?.refinerPage?.map(
                            (refPage) => refPage.key
                          ),
                          datasets: [
                            {
                              label: "Target Refiner Page How-To",
                              data: htRequests?.refinerPage?.map(
                                (refPage) => refPage.doc_count
                              )
                            }
                          ]
                        }}
                      />
                    )}
                  {selectedOption === "uniqueUsers" && htOption && showMenu && (
                    <BarChart
                      chartData={{
                        labels: htRequests?.uniqueUser?.map((date) => {
                          const dateObj = new Date(date.key_as_string);
                          return dateObj.toLocaleDateString("en-US", {
                            month: "short",
                            year: "numeric",
                            timeZone: "UTC"
                          });
                        }),
                        datasets: [
                          {
                            label: "Unique Users How-To",
                            data: htRequests?.uniqueUser?.map((bucket) => {
                              return bucket.amount_users.value;
                            })
                          }
                        ]
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Metrics;
