import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "bootstrap";
import StoreContext from "../../store/storecontext";
import { ThemeContext } from "../../ThemeContext";
import { formatDate } from "../FormatDate/DateFormat";
import ReviewCuratedClasses from "./ReviewContent.module.css";
import CuratedTableTabs from "./CuratedTableTabs";
import ExportRequestBtns from "./ExportRequestBtns";
import TableDisplaySection from "./TableDisplaySection";
import PaginationButtons from "./PaginationButtons";
import { FaUsers } from "react-icons/fa";

const ReviewContent = () => {
  const [tabStates, setTabStates] = useState({
    bb: true,
    tc: false,
    ht: false,
    syn: false
  });
  const tableStrings = { bb: "BB", tc: "TC", ht: "HT", syn: "SYN" };
  const [btnListForPages, setBtnListForPages] = useState([]);
  const [currentPageResults, setCurrentPageResults] = useState([]);
  const [currentPageList, setCurrentPageList] = useState([]);
  const [currentStatusList, setCurrentStatusList] = useState([]);
  const [currentReqTypeList, setCurrentReqTypeList] = useState([]);
  const [totalAmountOfRequests, setTotalAmountOfRequests] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [areResultsReady, setAreResultsReady] = useState(false);
  const [loadTableTrigger, setLoadTableTrigger] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);

  // filter variables
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage] = useState(10);
  const [filterByTypeParam, setFilterByTypeParam] = useState("");
  const [filterByStatusParam, setFilterByStatusParam] = useState("");
  const [hasFilterByTypeChanged, setHasFilterByTypeChanged] = useState(false);
  const [hasFilterByStatusChanged, setHasFilterByStatusChanged] =
    useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [sortField, setSortField] = useState("lastmodifieddateUTC");
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [typeFilterOptions, setTypeFilterOptions] = useState([]);
  const [showDelegates, setShowDelegates] = useState(false);

  // navigation variables
  const [updatePaginationBtns, setUpdatePaginationBtns] = useState(true);
  const bbRedirectUrl = "/promote/bestbet/request";
  const tcRedirectUrl = (template) => `/promote/topiccard/${template}request`;
  const tcTempRedirectUrl = (template) => `/promote/topiccard-new/${template}request`;
  const htRedirectUrl = "/promote/how-to/request";
  const synRedirectUrl = "/promote/synonyms/request";

  const store = useContext(StoreContext);
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx[0];
  const navigate = useNavigate();
  const [currentService, setCurrentService] = useState(
    store.services.bestBetService
  );

  const [createRowsTrigger, setCreateRowsTrigger] = useState(false);
  const [disableTabsTrigger, setDisableTabsTrigger] = useState(false);

  const reqTypeFields = {
    bb: {
      date: "lastmodifieddateUTC",
      owner: "requestedby.keyword",
      id: "bbrequestid"
    },
    ht: {
      date: "lastmodifieddateUTC",
      owner: "requestedby.keyword",
      id: "howto_req_id",
      delegate: "contacts.keyword"
    },
    tc: {
      date: "lastmodificationdate",
      owner: "submittedbyenterpriseid",
      id: "tcrequestid",
      delegate: "contacts.keyword"
    },
    syn: {
      date: "lastmodificationdate",
      delegate: "synonyms.keywords",
      id: "request_id"
    }
  };

  const defaultSort = {
    id: "desc",
    date: "desc",
    owner: "asc",
    delegate: "asc"
  };

  const arrowColor = theme === "dark" ? "#fff" : "#000000";

  const downFilterArrow = (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="directional"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="drop-down" fill={arrowColor}>
          <polygon id="Shape" points="5 8 12 16 19 8"></polygon>
        </g>
      </g>
    </svg>
  );

  const redirectToPromoteMyContent = (redirectTo) => {
    if (redirectTo === tableStrings.bb) {
      navigate(bbRedirectUrl);
    } else if (redirectTo === tableStrings.tc) {
      navigate(tcRedirectUrl("")); //redirect to /promote/topiccard/${""}request
    } else if (redirectTo === tableStrings.ht) {
      navigate(htRedirectUrl);
    } else if (redirectTo === tableStrings.syn) {
      navigate(synRedirectUrl);
    }
  };

  const redirectToRequest = (event) => {
    let reqID = event.target.dataset.btnredirectid;
    if (tabStates.bb) {
      navigate(bbRedirectUrl + "/" + reqID);
    } else if (tabStates.tc) {
      const req = currentPageResults.find((req) => req._id === reqID);
      const template = req._source.template === "standard" ? "" : "custom/";

      // QQQ remove after tc refactor
      if(req._source.title.includes("NewForm")){
        navigate(tcTempRedirectUrl(template) + "/" + reqID);
      }else{
        navigate(tcRedirectUrl(template) + "/" + reqID);
      }
    } else if (tabStates.ht) {
      navigate(htRedirectUrl + "/" + reqID);
    } else if (tabStates.syn) {
      navigate(synRedirectUrl + "/" + reqID);
    }
  };

  const resetFilters = () => {
    setFilterByStatusParam("");
    setFilterByTypeParam("");
    setSortBy("desc");
  };

  const displayTable = (tableToShow) => {
    if (tableToShow === tableStrings.bb) {
      let currTabStates = { ...tabStates };
      currTabStates.bb = true;
      currTabStates.tc = false;
      currTabStates.ht = false;
      currTabStates.syn = false;
      resetFilters();
      setTabStates(currTabStates);
    } else if (tableToShow === tableStrings.tc) {
      let currTabStates = { ...tabStates };
      currTabStates.tc = true;
      currTabStates.bb = false;
      currTabStates.ht = false;
      currTabStates.syn = false;
      resetFilters();
      setTabStates(currTabStates);
    } else if (tableToShow === tableStrings.ht) {
      let currTabStates = { ...tabStates };
      currTabStates.ht = true;
      currTabStates.syn = false;
      currTabStates.bb = false;
      currTabStates.tc = false;
      resetFilters();
      setTabStates(currTabStates);
    } else if (tableToShow === tableStrings.syn) {
      let currTabStates = { ...tabStates };
      currTabStates.syn = true;
      currTabStates.bb = false;
      currTabStates.tc = false;
      currTabStates.ht = false;
      resetFilters();
      setTabStates(currTabStates);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = async () => {
    let lastPage = parseInt(totalAmountOfRequests / 10);
    lastPage = lastPage * 10;
    await setCurrentPage(lastPage);
  };

  const goToPage = (event) => {
    let pageValue = event.target.innerText;

    let lastPage = parseInt(totalAmountOfRequests / 10);
    lastPage = lastPage * 10;

    if (pageValue === "‹" && currentPage >= resultsPerPage) {
      let newPage = currentPage - resultsPerPage;
      setCurrentPage(newPage);
    } else if (pageValue === "›" && currentPage <= lastPage - resultsPerPage) {
      let newPage = currentPage + resultsPerPage;
      setCurrentPage(newPage);
    } else if (parseInt(pageValue) >= 0) {
      let newPage = parseInt(pageValue * 10);

      if (newPage - resultsPerPage <= lastPage) {
        newPage = newPage - resultsPerPage;
        setCurrentPage(newPage);
      } else {
        setCurrentPage(newPage);
      }
    }
  };

  const filterByType = (event) => {
    let filterValue = event.target.innerText;
    if (filterValue === "All") {
      setFilterByTypeParam("");
      setHasFilterByTypeChanged(false);
    } else {
      if (
        !typeFilterOptions.includes("All") ||
        typeFilterOptions.length === 1
      ) {
        let typeOptions = [...typeFilterOptions, "All"];
        setTypeFilterOptions(typeOptions.sort());
      }
      let filterValues = tabStates.bb
        ? "cleanrequesttype|" + filterValue
        : "request_type|" + filterValue;
      let newFilterBy = encodeURI(filterValues);
      setFilterByTypeParam(newFilterBy);
      setHasFilterByTypeChanged(true);
    }
  };

  const oppositeSort = () => {
    return sortBy === "desc" ? "asc" : "desc";
  };

  const sortFieldBy = (list, order) => {
    if (!list) {
      return list;
    }
    if (order === "asc") {
      return list.sort();
    } else {
      return list.sort().reverse();
    }
  };
  const filterByStatus = (event) => {
    let filterValue = event.target.innerText;
    let filterValues = "";

    if (filterValue === "All") {
      setFilterByStatusParam("");
      setHasFilterByStatusChanged(false);
    } else {
      let statusPropValue = "";
      if (!statusFilterOptions.includes("All")) {
        let statusOptions = [...statusFilterOptions, "All"];
        setStatusFilterOptions(statusOptions.sort());
      }
      if (tabStates.bb) {
        statusPropValue = "cleanstatus|";
        filterValues = statusPropValue + filterValue;
      } else if (tabStates.tc || tabStates.ht || tabStates.syn) {
        filterValues = filterValue;
      }
      if (tabStates.syn) {
        filterValues = "status|" + filterValue;
      }
      let newFilterBy = encodeURI(filterValues);
      setFilterByStatusParam(newFilterBy);
      setHasFilterByStatusChanged(true);
    }
  };

  const orderByField = (field) => {
    var fieldName;
    ["bb", "tc", "ht", "syn"].forEach((reqType) => {
      fieldName = tabStates[reqType]
        ? reqTypeFields[reqType][field]
        : fieldName;
    });
    if (sortField === fieldName) {
      setSortBy(oppositeSort());
    } else {
      setSortField(fieldName);
      setSortBy(defaultSort[field]);
    }
  };

  const updatePaginationCss = () => {
    let currPage = parseInt(totalAmountOfRequests / 10);

    let firstPage = parseInt(currentPage / 10);
    let endPage = currPage - (currPage - 4) + parseInt(currentPage / 10);

    if (firstPage - 5 > 0) {
      firstPage = firstPage - 2;
      endPage = endPage - 2;
    }

    let paginationButtons = [];

    let pageBtnClass =
      theme === "dark"
        ? ReviewCuratedClasses.page_btn_dark
        : ReviewCuratedClasses.page_btn_light;

    for (let i = firstPage; i <= endPage; i++) {
      let isActivePage = false;

      isActivePage = parseInt(currentPage / 10) === i ? " active" : "";

      let pageLiStyle = "";

      if (theme === "dark") {
        pageLiStyle = isActivePage
          ? ReviewCuratedClasses.page_item_li_dark_active
          : ReviewCuratedClasses.page_btn_dark;
      } else {
        pageLiStyle = isActivePage
          ? ReviewCuratedClasses.page_item_li_active
          : ReviewCuratedClasses.page_btn_light;
      }

      const paginationButton = (
        <li key={i} id={"page-item-li"} className={"page-item " + pageLiStyle}>
          <button
            key={i * 2}
            id={"go-to-page " + i}
            aria-label={"go-to-page " + (i + 1)}
            className={pageBtnClass}
            href="#"
            tabIndex="0"
            onClick={goToPage}
          >
            <span key={i * 3}>{i + 1}</span>
          </button>
        </li>
      );

      if (parseInt(currentPage / 10) < currPage && i < currPage + 1) {
        paginationButtons.push(paginationButton);
      }
    }

    setBtnListForPages(paginationButtons);
  };

  const loadDataForTable = async () => {
    let filterBy = "";
    if (filterByStatusParam !== "" && filterByTypeParam !== "") {
      filterBy = filterByStatusParam + "," + filterByTypeParam;
    } else if (filterByStatusParam === "") {
      filterBy = filterByTypeParam;
    } else if (filterByTypeParam === "") {
      filterBy = filterByStatusParam;
    }
    setTotalAmountOfRequests(0);
    await currentService
      .GetRequests(currentPage, resultsPerPage, filterBy, sortField, sortBy)
      .then((r) => {
        let totalResults = r.data.hits?.total?.value;
        let currList = [...r.data.hits.hits];
        if (
          (filterByTypeParam === "" && tabStates.bb) ||
          (filterByTypeParam === "" && tabStates.syn)
        ) {
          let requestType = r.data.aggregations.requesttypes.buckets;
          requestType = requestType.map((type) => type.key);
          setTypeFilterOptions(requestType.sort());
        }

        if (filterByStatusParam === "") {
          let requeststatus;
          if (tabStates.bb) {
            requeststatus = r.data.aggregations.requeststatus.buckets;
          } else if (tabStates.tc) {
            requeststatus = r.data.aggregations.status.buckets;
          } else if (tabStates.ht) {
            // ht index query doesn't have
            // r.data.aggregations object,
            // and it can't be extracted from the first 10 results either
            requeststatus = [
              { key: "New" },
              { key: "Implemented" },
              { key: "Cancelled" },
              { key: "Inactive" },
              { key: "Not Approved" }
            ];
          } else if (tabStates.syn) {
            requeststatus = r.data.aggregations.requeststatus.buckets;
          }
          requeststatus = requeststatus.map((status) => status.key);
          setStatusFilterOptions(requeststatus.sort());
        }
        setTotalAmountOfRequests(totalResults);
        setCurrentPageResults(currList);
        let newBool = !updatePaginationBtns;
        setUpdatePaginationBtns(newBool);
        setAreResultsReady(true);
        setIsDataLoading(false);
        setApiFailed(false);
        setCreateRowsTrigger(!createRowsTrigger);
      })
      .catch((error) => {
        setCurrentPageResults([]);
        setTotalAmountOfRequests(0);
        setIsDataLoading(false);
        setApiFailed(true);
        setDisableTabsTrigger(false);
        console.log("error on load Data For Table");
      });
  };

  const updateCurrentService = async () => {
    let currentRequestService = "";
    if (tabStates.bb) {
      currentRequestService = store.services.bestBetService;
    } else if (tabStates.tc) {
      currentRequestService = store.services.topicCardService;
    } else if (tabStates.ht) {
      currentRequestService = store.services.howToService;
    } else if (tabStates.syn) {
      currentRequestService = store.services.synonymsService;
    }
    await setCurrentService(currentRequestService);
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const handleIconEnter = (id) => {
    let element = document.querySelector(`#delegate_div_for_req_${id}`);
    element.children[0].classList.remove(ReviewCuratedClasses.hideList);
  };

  const handleIconLeave = (id) => {
    let element = document.querySelector(`#delegate_div_for_req_${id}`);
    element.children[0].classList.add(ReviewCuratedClasses.hideList);
  };

  const createTRows = async () => {
    setAreResultsReady(false);

    let requestDelegates = [];
    let totalRows = await currentPageResults.map((el, i) => {
      let request = el._source;
      let lastModificationDate = "";
      let requestTitle = "";
      let requestURL = "";
      let requestStatus = "";
      let requestOwner = "";

      if (tabStates.bb) {
        requestTitle = request.cleantitle;
        requestURL = request.cleanurl;
        requestStatus = request.cleanstatus;
        requestOwner = request.requestedby;
        lastModificationDate = formatDate(request.lastmodifieddateUTC);
      } else if (tabStates.ht) {
        requestTitle = request.cleantitle;
        requestURL = request.cleanurl;
        requestStatus = request.cleanstatus;
        requestOwner = request.requestedby;
        requestDelegates = request.contacts || [];
        requestDelegates = sortFieldBy(requestDelegates, sortBy);
        lastModificationDate = formatDate(request.lastmodifieddateUTC);
      } else if (tabStates.tc) {
        requestTitle = request.title;
        requestURL = request.url || " ";
        requestStatus = request.status;
        requestOwner = request.submittedbyenterpriseid;
        requestDelegates = sortFieldBy(request.contacts, sortBy);
        lastModificationDate = formatDate(request.lastmodificationdate);
      } else if (tabStates.syn) {
        requestTitle = request.term;
        requestStatus = request.status;
        requestDelegates = request?.synonyms?.join(", ");
        lastModificationDate = formatDate(request.lastmodificationdate);
      }

      let firstDelegate = "         ";

      if (request.hasOwnProperty("contacts")) {
        // since delegates are optional for topic cards
        // a check is needed before trying to render the data
        if (request.contacts.length === 0) {
          // push an empty string to signify an empty cell
          requestDelegates.push(firstDelegate);
        } else if (request.contacts.length > 0) {
          firstDelegate = requestDelegates[0];
        }
      }

      const linkItemColor =
        theme === "dark"
          ? ReviewCuratedClasses.redirect_to_request_btn_dark
          : ReviewCuratedClasses.redirect_to_request_btn;

      const showCleanRequestType = !tabStates.tc && !tabStates.ht;

      const darkList =
        theme === "dark"
          ? ReviewCuratedClasses.delegates_list_dark
          : ReviewCuratedClasses.delegates_list;

      return (
        <tr key={el._id} tabIndex="0" aria-label={"row " + i + 1}>
          <td>
            <button
              data-testid="rqst_btn"
              id={"btn_request_id__" + el._id}
              key={"btn_request_id__" + el._id}
              onClick={redirectToRequest}
              data-btnredirectid={el._id}
              className={linkItemColor}
            >
              {el._id}
            </button>
          </td>
          {tabStates.syn && (
            <>
              <td
                key={el._id * 2}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={requestTitle}
                className={ReviewCuratedClasses.td_class}
                tabIndex="0"
                aria-label={requestTitle}
                id="syn_rcc_term_column"
              >
                <button
                  id={"btn_request_title__" + el._id}
                  key={"btn_request_title__" + el._id}
                  onClick={redirectToRequest}
                  data-btnredirectid={el._id}
                  className={`${ReviewCuratedClasses.td_class}  ${linkItemColor} `}
                >
                  {requestTitle}
                </button>
              </td>
              <td
                key={el._id * 3}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={requestDelegates}
                className={ReviewCuratedClasses.synonyms_col_table}
                tabIndex="0"
                aria-label={requestDelegates}
                id="syn_rcc_synonym_column"
              >
                {requestDelegates}
              </td>
            </>
          )}
          {!tabStates.syn && (
            <>
              <td
                key={el._id * 4}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={requestTitle}
                className={ReviewCuratedClasses.td_class}
                tabIndex="0"
                aria-label={requestTitle}
              >
                <button
                  id={"btn_request_title__" + el._id}
                  key={"btn_request_title__" + el._id}
                  onClick={redirectToRequest}
                  data-btnredirectid={el._id}
                  data-testid="req_btn"
                  className={`${ReviewCuratedClasses.td_class}  ${linkItemColor} `}
                >
                  {requestTitle}
                </button>
              </td>
              <td
                key={el._id * 5}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={requestURL}
                className={ReviewCuratedClasses.td_class}
                tabIndex="0"
                aria-label={requestURL}
                id="tc_rcc_url_column"
              >
                {requestURL}
              </td>
            </>
          )}

          {showCleanRequestType && (
            <td
              key={el._id * 6}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title={request.cleanrequesttype || request.request_type}
              className={ReviewCuratedClasses.td_class}
              tabIndex="0"
              aria-label={request.cleanrequesttype || request.request_type}
            >
              {request.cleanrequesttype || request.request_type}
            </td>
          )}

          <td
            key={el._id * 7}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title={requestStatus}
            className={ReviewCuratedClasses.td_class}
            tabIndex="0"
            aria-label={requestStatus}
          >
            {requestStatus}
          </td>
          {!tabStates.syn && (
            <>
              <td
                key={el._id * 8}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={requestOwner}
                className={ReviewCuratedClasses.td_class}
                tabIndex="0"
                aria-label={requestOwner}
              >
                {requestOwner}
              </td>
              {!showCleanRequestType && (
                <>
                  {request.hasOwnProperty("contacts") ? (
                    <td
                      key={el._id * 9}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title={firstDelegate}
                      className={ReviewCuratedClasses.td_class}
                      tabIndex="0"
                      aria-label={"requestDelegates"}
                    >
                      <div>
                        {requestDelegates.length === 0 ? (
                          <></>
                        ) : (
                          <div className={ReviewCuratedClasses.delegates_row}>
                            <label className="rcc-delegate-col">
                              {firstDelegate}
                            </label>
                            {requestDelegates.length > 1 && (
                              <>
                                <FaUsers
                                  className={ReviewCuratedClasses.users_icon}
                                  onMouseEnter={() => {
                                    handleIconEnter(el._id);
                                  }}
                                  id="users-icon"
                                  onMouseLeave={() => handleIconLeave(el._id)}
                                />
                              </>
                            )}
                            <div
                              id={"delegate_div_for_req_" + el._id}
                              className={ReviewCuratedClasses.box_list}
                            >
                              <ul
                                className={`${ReviewCuratedClasses.hideList}  ${ReviewCuratedClasses.delegates_list} + ${darkList}`}
                              >
                                {requestDelegates.map((delegate, index) => (
                                  <li key={index}>{delegate}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </>
              )}
              <td
                key={el._id * 10}
                className={ReviewCuratedClasses.td_class}
                tabIndex="0"
                aria-label={lastModificationDate}
              >
                {lastModificationDate}
              </td>
            </>
          )}
        </tr>
      );
    });

    // set filters
    let reqTypeList = [];
    typeFilterOptions.map((el) => {
      let newLiAction = (
        <li key={el}>
          <a className="dropdown-item" href="#" onClick={filterByType}>
            {el}
          </a>
        </li>
      );
      reqTypeList.push(newLiAction);
    });

    let statusList = [];
    statusFilterOptions.map((el) => {
      let newLiAction = (
        <li key={el}>
          <a className="dropdown-item" href="#" onClick={filterByStatus}>
            {el}
          </a>
        </li>
      );
      statusList.push(newLiAction);
    });

    setCurrentStatusList(statusList);
    setCurrentReqTypeList(reqTypeList);
    setCurrentPageList(totalRows);
    let newBool = !updatePaginationBtns;
    setUpdatePaginationBtns(newBool);
    await setDisableTabsTrigger(false);
  };

  const changeTabState = async () => {
    await updateCurrentService();
    let field = "";
    if (tabStates.bb) {
      field = reqTypeFields.bb["date"];
    } else if (tabStates.tc) {
      field = reqTypeFields.tc["date"];
    } else if (tabStates.ht) {
      field = reqTypeFields.ht["date"];
    } else if (tabStates.syn) {
      field = reqTypeFields.syn["date"];
    }
    setSortField(field);
  };

  // useEffect for tooltips
  useEffect(() => {
    try {
      const tooltipClass =
        theme === "dark" ? "custom-tooltip-dark" : "custom-tooltip-light";
      let tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl, {
          tooltipTriggerList,
          trigger: "hover focus",
          customClass: tooltipClass
        });
      });
    } catch (error) {
      console.log("can't find tooltips to initialize");
    }
  }, [currentPageList]);

  // for when filters change
  useEffect(() => {
    deleteOldTooltips();
    setCurrentPage(0);
    let triggerVal = !loadTableTrigger;
    setLoadTableTrigger(triggerVal);
  }, [filterByStatusParam, filterByTypeParam, sortBy]);

  //loads table when the service is updated
  useEffect(() => {
    let triggerVal = !loadTableTrigger;
    setLoadTableTrigger(triggerVal);
    setCurrentPage(0);
  }, [sortField]);

  useEffect(() => {
    setDisableTabsTrigger(true);
    changeTabState();
    let triggerVal = !loadTableTrigger;
    setLoadTableTrigger(triggerVal);
  }, [tabStates]);

  // useEffect for pagination when currentPage changes
  useEffect(() => {
    let triggerVal = !loadTableTrigger;
    setLoadTableTrigger(triggerVal);
  }, [currentPage]);

  // use effect to update the buttons used for pagination
  useEffect(() => {
    updatePaginationCss();
  }, [updatePaginationBtns]);

  // this update is for first time render of the component
  useEffect(() => {
    updatePaginationCss();
  }, [themeCtx[0]]);

  // render results on list change
  useEffect(() => {
    createTRows();
  }, [themeCtx[0], showDelegates]);

  // load table results
  useEffect(() => {
    setDisableTabsTrigger(true);
    deleteOldTooltips();
    setIsDataLoading(true);
    loadDataForTable();
  }, [loadTableTrigger]);

  useEffect(() => {
    createTRows();
  }, [createRowsTrigger]);

  return (
    <div
      id="review-curated-content"
      data-testid="rcc_section"
      aria-label="review-curated-content"
      className={"container " + ReviewCuratedClasses.main_div_container}
    >
      <div className={ReviewCuratedClasses.tabs_and_btns_rcc}>
        <CuratedTableTabs
          displayTable={displayTable}
          isDarkMode={theme === "dark"}
          delegates_list_dark={ReviewCuratedClasses.delegates_list_dark}
          best_bet_tab={ReviewCuratedClasses.best_bet_tab}
          best_bet_tab_dark={ReviewCuratedClasses.best_bet_tab_dark}
          topic_card_tab={ReviewCuratedClasses.topic_card_tab}
          topic_card_tab_dark={ReviewCuratedClasses.topic_card_tab_dark}
          how_to_tab={ReviewCuratedClasses.how_to_tab}
          how_to_tab_dark={ReviewCuratedClasses.how_to_tab_dark}
          syn_tab={ReviewCuratedClasses.how_to_tab}
          syn_tab_dark={ReviewCuratedClasses.how_to_tab_dark}
          active_bb_tab={ReviewCuratedClasses.active_bb_tab}
          active_tc_tab={ReviewCuratedClasses.active_tc_tab}
          active_ht_tab={ReviewCuratedClasses.active_ht_tab}
          active_syn_tab={ReviewCuratedClasses.active_syn_tab}
          active_bb_tab_dark={ReviewCuratedClasses.active_bb_tab_dark}
          active_tc_tab_dark={ReviewCuratedClasses.active_tc_tab_dark}
          active_ht_tab_dark={ReviewCuratedClasses.active_ht_tab_dark}
          active_syn_tab_dark={ReviewCuratedClasses.active_syn_tab_dark}
          request_table_tabs={ReviewCuratedClasses.request_table_tabs}
          active_delegates_list={ReviewCuratedClasses.active_delegates_list}
          tabStates={tabStates}
          tableStrings={tableStrings}
          disableTabsTrigger={disableTabsTrigger}
        />
      </div>
      <div
        id="table-content-rcc-div"
        className={ReviewCuratedClasses.table_div_rcc}
      >
        <div className={ReviewCuratedClasses.review_curated_content_buttons}>
          <ExportRequestBtns
            isDarkMode={theme === "dark"}
            delegates_list_dark={ReviewCuratedClasses.delegates_list_dark}
            export_requests_div={ReviewCuratedClasses.export_requests_div}
            div_separator={ReviewCuratedClasses.div_separator}
            total_amount_span={ReviewCuratedClasses.total_amount_span}
            font_weight_button={ReviewCuratedClasses.font_weight_button}
            totalAmountOfRequests={totalAmountOfRequests}
            total_results_color_dark={
              ReviewCuratedClasses.total_results_color_dark
            }
            redirectToPromoteMyContent={redirectToPromoteMyContent}
            req_bb_btn_color={ReviewCuratedClasses.req_bb_btn_color}
            req_bb_btn_color_dark={ReviewCuratedClasses.req_bb_btn_color_dark}
            req_tc_btn_color={ReviewCuratedClasses.req_tc_btn_color}
            req_tc_btn_color_dark={ReviewCuratedClasses.req_tc_btn_color_dark}
            req_ht_btn_color={ReviewCuratedClasses.req_ht_btn_color}
            req_ht_btn_color_dark={ReviewCuratedClasses.req_ht_btn_color_dark}
            req_syn_btn_color={ReviewCuratedClasses.req_syn_btn_color}
            req_syn_btn_color_dark={ReviewCuratedClasses.req_syn_btn_color_dark}
            tabStates={tabStates}
            tableStrings={tableStrings}
            filterBy={{ type: filterByTypeParam, status: filterByStatusParam }}
            sortField={sortField}
            sortBy={sortBy}
          />
        </div>
        <TableDisplaySection
          isDarkMode={theme === "dark"}
          delegates_list_dark={ReviewCuratedClasses.delegates_list_dark}
          currentReqTypeList={currentReqTypeList}
          currentStatusList={currentStatusList}
          active_tab_class={ReviewCuratedClasses.active_tab_class}
          table_filter_status_arrow={
            ReviewCuratedClasses.table_filter_status_arrow
          }
          status_filter_menu={ReviewCuratedClasses.status_filter_menu}
          status_filter_menu_dark={ReviewCuratedClasses.status_filter_menu_dark}
          table_filter_arrow={ReviewCuratedClasses.table_filter_arrow}
          active_delegates_list={ReviewCuratedClasses.active_delegates_list}
          orderBy={orderByField}
          downFilterArrow={downFilterArrow}
          currentPageList={currentPageList}
          tbody_dark={ReviewCuratedClasses.tbody_dark}
          table_filter_button={ReviewCuratedClasses.table_filter_button}
          tabStates={tabStates}
          isDataLoading={isDataLoading}
          apiFailed={apiFailed}
        />
        {isDataLoading ? (
          <></>
        ) : (
          <>
            {currentPageResults.length < 10 &&
            totalAmountOfRequests < 10 &&
            apiFailed ? (
              <></>
            ) : (
              <PaginationButtons
                goToFirstPage={goToFirstPage}
                goToPage={goToPage}
                goToLastPage={goToLastPage}
                btnListForPages={btnListForPages}
                isDarkMode={theme === "dark"}
                page_btn_light={ReviewCuratedClasses.page_btn_light}
                page_btn_dark={ReviewCuratedClasses.page_btn_dark}
                currentPageNum={currentPage}
                totalRequests={totalAmountOfRequests}
                arrow_btn_light={ReviewCuratedClasses.arrow_btn_light}
                arrow_btn_dark={ReviewCuratedClasses.arrow_btn_dark}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewContent;
