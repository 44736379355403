import BaseService from "./BaseService";

class MetricsFeedbackService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  DownloadMetricsFeedback(fromDate, toDate) {
    return this.axios.get(
      `${this.baseUrl}feedback/export?fromDate=${fromDate}&toDate=${toDate}`,
      this.config
    );
  }
}
export default MetricsFeedbackService;
