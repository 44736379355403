import React from "react";
import "./HomePage.css";
import background from "../../assets/images/home_background.svg";
import { useNavigate } from "react-router-dom";
import AnnouncementModal from "./AnnouncementModal";

const HomePage = () => {
  const nfmodalwidgetid = process.env.REACT_APP_NF_MODAL_WIDGET_ID;
  const nfmodalappid = process.env.REACT_APP_NF_MODAL_APP_ID;
  const navigate = useNavigate();
  const setActiveMenuOption = (menuOption) => {
    // set active menu tab
    let liElements = document.querySelectorAll(".home-menu-option-li");

    Array.from(liElements).map((el) => {
      if (el.dataset.pagesection === menuOption) {
        el.classList.add("active-home-menu-option");
      } else {
        el.classList.remove("active-home-menu-option");
      }
    });
  };

  return (
    <div className="home_page" data-testid="homepage" aria-label="Main page">
      <AnnouncementModal />
      <div
        id="acn-modal"
        className="acn-auth-azure"
        data-widgetid={nfmodalwidgetid}
        data-appid={nfmodalappid}
      ></div>
      <div className="main-div" id="MainLogos">
        <div
          id="header-acn-logo"
          className="acn-logo"
          data-testid="acn-logo"
          aria-label="home page"
        />
        <div
          id="header-searchtools-logo"
          className="searchtools-logo"
          data-testid="searchtools-logo"
          aria-label="home page"
        />
      </div>
      <div className="welcome_msg">
        <p aria-label="Welcome message" id="welcome_message">
          Search tools optimizes your content for better visibility and higher
          placement in search results, making it easier for your target audience
          to discover your content.
        </p>
        <button
          id="home-promote-my-content-btn"
          className="btn btn-primary rounded-pill adjust-home-btn"
          data-testid="promoteBtn"
          onClick={() => {
            navigate("/promote/bestbet/request");
            setActiveMenuOption("promote");
          }}
        >
          Promote my Content →
        </button>
      </div>

      <img
        id="home-background"
        data-testid="background-img"
        className="background_img"
        src={background}
      />
    </div>
  );
};

export default HomePage;
