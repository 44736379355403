import { Link, useNavigate } from "react-router-dom";
import StoreContext from "../../store/storecontext";
import { useContext } from "react";
import { TiPencil } from "react-icons/ti";
import { formatDate } from "../FormatDate/DateFormat";
import "./ManageMyRequests.css";
import { ThemeContext } from "../../ThemeContext";

const Card = ({ data }) => {
  const store = useContext(StoreContext);
  const themeCtx = useContext(ThemeContext);

  let isDarkMode = themeCtx[0] === "dark";
  const navigate = useNavigate();
  const hideEditBB =
    data.status === "Cancelled" ||
    data.status === "Not Approved" ||
    (data.reqType === "Removal" && data.status === "Implemented");
  const hideEditTC = data.status === "Inactive";
  const hideEditSY =
    data.status === "Cancelled" ||
    (data.reqType === "Removal" && data.status === "Implemented");
  const hideEditButton =
    data.type === "BB" || data.type === "SY"
      ? hideEditBB || hideEditSY
      : hideEditTC;

  const requestToEdit = (e) => {
    let comesFromTitle = e.target.id === "request-edit-with-title";
    if (data.type === "BB") {
      if (data.status === "New") {
        store.state.requestToEditBB = {
          canEdit: true,
          bbid: data.id,
          contentSearcher: false,
          itsFromManageMyRequest: true
        };
        navigate(data.reqLink + data.id);
      } else if (data.status === "Implemented") {
        store.state.requestToEditBB = {
          canEdit: !comesFromTitle,
          bbid: data.id,
          contentSearcher: false,
          itsFromManageMyRequest: true,
          showWarningBanner: true
        };

        comesFromTitle
          ? navigate(data.reqLink + data.id)
          : navigate(data.reqLink);
      } else {
        store.state.requestToEditBB = {
          canEdit: false,
          bbid: data.id,
          contentSearcher: false,
          itsFromManageMyRequest: true
        };
        navigate(data.reqLink + data.id);
      }
    } else if (data.type === "SY") {
      if (data.status === "New") {
        store.state.requestToEditSYN = {
          canEdit: true,
          request_id: data.id,
          contentSearcher: false,
          itsFromManageMyRequest: true
        };
      } else if (data.status === "Implemented") {
        store.state.requestToEditSYN = {
          canEdit: !comesFromTitle,
          request_id: data.id,
          contentSearcher: false,
          itsFromManageMyRequest: true
        };
      } else {
        store.state.requestToEditSYN = {
          canEdit: false,
          request_id: data.id,
          contentSearcher: false,
          itsFromManageMyRequest: true
        };
      }
      navigate(data.reqLink + data.id);
    } else {
      navigate(data.reqLink + data.id);
    }
  };

  const isCTC = () => {
    return data.reqLink.includes("custom");
  };
  const typeClassMap = {
    BB: "bbIcon",
    TC: isCTC() ? "ctcIcon" : "tcIcon",
    HT: "htIcon",
    SY: "synIcon"
  };
  const iconClass = `card-header ${typeClassMap[data.type]}`;
  const textLinkColor =
    data.type === "SY" ? (isDarkMode ? "black" : "white") : "black";
  return (
    <>
      <div
        className="cards_box"
        id={`card_mmr_${data.id}`}
        data-testid="card_mmr"
      >
        <div className="card manage_card">
          <div className={iconClass}>
            <Link
              className="icon_link"
              to={data.reqLink + data.id}
              style={{ color: textLinkColor }}
              data-testid="card-icon"
            >
              <span data-testid="card-icon" className="type_req_icon">
                {`${isCTC() ? "C" : ""}${data.type}`}
              </span>
            </Link>
          </div>
          <div className="card-body">
            <span
              tabIndex={0}
              id="request-edit-with-title"
              onClick={requestToEdit}
              className="card-title"
              data-testid="card_title"
              onKeyDown={(e) => e.key === "Enter" && requestToEdit(e)}
            >
              {data.title}
            </span>

            <div
              className="card-text description_card_mmr"
              id="sub_url_card"
              data-testid="url_card"
            >
              {data.url}
            </div>
            <div
              className="card-text"
              data-testid="card_description"
              id="card_description"
            >
              {data.description}
            </div>
          </div>
          <div className="card-footer">
            <p className="footer_state" data-testid="card_status">
              {data.status}
            </p>
            <p className="footer_date" data-testid="card_date">
              Modification Date:<br></br>
              {formatDate(data.date)}
            </p>
            {hideEditButton ? (
              <></>
            ) : (
              <button
                onClick={requestToEdit}
                className="edit_button"
                data-testid="edit_button"
              >
                Edit <TiPencil className="edit_pencil" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Card;
