import { useEffect, useContext } from "react";
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../../../ThemeContext";
import { triggerToast } from "../../../Toast/TriggerToast";

const HowToPills = ({
  selectedTargets,
  setSelectedTarget,
  arrayForReview = [],
  hasReviewArray,
  field,
  updateGetInTouch,
  updateDelegates,
  disableField,
  revalidateForm,
  onCopyToClipBoard,
  requestStatus,
  removeGreen,
  isLoading = null
}) => {
  const [theme] = useContext(ThemeContext);
  const tooltipClass = theme === "dark" ? "ht-tooltip-dark" : "";
  let greenPillStyle = "btn btn-success pills";
  let redPill = "btn btn-danger pills-red";
  let greyPill = "btn btn-gray-class";

  if (selectedTargets === undefined) {
    selectedTargets = [];
  }

  let getToggleSelector = () => {
    let selector = "";
    if (field === "synonyms") {
      selector = "synonym-tooltip";
    } else {
      selector = field === "delegates" ? "delegate-tooltip" : undefined;
    }

    return selector;
  };

  useEffect(() => {
    if (arrayForReview.length >= 0) {
      const tooltipClass = theme === "dark" ? "tooltip-dark" : "";
      let tooltipElements = document.querySelectorAll(
        '[data-bs-toggle="red-pills"]'
      );
      [...tooltipElements].map(
        (ttp) =>
          new Tooltip(ttp, {
            tooltipElements,
            trigger: "hover focus",
            customClass: tooltipClass
          })
      );
    }
  }, [theme]);
  useEffect(() => {
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="red-pills"]')
    );
    if (tooltipTriggerList.length !== arrayForReview.length) {
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl, {
          trigger: "hover focus",
          customClass: tooltipClass
        });
      });
    }
  }, [arrayForReview]);
  useEffect(() => {
    //initialize tooltips for synonyms and delegates
    const initializeSynonymTooltips = () => {
      let tooltipElements = document.querySelectorAll(
        `[data-bs-toggle="synonym-tooltip"]`
      );
      [...tooltipElements].map(
        (ttp) =>
          new Tooltip(ttp, {
            trigger: "hover focus",
            customClass: tooltipClass
          })
      );
    };
    if (field === "synonyms") {
      initializeSynonymTooltips();
    }
  }, [selectedTargets, theme]);
  const removePill = (event) => {
    const target = event.target.value.trim();
    let targetArray = [];
    if (field === "synonyms") {
      const tooltip = document.querySelector(".tooltip.show");
      tooltip.remove();
      (async () => {
        const added = await removeGreen(target);
        if (!added) {
          const arr1 = new Set([...selectedTargets, target]);
          await setSelectedTarget(Array.from(arr1));
        }
      })();
    } else if (selectedTargets.indexOf(target) !== -1) {
      targetArray = [...selectedTargets];

      if (field === "getInTouch") {
        updateGetInTouch(target);
      } else if (field === "delegates") {
        updateDelegates(target);
      } else {
        targetArray = targetArray.filter(
          (targetElement) => target !== targetElement
        );
        setSelectedTarget(field, targetArray);
        if (field === "keyword") {
          revalidateForm();
        }
      }
    } else if (field === "keyword") {
      targetArray = [...arrayForReview];
      targetArray = targetArray.filter(
        (targetElement) => target !== targetElement
      );
      setSelectedTarget("keywordsForReview", targetArray);
    }
  };

  const initDelegateTooltips = () => {
    try {
      let tooltipElements = document.querySelectorAll(
        `[data-bs-toggle="delegate-tooltip"]`
      );
      [...tooltipElements].map(
        (ttp) =>
          new Tooltip(ttp, {
            trigger: "hover focus",
            customClass: tooltipClass
          })
      );
    } catch (error) {}
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  let tooltipToggleSelector = getToggleSelector();

  const handleClick = (e) => {
    onCopyToClipBoard(e);
    triggerToast(1000, 0, "copyToClipboard");
  };

  return (
    <div className="tc-pills-container">
      {selectedTargets.length === 0 ? (
        ""
      ) : (
        <div className="pills-group">
          {selectedTargets.map((target, index) => (
            <div key={index} className={`btn-group pill`} role="group">
              <button
                data-testid="pill"
                type="button"
                className={
                  requestStatus === "Inactive" ? greyPill : greenPillStyle
                }
                onClick={handleClick}
              >
                {target}
              </button>

              {!disableField && (
                <button
                  type="button"
                  value={target}
                  data-testid="cross-pill"
                  data-bs-toggle={tooltipToggleSelector}
                  data-bs-placement="top"
                  data-bs-title={
                    field === "delegates" ? "Remove" : "Add as keyword"
                  }
                  className={`btn btn-success pills`}
                  onMouseEnter={initDelegateTooltips}
                  onMouseLeave={deleteOldTooltips}
                  onClick={(event) => removePill(event)}
                >
                  {field !== "synonyms" ? "x" : "+"}
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      {hasReviewArray ? (
        arrayForReview.length === 0 ? (
          ""
        ) : (
          <div className="pills-group">
            {arrayForReview.map((target, index) => (
              <div key={index} className={`btn-group pill`} role="group">
                <button
                  role="tooltip"
                  data-bs-toggle="red-pills"
                  onClick={handleClick}
                  data-bs-placement="top"
                  data-bs-title={
                    "Another How-to Card is already associated with this keyword. It will be considered, but may not be implemented."
                  }
                  type="button"
                  className={requestStatus === "Inactive" ? greyPill : redPill}
                >
                  {target}
                </button>

                {!disableField && (
                  <button
                    type="button"
                    value={target}
                    className={`btn btn-danger pills-red`}
                    onClick={(event) => removePill(event)}
                  >
                    x
                  </button>
                )}
              </div>
            ))}
          </div>
        )
      ) : (
        ""
      )}
      {field === "delegates" && isLoading ? (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div
            className="spinner-delegates spinner-border spinner-border"
            role="status"
            id="delegates-spinner"
            data-testid="delegates-spinner"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </div>
  );
};

export default HowToPills;
