import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// import { formatDate } from "../../FormatDate/DateFormat";
import StoreContext from "../../../store/storecontext";
import { ThemeContext } from "../../../ThemeContext";


import TC_Validations from "../../../validationClasses/TopicCardValidations";



import ModalCustom from "../../Modal/ModalCustom";
import CompareTableTC from "./CompareTableTC";
import Preview from "../FormUI/Preview/Preview";
import TCPreview from "./TopicCardInputs/Preview/TCPreview";
import CommentHistory from "../FormUI/CommentHistory/CommentHistory";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest";


// form field components and css file
//  (always import the css file + all the needed components, use this as an example)
import "../FormUI/RequestForms.css";
import "./TCForm.css";

import { copyToClipboard } from "../../CopyToClipboard/CopyToClipboard";
import { triggerToast } from "../../Toast/TriggerToast";

// tooltip manager : this will initialize all the tooltips on the fields
import TooltipManager from "../FormUI/TooltipManager";



import FormSpinners from "../FormUI/FormSpinners";
import FormInfoHeader from "../FormUI/FormInfoHeader";

import TitleField from "../FormUI/FormFields/TitleField";
import UrlField from "../FormUI/FormFields/UrlField";
import DescriptionOrCommentField from "../FormUI/FormFields/DescriptionOrCommentField";
import TCLineItems from "./TopicCardInputs/TCLineItems";
import ContactOrDelegateField from "../FormUI/FormFields/ContactOrDelegateField";
import LocationRefinerAndKeywords from "../FormUI/FormFields/LocationRefinerAndKeywords/LocationRefinerAndKeywords";

//buttons wrapper
import TCButtonsWrapper from "./TCButtonsWrapper";


// gen ai modal
import GenAiModal from "../FormUI/GenAIDescription/GenAiModal";
// gen ai methods
import GenAiDescription from "../FormUI/GenAIDescription/GenAiDescription";




const TCForm = () => {
  const requestType = "TC";
  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);
  const store = useContext(StoreContext);
  const [theme] = useContext(ThemeContext);

  const [notFoundMessage, setNotFoundMessage] = useState(false);
  
  const [headerInfo, setHeaderInfo] = useState({
    showLastModified: null,
    showSecondSection: null,
    showInfoHeader: null
  });

  const [isSupportContentChecked, setSupportContentChecked] = useState(idFromUrl === undefined);

  const [isSitesNewsChecked, setSitesNewsChecked] = useState(false);
  const [isLearningMaterialsChecked, setLearningMaterialsChecked] = useState(false);
  const [isSalesMaterialChecked, setSalesMaterialChecked] = useState(false);
  const [isAccenturePoliciesChecked, setAccenturePoliciesChecked] = useState(false);
  const [isInternalResourcesChecked, setInternalResourcesChecked] = useState(false);
  const [isExternalResourcesChecked, setExternalResourcesChecked] = useState(false);

  const [formContainsData, setFormContainsData] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  const [disableFormButtons, setDisableFormButtons] = useState(true);
  const [disableDraftBtn, setDisableDraftBtn] = useState(true);

  
  const urlCheckerService = store.services.urlCheckerService;
  const synonymsServiceRef = store.services.synonymsService;

  const genAIService = store.services.genIAService;
  const [showGenIADescModal, setShowGenIADescModal] = useState(false);
  const [selectedGenAiOption, setSelectedGenAiOption] = useState("");
  const [lastGeneratedDescription, setLastGeneratedDescription] = useState("");
  const [lastGeneratedKeywords, setLastGeneratedKeywords] = useState();
  const [isGenAiLoadingData, setIsGenAiLoadingData] = useState(false);
  const [showGenAiOptions, setShowGenAiOptions] = useState(false);
  const [showGenAiSpinner, setShowGenAiSpinner] = useState(false);
  const [showGenAiWarning, setShowGenAiWarning] = useState(false);
  const [disableRocketBtn, setDisableRocketBtn] = useState(true);


  const locRefKwfields = [
    "countries",
    "refinerPages",
    "keywords",
    "keywordsforreview"
  ];

  const locRefAndKwTooltipObj = {
    locationFieldTooltip:
      "Topic Card can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations.",
    refinerPageTooltip:
      "One or many Search pages (refiner pages) can be selected to display the Topic Card based on the site content and/or where the content resides (e.g., KX Documents, myLearning).",
    keywordsFieldTooltip: `Keyword recommendations:
    <br/>• At least 1 related keyword based on your site content is required; Maximum of 20 keywords can be entered. \n
    <br/>• Avoid generic terms that can be associated with various site content within Accenture, such as "Accenture", "skills", "process", or "analysis". \n
    <br/>• Avoid single keywords unless it is highly specific, such as an acronym. \n
    <br/>• Avoid keywords that have more than three words as it is very unlikely to be entered as a search term.`
  };


  const formData = {
    tcid: 0,
    tcrequestid: 0,
    title: { value: "", isValid: null },
    url: { value: "", isValid: null },
    description: { value: "", isValid: null },
    supportContent: [{ title: "", url: "" }],
    sitesNews: [],
    learningMaterials: [],
    salesMaterial: [],
    accenturePolicies: [],
    internalResources: [],
    externalResources: [],
    getInTouch: { value: [], isValid: null }, //contact experts data, array of objs
    contacts: [],//delegates eids array
    countries: { value: [], isValid: null },
    refinerPages: { value: [], isValid: null },
    keywordsObj: {
      keywords: { value: [], isValid: null },
      keywordsforreview: { value: [], isValid: null }
    },
    delegates: { value: [], isValid: null },//delegates array of objs  {eid + yammerid}
    userIdDelegates: [],
    commentBox: { value: "", isValid: null },
    comments: [],
    history: [],
    organization: [],
    yammerthreadid: null,
    submittedbyuserid: null,
    submittedbyenterpriseid: null,
    creationdate: undefined,
    status: "New",
    template: "standard",
  };
  
  const [tcForm, setTcForm] = useState(formData);


  const lineItemsFormStates = {
    supportContent: [
      {
        title: { state: null, errorMessage: null, inputClass: "" },
        url: { state: null, errorMessage: null, inputClass: "" }
      }
    ],
    sitesNews: [],
    learningMaterials: [],
    salesMaterial: [],
    accenturePolicies: [],
    internalResources: [],
    externalResources: [],
  };

  const [inputStates, setInputStates] = useState(lineItemsFormStates);

  const [originalReqData, setOriginalReqData] = useState({
    tcData: null,
    tcReqData: null,
    formData: null
  });

  const [showPreviewTrigger, setShowPreviewTrigger] = useState(false);
  const [compareTableTrigger, setCompareTableTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formImplementedModal, setFormImplementedModal] = useState({
    show: false,
    msg: "",
    onClose: () => {}
  });

  const updateFormState = (
    _field,
    _userInput,
    _validationResult,
    _replaceWholeArr = null
  ) => {
    let tempForm = { ...tcForm };

    if (
      _field === "keywords" ||
      _field === "keywordsforreview" ||
      _field === "keywordsObj"
    ) {
      if (_field === "keywordsObj") {
        // set data
        tempForm.keywordsObj.keywords.value = _userInput.greenKwList;
        tempForm.keywordsObj.keywordsforreview.value = _userInput.redKwList;

        // set field state
        tempForm.keywordsObj.keywords.isValid =
          _userInput.greenKwList.length > 0;
        tempForm.keywordsObj.keywordsforreview.isValid =
          _userInput.redKwList.length > 0;
      } else {
        // set data
        if (_replaceWholeArr === true) {
          tempForm.keywordsObj[_field].value = _userInput;
        } else if (_replaceWholeArr === null) {
          tempForm.keywordsObj[_field].value.push(_userInput);
        }

        //set field state
        if (_validationResult.hasOwnProperty("isValid")) {
          tempForm.keywordsObj[_field].isValid = _validationResult.isValid;
        } else {
          tempForm.keywordsObj[_field].isValid = _validationResult;
        }
      }
    } else {

      // QQQ update line items (remove this after line item refactor)
      let tempField = "";

      if(_field.includes("_lineItem")){
        tempField = _field.split("_")[0]
        tempForm[tempField] = _userInput;
      }else{
        // set data
        tempForm[_field].value = _userInput;
  
        // set field state
        if (_validationResult.hasOwnProperty("isValid")) {
          tempForm[_field].isValid = _validationResult.isValid;
        } else {
          tempForm[_field].isValid = _validationResult;
        }
      }

    }

    setTcForm({ ...tempForm });
  };

  const disableButtonsAfterValidation = (_disableFormButtons, _disableDraftBtn)=>{

    try {
      let formButtonsDiv = document.querySelector(".form-buttons-div-selector");

      for (let i = 0; i < formButtonsDiv.children.length; i++) {
        let formBtn = formButtonsDiv.children[i];
        formBtn.disabled = formBtn.innerText === "Save Draft" ? _disableDraftBtn : _disableFormButtons;
      }
      
    } catch (error) {
      console.log(" error ", error);
    }

  }

  const checkIfFieldIsEmptyOrInvalid = (_field) => {
    let isFieldEmpty = _field.trim().length === 0;
    let isFieldInvalid = false;
    if (!isFieldEmpty) {
      let res = _field.trim().match(/([\\"])/g);
      isFieldInvalid = res?.length > 0;
    }

    return { isFieldEmpty: isFieldEmpty, isFieldInvalid: isFieldInvalid };
  };

  const enableOrDisableDraftBtn = (_value = null, _field = null) => {

    setDisableDraftBtn(true);
    // check if any field has some value, if it does, enable save draft button
    // \ and " are invalid chars, disable draft button if any of the fields has this
    let titleRes = checkIfFieldIsEmptyOrInvalid(tcForm.title.value);
    let isTitleEmpty = titleRes.isFieldEmpty;
    let isTitleInvalid = titleRes.isFieldInvalid;

    let urlRes = checkIfFieldIsEmptyOrInvalid(tcForm.url.value);
    let isUrlEmpty = urlRes.isFieldEmpty;
    let isUrlInvalid = urlRes.isFieldInvalid;

    let descRes = checkIfFieldIsEmptyOrInvalid(tcForm.description.value);
    let isDescEmpty = descRes.isFieldEmpty;
    let isDescInvalid = descRes.isFieldInvalid;

    let isRefinerEmpty = tcForm.refinerPages.value.length === 0;
    let isLocationEmpty = tcForm.countries.value.length === 0;
    let isKeywordsEmpty = tcForm.keywordsObj.keywords.value.length === 0;
    let isDelegatesEmpty = tcForm.contacts.length === 0;
    

    let fieldsSizeArr = [
      isTitleEmpty,
      isUrlEmpty,
      isDescEmpty,
      isRefinerEmpty,
      isLocationEmpty,
      isKeywordsEmpty,
      isDelegatesEmpty
    ];


    for (let r = 0; r < fieldsSizeArr.length; r++) {
      if (!fieldsSizeArr[r]) {
        setDisableDraftBtn(false);
      }
    }

    let lineItemsNamesArr = [
      "supportContent",
      "accenturePolicies",
      "externalResources",
      "internalResources",
      "learningMaterials",
      "salesMaterial",
      "sitesNews"
    ];

    for (let i = 0; i < lineItemsNamesArr.length; i++) {
      let currLineItem = lineItemsNamesArr[i];
      const tempLineItem = tcForm[currLineItem];
      try {
        if (tempLineItem?.length > 0) {
          for (let i = 0; i < tempLineItem.length; i++) {
            const tempLiEl = tempLineItem[i];
            let liTitleRes = checkIfFieldIsEmptyOrInvalid(tempLiEl.title);
            let liUrlRes = checkIfFieldIsEmptyOrInvalid(tempLiEl.url);

            if (!liTitleRes.isFieldEmpty && !liTitleRes.isFieldInvalid) {
              setDisableDraftBtn(false);
            } else if (liTitleRes.isFieldInvalid) {
              setDisableDraftBtn(true);
            }

            if (!liUrlRes.isFieldEmpty && !liUrlRes.isFieldInvalid) {
              setDisableDraftBtn(false);
            } else if (liUrlRes.isFieldInvalid) {
              setDisableDraftBtn(true);
            }
          }
        }
      } catch (error) {
        console.log("error :", error);
      }
    }

    if (isTitleInvalid || isUrlInvalid || isDescInvalid) {
      setDisableDraftBtn(true);
    }
  };


  const isFormValid = () => {
    let checkedLineItems = [
      {
        item: "supportContent",
        isChecked: isSupportContentChecked,
        state: inputStates.supportContent
      },
      {
        item: "sitesNews",
        isChecked: isSitesNewsChecked,
        state: inputStates.sitesNews
      },
      {
        item: "learningMaterials",
        isChecked: isLearningMaterialsChecked,
        state: inputStates.learningMaterials
      },
      {
        item: "salesMaterial",
        isChecked: isSalesMaterialChecked,
        state: inputStates.salesMaterial
      },
      {
        item: "accenturePolicies",
        isChecked: isAccenturePoliciesChecked,
        state: inputStates.accenturePolicies
      },
      {
        item: "internalResources",
        isChecked: isInternalResourcesChecked,
        state: inputStates.internalResources
      },
      {
        item: "externalResources",
        isChecked: isExternalResourcesChecked,
        state: inputStates.externalResources
      }
    ];

    let isFormValid;
    let isTitleValid = tcForm.title.value !== null && tcForm.title.isValid;
    let isURLValid = tcForm.url.isValid !== false && tcForm.url.value.length >= 0;
    let isRefinerPageValid = tcForm.refinerPages.value.length > 0;
    let isLocationValid = tcForm.countries.value.length > 0;
    let areKeywordsValid = tcForm.keywordsObj.keywords.value.length > 0;
    let areLineItemsValid = true;
    let isDescriptionValid = TC_Validations.validateDescription(
      tcForm.description.value
    ).isValid;
    var lineItemsChecked = 0;
    for (let index = 0; index < checkedLineItems.length; index++) {
      const item = checkedLineItems[index];
      if (item.isChecked && item.state.length > 0) {
        lineItemsChecked++;
        for (let j = 0; j < item.state.length; j++) {
          const state = item.state[j];
          let titleState = state.title.state !== null && state.title.state;
          let urlState = state.url.state !== null && state.url.state;
          areLineItemsValid = areLineItemsValid && titleState && urlState;
        }
      }
    }
    areLineItemsValid = areLineItemsValid && lineItemsChecked > 0;
    
    if(isTitleValid === null){
      isTitleValid = false;
    }
    

    isFormValid =
      isTitleValid &&
      isURLValid &&
      isDescriptionValid &&
      isLocationValid &&
      isRefinerPageValid &&
      areKeywordsValid &&
      areLineItemsValid;


    return isFormValid;
  };

  const updateLineItemState = (lineItem, value, index, field) => {
    if (isLoading) return;
    let states = { ...inputStates };
    states[lineItem][index][field] = value;
    setInputStates(states);
  };

  const setNewLineItem = (lineItem, value) => {
    if (isLoading) return;
    let formArray = { ...tcForm };
    formArray[lineItem] = [...formArray[lineItem], { title: "", url: "" }];
    setTcForm({ ...formArray });
  };

  const setNewLineItemState = (lineItem, value) => {
    if (isLoading) return;
    let statesArray = { ...inputStates };
    statesArray[lineItem] = value;
    setInputStates(statesArray);
  };


  const showKwLoadingSpinner = (_showSpinner) => {
    let spinnerEl = document.querySelector(".keywords-loading-spinner");
    spinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";
    _showSpinner
      ? spinnerEl.classList.remove("change-kw-spinner-size")
      : spinnerEl.classList.add("change-kw-spinner-size");
    let kwListElem = document.querySelector(".form-keywords-list-selector");
    kwListElem.style.visibility = _showSpinner ? "hidden" : "visible";
    _showSpinner
      ? kwListElem.classList.add("change-kw-spinner-size")
      : kwListElem.classList.remove("change-kw-spinner-size");


    try {

      let btnsDiv = document.querySelector(".form-buttons-div-selector");
      let btnsDivChildren = btnsDiv.childNodes;

      for (let i = 0; i < btnsDivChildren.length; i++) {
        const btnEl = btnsDivChildren[i];
        if (btnEl.innerText === "Save Draft") {
          btnEl.disabled = _showSpinner ? true : false;
        } else {
          btnEl.disabled = _showSpinner ? true : false;
        }
      }
    } catch (error) {
    }
  };


  const clipboardHandler = (event) => {
    copyToClipboard(event);
    triggerToast(1000, 0, "copyToClipboard");
  };

  const showPreview = () => {
    setShowPreviewTrigger(true);
  };

  const closeTcPreview = () => {
    setShowPreviewTrigger(false);
  };

  const showCompareTable = () => {
    setCompareTableTrigger(true);
  };

  const closeComparisonTable = () => {
    setCompareTableTrigger(false);
  };

  const enableOrDisableButtons = () => {
    let validationResult = isFormValid();
    setDisableFormButtons(!validationResult);
  };

  const handleCloseGenAiModal = () => {
    setShowGenIADescModal(false);
  };


  const showLoadingSpinner = (_showSpinner, _isOverlaySpinner = null) => {
    // shows spinner and hides form, or viceversa
    let spinnerEl = null;

    if (_isOverlaySpinner) {
      spinnerEl = document.querySelector(".overlay-spinner-selector");
      spinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";
    } else {
      spinnerEl = document.querySelector(".main-form-loading-spinner");
      spinnerEl.style.visibility = _showSpinner ? "visible" : "hidden";

      let mainFormEl = document.querySelector(".form-main-div");
      mainFormEl.style.visibility = _showSpinner ? "hidden" : "visible";

    }
  };

  const closeFormImplementedModal = () => {
    setFormImplementedModal({ ...formImplementedModal, show: false }); //GO TO /myrequests;
    formImplementedModal.onClose();
  };

  const fillStateArray = (data) => {
    let arrOfStates = [];
    data.map((item) => {
      let newItem = {
        title: { state: true, errorMessage: null, inputClass: "is-valid" },
        url: { state: true, errorMessage: null, inputClass: "is-valid" }
      };
      arrOfStates.push(newItem);
    });

    return arrOfStates;
  };


  const setDataOnUI = (_formData) => {

    // check if form object has data before setting the values on the dom.
    // the request id must be different from zero,
    // because we are loading an implemented request into the form
    if (_formData.tcrequestid <= 0) return;

    // title
    let titleEL = document.getElementById(`${requestType}-title-input-field`);
    titleEL.value = _formData.title.value;
    if (_formData.title.value !== "") {
      titleEL.classList.add("is-valid");
    }

    // url
    let urlEL = document.getElementById(`${requestType}-url-input-field`);
    urlEL.value = _formData.url.value;
    if (_formData.url.value !== "") {
      urlEL.classList.add("is-valid");
    }

    // description
    let descriptionEL = document.getElementById(
      `${requestType}-description-input-field`
    );
    descriptionEL.value = _formData.description.value;
    descriptionEL.classList.add("is-valid");
    if (_formData.description.value !== "") {
      descriptionEL.classList.add("is-valid");
    }
  };


  const setFormData = async (tcReqData) => {
    if ((formContainsData || tcReqData === null) && !isLoading) return;

    let dataToDisplay = {
      tcid: 0,
      tcrequestid: 0,
      title: { value: "", isValid: null },
      url: { value: "", isValid: null },
      description: { value: "", isValid: null },
      supportContent: [{ title: "", url: "" }],
      sitesNews: [{ title: "", url: "" }],
      learningMaterials: [{ title: "", url: "" }],
      salesMaterial: [{ title: "", url: "" }],
      accenturePolicies: [{ title: "", url: "" }],
      internalResources: [{ title: "", url: "" }],
      externalResources: [{ title: "", url: "" }],
      getInTouch: { value: [], isValid: null }, //contact experts data, array of objs
      contacts: [],//delegates eids array
      countries: { value: [], isValid: null },
      refinerPages: { value: [], isValid: null },
      keywordsObj: {
        keywords: { value: [], isValid: null },
        keywordsforreview: { value: [], isValid: null }
      },
      delegates: { value: [], isValid: null },//delegates array of objs  {eid + yammerid}
      userIdDelegates: [],
      commentBox: { value: "", isValid: null },
      comments: [],
      history: [],
      organization: [],
      yammerthreadid: null,
      submittedbyuserid: null,
      submittedbyenterpriseid: null,
      creationdate: undefined,
      status: "New",
      template: "standard",
      wasdraft: null
    };

    let lineItemsStates = {
      supportContent: [],
      sitesNews: [],
      learningMaterials: [],
      salesMaterial: [],
      accenturePolicies: [],
      internalResources: [],
      externalResources: []
    };

    await setSupportContentChecked(tcReqData.body.getsupport.length > 0);
    await setSitesNewsChecked(tcReqData.body.newsandarticles.length > 0);
    await setLearningMaterialsChecked(tcReqData.body.learn.length > 0);
    await setSalesMaterialChecked(tcReqData.body.salesmaterial.length > 0);
    await setAccenturePoliciesChecked(
      tcReqData.body.accenturepolicies.length > 0
    );
    await setInternalResourcesChecked(
      tcReqData.body.internalresources.length > 0
    );
    await setExternalResourcesChecked(
      tcReqData.body.externalresources.length > 0
    );

    const isTitleValid =
    TC_Validations.titleLengthValidation(tcReqData.title).isValid &&
    TC_Validations.specialCharsValidation(tcReqData.title).isValid;

    let urlValidResult = await TC_Validations.validateURL(tcReqData.url);
    const isUrlValid = tcReqData.url.length > 0 ? urlValidResult.isValid : null;

    dataToDisplay.title.value = tcReqData.title;
    dataToDisplay.title.isValid = isTitleValid;
    dataToDisplay.url.value = tcReqData.url;
    dataToDisplay.url.isValid = isUrlValid;
    dataToDisplay.description.value = tcReqData.description;
    dataToDisplay.description.isValid = TC_Validations.validateDescription(tcReqData.description).isValid;

    dataToDisplay.supportContent = [...tcReqData.body.getsupport];
    lineItemsStates.supportContent = fillStateArray(tcReqData.body.getsupport);

    dataToDisplay.sitesNews = [...tcReqData.body.newsandarticles];
    lineItemsStates.sitesNews = fillStateArray(dataToDisplay.sitesNews);

    dataToDisplay.learningMaterials = [...tcReqData.body.learn];
    lineItemsStates.learningMaterials = fillStateArray(
      dataToDisplay.learningMaterials
    );

    dataToDisplay.salesMaterial = [...tcReqData.body.salesmaterial];
    lineItemsStates.salesMaterial = fillStateArray(dataToDisplay.salesMaterial);

    dataToDisplay.accenturePolicies = [...tcReqData.body.accenturepolicies];
    lineItemsStates.accenturePolicies = fillStateArray(
      dataToDisplay.accenturePolicies
    );

    dataToDisplay.internalResources = [...tcReqData.body.internalresources];
    lineItemsStates.internalResources = fillStateArray(
      dataToDisplay.internalResources
    );

    dataToDisplay.externalResources = [...tcReqData.body.externalresources];
    lineItemsStates.externalResources = fillStateArray(
      dataToDisplay.externalResources
    );

    tcReqData.body.getintouch.map((el) => {
      let entry = {
        eid: el.eid,
        fullname: el.fullname,
        role: el.role,
        url: el.url
      };
      if (el !== null || el !== undefined) {
        dataToDisplay.getInTouch.value.push(entry);
      }

    });

    let tcCleanKeywords =
      tcReqData.keywordsraw.length > 0
        ? tcReqData.keywordsraw
        : tcReqData.keywords;
    let tcInvalidKeywords =
      tcReqData.invalidkeywordsraw.length > 0
        ? tcReqData.invalidkeywordsraw
        : tcReqData.invalidkeywords;

    dataToDisplay.contacts = [...tcReqData.contacts];
    dataToDisplay.refinerPages.value = [...tcReqData.aid];
    dataToDisplay.countries.value = [...tcReqData.country];
    dataToDisplay.keywordsObj.keywords.value = [...tcCleanKeywords];
    dataToDisplay.keywordsObj.keywords.isValid = true;
    dataToDisplay.keywordsObj.keywordsforreview.value = [...tcInvalidKeywords];
    dataToDisplay.keywordsObj.keywordsforreview.isValid = true;
    dataToDisplay.delegates.value = [...tcReqData.delegates];
    dataToDisplay.userIdDelegates = [];
    dataToDisplay.comments = [...tcReqData.comments];
    dataToDisplay.history = [...tcReqData.history];
    dataToDisplay.organization = [...tcReqData.organization];
    dataToDisplay.status = tcReqData.status;
    dataToDisplay.tcid = tcReqData.tcid;
    dataToDisplay.tcrequestid = tcReqData.docID;
    dataToDisplay.yammerthreadid = tcReqData.yammerthreadid;
    dataToDisplay.submittedbyuserid = tcReqData.submittedbyuserid;
    dataToDisplay.submittedbypeoplekey = tcReqData.submittedbypeoplekey;
    dataToDisplay.submittedbyuserid = tcReqData.submittedbyuserid;
    dataToDisplay.submittedbyenterpriseid = tcReqData.submittedbyenterpriseid;
    dataToDisplay.creationdate = tcReqData.creationdate;
    dataToDisplay.wasdraft = tcReqData.wasdraft;

    // set line items states
    await setInputStates(lineItemsStates);

    
    // set the form mode
    const tempReqData = {
      isAdmin: store.state.isAdmin,
      submittedbyenterpriseid: dataToDisplay.submittedbyenterpriseid,
      delegates: dataToDisplay.delegates.value,
      status: dataToDisplay.status
    };

    const isReadOnly = await TC_Validations.validateFormMode(tempReqData);
    setIsFormReadOnly(isReadOnly);

    enableOrDisableButtons();

    try {
      let buttonsDiv = document.querySelector(".form-buttons-div-selector");
      buttonsDiv.style.visibility = !isReadOnly ? "visible" : "hidden";
      
      let urlEl = document.querySelector(".kw-div-elements-holder-selector");
      urlEl.classList.remove("TC-url-field-extra-margin");
      
      let locRefAndKwEl = document.querySelector(
        ".location-refiner-and-keywords-main-div"
      );

      if(isReadOnly){
        locRefAndKwEl.style.marginLeft = 0;
      }


    } catch (error) {
      console.log("error on hiding buttons div : ", error);
    }

    try {
      let delegatesDiv = document.querySelector(
        ".TC-contact-and-del-field-extra-margin-2"
      );

      if(isReadOnly){
        delegatesDiv.style.marginLeft = 0;
      }

      
    } catch (error) {
      
    }

    return dataToDisplay;
  };


  const loadFormHandler = async (tcReqID) => {
    if (formContainsData) return;

    let tcQuery = null;
    let tcData = null;
    let lastRequest = null;
    let tcReqQuery = null;
    let tcReqData = {};

    showLoadingSpinner(true);
    setIsLoading(true);
    setDisableFormButtons(true);
    setDisableDraftBtn(true);

    tcReqQuery = await store.services.topicCardService.GetTopicCardRequest(
      tcReqID
    );

    let totalHits = tcReqQuery.data.hits.total.value;
    if (totalHits === 0) {
      showLoadingSpinner(false);
      setIsLoading(false);
      setNotFoundMessage(true);
    } else {
      if (tcReqQuery.data.hits.hits.length > 1) {
        lastRequest = tcReqQuery.data.hits.hits.pop();
        tcReqData = lastRequest._source;
      } else {
        tcReqData = tcReqQuery.data.hits.hits[0]._source;
        tcReqData.docID = parseInt(tcReqQuery.data.hits.hits[0]._id);
      }

      if (tcReqData.status === "Implemented" || tcReqData.tcid > 0) {
        tcQuery = await store.services.topicCardService.GetTopicCardByID(
          tcReqData.tcid
        );

        if (tcQuery.data.hits.hits.length > 1) {
          tcData = tcQuery.data.hits.hits.pop();
          tcData = tcData._source;
        } else {
          tcData = tcQuery.data.hits.hits[0]._source;
          tcData.docID = parseInt(tcQuery.data.hits.hits[0]._id);
        }
      }

      let currentOriginalData = { ...originalReqData };
      let deepCopyTcData = JSON.parse(JSON.stringify(tcData));
      let deepCopyTcReqData = JSON.parse(JSON.stringify(tcReqData));
      let reqDataObj = await setFormData(deepCopyTcReqData);

      currentOriginalData.tcData = deepCopyTcData;
      currentOriginalData.tcReqData = deepCopyTcReqData;
      currentOriginalData.formData = JSON.parse(JSON.stringify(reqDataObj));

      console.log("QQQ currentOriginalData ", currentOriginalData);

      await setOriginalReqData({ ...currentOriginalData });

      if (
        parseInt(idFromUrl) > 0 &&
        !formContainsData &&
        tcForm.title.value.length <= 0
      ) {
        // load form data into tcForm object
        await setTcForm({...JSON.parse(JSON.stringify(reqDataObj))});
        setDataOnUI(JSON.parse(JSON.stringify(reqDataObj)));
        setFormContainsData(true);
        setIsLoading(false);
        showLoadingSpinner(false);
      }
    }
  };


  const saveAILogs = async (data) => {
    try {
      const response = await store.services.aiLogsService.SaveAIUsage(data);
      console.log("logs saved ", response);
    } catch (error) {
      console.log("error on saving logs ", error);
    }
  };


  const getSelectedGenAiData = async (selectedOption) => {
    await setLastGeneratedKeywords([]);

    let dateObj = new Date();
    let formattedDate = dateObj.toISOString().replace("T", " ").slice(0, 19);

    if (selectedOption === "description") {
      //save log
      const data = {
        eid: localStorage.getItem("searchtools.eid"),
        url: tcForm.url.value,
        parent: "topiccard",
        type: "description",
        result: lastGeneratedDescription,
        date: formattedDate
      };
      saveAILogs(data);

      // make loading spinner spin for a while, then show modal with data
      setIsGenAiLoadingData(true);

      setTimeout(() => {
        setIsGenAiLoadingData(false);
      }, 2000);
    } else {
      // enable loading spinner untill data is ready to be shown to the user
      setIsGenAiLoadingData(true);
      let requestedKeywords = 0;
      let currGreenKw = tcForm.keywordsObj.keywords.value.length;
      let currRedKw = tcForm.keywordsObj.keywordsforreview.value.length;
      let totalCurrKw = currGreenKw + currRedKw;
      requestedKeywords = 20 - totalCurrKw;

      let genAiKwObj = {};
      let validKwList = [];
      let currLocationsSelected = [...tcForm.countries.value];
      let currRefinersSelected = [...tcForm.refinerPages.value];
      if (requestedKeywords !== 0) {
        try {
          genAiKwObj = await GenAiDescription.GetGenAiKeywords(
            tcForm.url.value,
            tcForm.url.isValid,
            urlCheckerService,
            genAIService,
            requestedKeywords
          );

          let keywordParams = {
            keyword: "",
            location: currLocationsSelected,
            refinerPage: currRefinersSelected,
            tcrequestid: null,
            abortController: null,
            service: store.services.topicCardService
          };

          // only show valid keywords to the user
          for (let i = 0; i < requestedKeywords; i++) {
            if (i < 20) {
              let kw = genAiKwObj.keywords[i];
              let lowerCaseKw = "";
              kw = kw.replaceAll(".", "");
              lowerCaseKw = kw.toLowerCase();
              keywordParams.keyword = kw;
              let validationResult =
                await TC_Validations.validateKeyword(keywordParams);
              keywordParams.keyword = "";
              if (
                validationResult.isValid &&
                !tcForm.keywordsObj.keywords.value.includes(lowerCaseKw) &&
                !tcForm.keywordsObj.keywords.value.includes(kw)
              ) {
                validKwList.push(kw);
              }
            }
          }

          if (validKwList.length > 0) {
            genAiKwObj.keywords = [...validKwList];
            await setLastGeneratedKeywords(genAiKwObj.keywords);
            setIsGenAiLoadingData(false);

            let kwListAsString = genAiKwObj.keywords.toString();
            const data = {
              eid: localStorage.getItem("searchtools.eid"),
              url: tcForm.url.value,
              parent: "topiccard",
              type: "keywords",
              result: kwListAsString,
              date: formattedDate
            };
            saveAILogs(data);
          } else if (validKwList.length <= 0) {
            setIsGenAiLoadingData(false);
            try {
              setTimeout(() => {
                let kwResultInfo =
                  document.getElementById("generated-keywords");
                kwResultInfo.innerText =
                  "We were unable to generate additional keywords for this URL.";
              }, 700);
            } catch (error) {
              console.log("err : ", error);
            }
          }
        } catch (error) {
          setIsGenAiLoadingData(false);
          console.log("err : ", error);
          try {
            setTimeout(() => {
              let kwResultInfo = document.getElementById("generated-keywords");
              kwResultInfo.innerText =
                "Something went wrong, please try again.";
            }, 700);
          } catch (error) {
            console.log("err : ", error);
          }
        }
      }
    }
  };


  const openGenAiModal = async (e, isRocketBtnDisabled) => {
    let descRadioButton = document.getElementById(
      "gen-ai-option-radio-btn-description"
    );
    let keywordsRadioButton = document.getElementById(
      "gen-ai-option-radio-btn-keywords"
    );
    let selectedOption = "";

    if (descRadioButton.checked) {
      selectedOption = "description";
    } else if (keywordsRadioButton.checked) {
      selectedOption = "keywords";
    }

    setSelectedGenAiOption(selectedOption);
    if (!isRocketBtnDisabled) {
      e.preventDefault();
      setShowGenIADescModal(true);
      await getSelectedGenAiData(selectedOption);
    }
  };


  const updateDescUI = () => {
    //adds the descrption generated by the gen ai
    // to the description field UI (form data is saved in another method)
    let descriptionEL = document.getElementById(`TC-description-input-field`);
    descriptionEL.value = lastGeneratedDescription;
    descriptionEL.classList.add("is-valid");
  };


  const isUrlClean = () => {
    let regex = urlCheckerService.textRegex;
    return !tcForm.url.value.match(regex);
  };



  
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------

  // form setup useEffect, hides some elements of the ui and set other for later use
  useEffect(() => {
    try {

      if(idFromUrl > 0){
        let urlEl = document.querySelector(".kw-div-elements-holder-selector");
        urlEl.style.marginLeft = "1.5em";
      }
    
      // hide keyword field spinner
      let kwSpinnerEl = document.querySelector(".keywords-loading-spinner");
      kwSpinnerEl.classList.add("change-kw-spinner-size");

      // hide synonyms suggestion list
      let synSuggDiv = document.querySelector(".kw-synonyms-list-selector");
      synSuggDiv.style.visibility = "hidden";
      synSuggDiv.classList.add("change-kw-spinner-size");

    } catch (error) {}
  }, []);


  useEffect(() => {
    TooltipManager.instantiateTooltips(requestType);

    let validationResult = isFormValid();
    
    if(validationResult === null){
      setDisableFormButtons(true);
    }else{
      setDisableFormButtons(!validationResult);
    }

    enableOrDisableDraftBtn();

    disableButtonsAfterValidation(disableFormButtons, disableDraftBtn);
    
  }, [tcForm]);


  // use effect for when the user loads request via browser url
  useEffect(() => {
    if (parseInt(idFromUrl) > 0) {
      loadFormHandler(idFromUrl);
    }
  }, [idFromUrl]);

  useEffect(() => {
    // after the data from index has been saved, fill the form
    if (
      originalReqData.tcData?.docID ||
      originalReqData.tcReqData?.tcrequestid
    ) {
      
      setHeaderInfo({
        showLastModified: idFromUrl === null || idFromUrl === undefined,
        showSecondSection: idFromUrl > 0,
        showInfoHeader: false || originalReqData.tcReqData?.tcrequestid > 0
      });

    }
  }, [originalReqData]);


  var userInputTimeout;
  useEffect(() => {
    var isCancelled = false;

    if(showGenAiSpinner) return;

    if (tcForm.url.value.length === 0) {
      GenAiDescription.enableRocketBtn(false);
      setDisableRocketBtn(true);
      setShowGenAiSpinner(false);
      setShowGenAiWarning(false);
    } else {
      if (isFormReadOnly) {
        GenAiDescription.enableRocketBtn(false);
        setDisableRocketBtn(true);
        setShowGenAiSpinner(false);
        setShowGenAiWarning(false);
        return;
      }
      try {
        userInputTimeout = setTimeout(async () => {
          if (isUrlClean() && tcForm.url.value !== "") {

            let urlFieldEl = document.querySelector(".kw-div-elements-holder-selector");
            urlFieldEl.style.marginLeft = "0";
            urlFieldEl.classList.remove("TC-url-field-extra-margin");
      
            GenAiDescription.showRocketBtn(false);
            setShowGenAiSpinner(true);

            try {
              let genAiDescObj = await GenAiDescription.GetGenAiDescription(
                tcForm.url.value,
                tcForm.url.isValid,
                urlCheckerService,
                genAIService
              );

              //Check if call was cancelled(the user modified the input)
              if (isCancelled || genAiDescObj === undefined) {

                setShowGenAiSpinner(false);
                GenAiDescription.showRocketBtn(true);
                setDisableRocketBtn(true);
                setShowGenAiWarning(false);
                return;
              }
              if (genAiDescObj.hasOwnProperty("showRocketBtn")) {

                setShowGenAiSpinner(false);
                GenAiDescription.showRocketBtn(true);

                if (
                  genAiDescObj.description !== "" &&
                  !genAiDescObj.description.includes(
                    "There is not enough information about this site"
                  )
                ) {
                  console.log("enablerocketbtn ", genAiDescObj.enableRocketBtn);
                  GenAiDescription.enableRocketBtn(
                    genAiDescObj.enableRocketBtn
                  );
                  setDisableRocketBtn(!genAiDescObj.enableRocketBtn);
                  setShowGenAiWarning(false);
                } else {
                  GenAiDescription.enableRocketBtn(false);
                  setDisableRocketBtn(true);
                  setShowGenAiWarning(true);
                }
              }
              setLastGeneratedDescription(genAiDescObj.description);
            } catch (error) {
              console.log("error : ", error);
              setShowGenAiWarning(true);
              setShowGenAiSpinner(false);
              GenAiDescription.showRocketBtn(true);
              GenAiDescription.enableRocketBtn(false);
              setDisableRocketBtn(true);
            }
          }
        }, 800);

        return () => {
          clearTimeout(userInputTimeout);
          isCancelled = true;
        };
      } catch (error) {}
    }
  }, [tcForm.url.value]);
  

  return (
    <>
      <FormSpinners />
      {notFoundMessage && <NotFoundRequest />}
      <form className={`form-main-div form-tc`} role="form" noValidate>
        <FormInfoHeader
          reqType={requestType}
          formName={"Topic Card"}
          requestId={tcForm.tcrequestid}
          lastRequestedBy={originalReqData?.tcReqData?.lastmodificationdate} //use this to store the last modified date value
          formRequestType={"Topic Card"}
          formStatus={tcForm.status}
          cleanCreationdate={""}
          showLastModified={headerInfo.showLastModified}
          showSecondSection={headerInfo.showSecondSection}
          showInfoHeader={headerInfo.showInfoHeader}
        />
        {/* inputType value must be equal 
        to form object property (formData.title for example)  */}
        <TitleField
          reqType={requestType}
          inputType={"title"}
          labelText={"Title"}
          placeholderText={"Enter a unique Topic Card title"}
          ariaLabelText={
            "Title Info. For better Search results relevancy for this requested Topic Card, enter the site title as it appears when navigating to the entered URL"
          }
          tooltipText={
            "Topic Titles must be unique, make sure your topic is not already created."
          }
          isRequired={true}
          isDisabledProp={isFormReadOnly}
          validateField={TC_Validations.validateTitle}
          updateFormState={updateFormState}
        />
        <UrlField
          reqType={requestType}
          inputType={"url"}
          labelText={"URL"}
          placeholderText={"Enter a URL for this Topic Card (optional)"}
          ariaLabelText={
            "Provide a main URL source to display (optional)."
          }
          tooltipText={
            "Provide a main URL source to display (optional)."
          }
          isRequired={false}
          isDisabledProp={isFormReadOnly}
          urlCheckerService={urlCheckerService}
          validateField={TC_Validations.validateURL}
          updateFormState={updateFormState}
          openGenAiModal={openGenAiModal}
          showGenAiSpinner={showGenAiSpinner}
          disableGenIaBtn={disableRocketBtn}
          showAiWarning={showGenAiWarning}
          hideGenAiFeature={isFormReadOnly}
          setSelectedGenAiOption={selectedGenAiOption}
          showGenAiOptions={showGenAiOptions}
          setShowGenAiOptions={setShowGenAiOptions}
          currFormData={tcForm}
          cssTheme={theme}
        />
        
        <DescriptionOrCommentField
          reqType={requestType}
          inputType={"description"}
          labelText={"Description"}
          placeholderText={
            "Enter a brief description of site content that will display below the Topic Card title; the description is intended to attract users to view/click this Topic Card."
          }
          ariaLabelText={
            "Please enter a description."
          }
          tooltipText={`Add a comprehensive description about your topic, up to 1000 characters.`}
          isRequired={true}
          isDisabledProp={isFormReadOnly}
          validateField={TC_Validations.validateDescription}
          updateFormState={updateFormState}
          textAreaValueProp={tcForm.description.value}
        />
        {/* LINE ITEMS HERE  */}
        <div className="row my-4 tc-line-items-pos-div">
          <div className="tc-line-items-div">
            <span className="span-title">
              Select the section for your topic card and add a link to
              each of them
            </span>
            <br></br>
            <TCLineItems
              reqType={requestType}
              title={"Support Content"}
              isDiamond={false}
              isChecked={isSupportContentChecked}
              setIsChecked={setSupportContentChecked}
              lineItem={tcForm.supportContent}
              setLineItem={updateFormState}
              lineItemId={"support-content"}
              field="supportContent"
              state={inputStates.supportContent}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Sites & News"}
              isDiamond={false}
              isChecked={isSitesNewsChecked}
              setIsChecked={setSitesNewsChecked}
              lineItem={tcForm.sitesNews}
              setLineItem={updateFormState}
              lineItemId={"sites-news"}
              field="sitesNews"
              state={inputStates.sitesNews}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Learning Materials"}
              isDiamond={false}
              isChecked={isLearningMaterialsChecked}
              setIsChecked={setLearningMaterialsChecked}
              lineItem={tcForm.learningMaterials}
              setLineItem={updateFormState}
              lineItemId={"learning-materials"}
              field="learningMaterials"
              state={inputStates.learningMaterials}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Sales Material"}
              isDiamond={false}
              isChecked={isSalesMaterialChecked}
              setIsChecked={setSalesMaterialChecked}
              lineItem={tcForm.salesMaterial}
              setLineItem={updateFormState}
              lineItemId={"sales-materials"}
              field="salesMaterial"
              state={inputStates.salesMaterial}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Accenture Policies"}
              isDiamond={false}
              isChecked={isAccenturePoliciesChecked}
              setIsChecked={setAccenturePoliciesChecked}
              lineItem={tcForm.accenturePolicies}
              setLineItem={updateFormState}
              lineItemId={"accenture-policies"}
              field="accenturePolicies"
              state={inputStates.accenturePolicies}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"Internal Resources"}
              isDiamond={true}
              isChecked={isInternalResourcesChecked}
              setIsChecked={setInternalResourcesChecked}
              lineItem={tcForm.internalResources}
              setLineItem={updateFormState}
              lineItemId={"internal-resources"}
              field="internalResources"
              state={inputStates.internalResources}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
            <TCLineItems
              reqType={requestType}
              title={"External Resources"}
              isDiamond={true}
              isChecked={isExternalResourcesChecked}
              setIsChecked={setExternalResourcesChecked}
              lineItem={tcForm.externalResources}
              setLineItem={updateFormState}
              lineItemId={"external-resources"}
              field="externalResources"
              state={inputStates.externalResources}
              setState={updateLineItemState}
              setNewLineItem={setNewLineItem}
              setNewLineItemState={setNewLineItemState}
              disableField={isFormReadOnly}
              formContainsData={formContainsData}
            />
          </div>
          <ContactOrDelegateField
            reqType={requestType}
            inputType={"contact-experts-input"}
            labelText={"Contact Experts"}
            formName={"Topic Card"}
            isRequired={false}
            updateFormState={updateFormState}
            readOnlyMode={isFormReadOnly}
            formValidations={TC_Validations}
            currFormData={tcForm}
            displayGrayPills={isFormReadOnly}
            placeholderText={"Contacts of this Topic Card"}
            ariaLabelText={"Contacts of this Topic Card"}
            tooltipText={"If needed, please provide the Enterprise ID of an expert who can serve as a contact for this topic card."}
            clipboardHandler={clipboardHandler}
          />
        </div>
        {/* fields is the array containing the name of the form object related to each input.
         its like the field inputType on other components,
          it points to the field wich then will be updated by the updateFormState method */}
        <LocationRefinerAndKeywords
          reqType={requestType}
          formName={"Topic Card"}
          fields={locRefKwfields}
          formValidations={TC_Validations}
          updateFormState={updateFormState}
          currFormData={tcForm}
          idFromUrl={idFromUrl}
          readOnlyMode={isFormReadOnly}
          displayGrayPills={isFormReadOnly}
          tooltipsObj={locRefAndKwTooltipObj}
          showKwLoadingSpinner={showKwLoadingSpinner}
          synonymsServiceRef={synonymsServiceRef}
        />
        <ContactOrDelegateField
          reqType={requestType}
          inputType={"delegates-input"}
          labelText={"Delegates"}
          formName={"Topic Card"}
          isRequired={false}
          updateFormState={updateFormState}
          readOnlyMode={isFormReadOnly}
          formValidations={TC_Validations}
          currFormData={tcForm}
          displayGrayPills={isFormReadOnly}
          placeholderText={"Delegates who can edit this request (optional)"}
          ariaLabelText={"Delegates who can edit this request (optional)"}
          tooltipText={"If needed, enter at least one Enterprise ID that can serve as a delegate to edit the Topic Card and receive related notifications."}
          clipboardHandler={clipboardHandler}
        />
        <div className="TC-description-and-comments-div">
          <DescriptionOrCommentField
            reqType={requestType}
            inputType={"commentBox"}
            labelText={"Comments"}
            placeholderText={
              "If needed, provide additional comments/details to be considered as part of this Topic Card request."
            }
            ariaLabelText={
              "Comments input. If needed, provide additional comments, details, to be considered as part of this Topic Card request."
            }
            tooltipText={
              "This comment field can also be used to provide additional information requested by the Search CoE team as part of the Topic Card request process."
            }
            isRequired={false}
            isDisabledProp={isFormReadOnly}
            validateField={TC_Validations.validateComments}
            updateFormState={updateFormState}
            textAreaValueProp={undefined}
          />
          {formContainsData && parseInt(idFromUrl) > 0 ? (
            <div className="tc-comments-main-div">
              {tcForm.comments.map((comment, idx) => (
                <div className="comments tc-comments-fix-width" key={idx}>
                  <CommentHistory
                    reqType={requestType}
                    user={comment.user}
                    date={comment.dateUTC}
                    comment={comment.comment}
                    commentCSS={"comment-tc"}
                  />
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* this is a wrapper for the buttons, 
        the buttons now will be ui elements, and they will have attached the methods references,
        but the methods and how each forms saves the data is handled by the wrapper, 
        the FormButtons component is imported inside the wrapper,
         so each form will have a unique wrapper, 
         but all of them will use the FormButtons component   */}
        <TCButtonsWrapper
          reqType={requestType}
          formName={"Topic Card"}
          idFromUrl={idFromUrl}
          disableFormButtons={disableFormButtons}
          disableDraftButton={disableDraftBtn}
          cssTheme={theme}
          appStore={store}
          form={tcForm}
          originalData={originalReqData} //check which data actually or pass whole obj
          showPreviewBool={showPreview}
          setPreviewContent={setPreviewContent}
          callCompareTable={showCompareTable}
          showLoadingSpinner={showLoadingSpinner}
          setModalProps={(msg, onClose) => {
            setFormImplementedModal({
              show: true,
              msg: msg,
              onClose: onClose
            });
          }}
        />
        {showPreviewTrigger && (
            <Preview
              preview={<TCPreview preview={previewContent} />}
              closePreview={closeTcPreview}
            />
        )}
        {/* -------------------------------------- modals section */}
        {compareTableTrigger ? (
              <div className="tc_compare_sticky_div">
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeComparisonTable}
                  onClose={closeComparisonTable}
                  modal_msg={
                    <CompareTableTC
                      originalReqData={originalReqData}
                      currentData={tcForm}
                      closeComparisonTable={closeComparisonTable}
                    />
                  }
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize={"modal-xl"}
                  noHeader={true}
                />
              </div>
            ) : (
              <></>
        )}
        {formImplementedModal.show ? (
          <ModalCustom
            darkMode={theme === "dark"}
            onClick={closeFormImplementedModal}
            onClose={closeFormImplementedModal}
            modal_msg={formImplementedModal.msg}
            btn_1_class={
              theme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
            modalSize=""
            noHeader={true}
          />
        ) : (
          <></>
        )}
        {showGenIADescModal && (
          <GenAiModal
            closeModal={handleCloseGenAiModal}
            selectedGenAiOption={selectedGenAiOption}
            lastGeneratedDescription={lastGeneratedDescription}
            lastGeneratedKeywords={lastGeneratedKeywords}
            replaceDescription={updateFormState}
            addNewKeywords={updateFormState}
            currFormData={tcForm}
            updateDescUI={updateDescUI}
            url={tcForm.url.value}
            BB_Validations={null}
            TC_Validations={TC_Validations}
            validationType={"TopicCardValidations"}
            isGenAiLoadingData={isGenAiLoadingData}
            changeIsGenAiLoadingData={setIsGenAiLoadingData}
            setShowGenAiOptions={setShowGenAiOptions}
            requestType={requestType}
          />
        )}
      </form>
    </>
  );
};

export default TCForm;
