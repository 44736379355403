export const filterSearcherRequests = (dataToFilter) => {
  var filteredRequests = dataToFilter.requests;
  const abbreviation = {
    "Topic Card": ["TC"],
    "Custom Topic Card": ["CTC"],
    "Best Bet": ["BB"],
    "How-To Card": ["HT"],
    Synonym: ["SY"]
  };

  switch (dataToFilter.filter) {
    case "type":
      filteredRequests = filteredRequests.filter(
        (req) => req.reqType == abbreviation[dataToFilter.value]
      );
      break;
    case "page":
      filteredRequests = filteredRequests.filter((req) => {
        if (req.hasOwnProperty("refPages")) {
          if (req.refPages.includes(dataToFilter.value)) {
            return req;
          }
        }
      });
      break;
    case "location":
      filteredRequests = filteredRequests.filter((req) => {
        if (req.hasOwnProperty("locations")) {
          if (req.locations.includes(dataToFilter.value)) {
            return req;
          }
        }
      });
  }
  if (
    dataToFilter.filter !== "type" &&
    dataToFilter.activeFilters.type.status
  ) {
    filteredRequests = filteredRequests.filter(
      (req) =>
        req.reqType == abbreviation[dataToFilter.activeFilters.type.value]
    );
  }
  if (
    dataToFilter.filter !== "page" &&
    dataToFilter.activeFilters.page.status
  ) {
    filteredRequests = filteredRequests.filter((req) => {
      if (req.hasOwnProperty("refPages")) {
        if (req.refPages.includes(dataToFilter.activeFilters.page.value)) {
          return req;
        }
      }
    });
  }
  if (
    dataToFilter.filter !== "location" &&
    dataToFilter.activeFilters.location.status
  ) {
    filteredRequests = filteredRequests.filter((req) => {
      if (req.hasOwnProperty("locations")) {
        if (req.locations.includes(dataToFilter.activeFilters.location.value)) {
          return req;
        }
      }
    });
  }
  return filteredRequests;
};

export const getFilterOptions = (allRequests, typeRequest) => {
  var typeOptions = new Set();
  var refinerOptions = new Set();
  var locationsWithoutGlobal = new Set();
  var isGlobalPresent = false;
  for (let index = 0; index < allRequests.length; index++) {
    const req = allRequests[index];
    typeOptions.add(typeRequest[req.reqType]);
    if (req.hasOwnProperty("locations")) {
      req.locations.forEach((loc) => {
        isGlobalPresent = isGlobalPresent || loc === "Global";
        if (loc !== "Global") {
          locationsWithoutGlobal.add(loc);
        }
      });
    }

    if (req.hasOwnProperty("refPages")) {
      req.refPages.forEach((page) => refinerOptions.add(page));
    }
  }
  var locationOptions;
  if (isGlobalPresent) {
    locationOptions = ["Global", ...Array.from(locationsWithoutGlobal).sort()];
  } else {
    locationOptions = Array.from(locationsWithoutGlobal).sort();
  }
  return {
    locationOptions: locationOptions,
    refinerPageOptions: Array.from(refinerOptions),
    typeOptions: Array.from(typeOptions)
  };
};
