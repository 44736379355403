import { useEffect, useState } from "react";

const CompareTableTC = (props) => {
  const [firstTableRows, setFirstTableRows] = useState();
  const [secondTableRows, setSecondTableRows] = useState();
  const [lineItemsTableRows, setLineItemsTableRows] = useState();

  const getComparedData = (
    propertyToCompare,
    formPropertyName,
    isArray = null
  ) => {
    let originalData = null;
    let currentReqData = null;

    if (props.originalReqData.tcData !== null) {
      originalData = { ...props.originalReqData.tcData };
    }

    currentReqData = { ...props.originalReqData.tcReqData };

    let data_before = originalData[propertyToCompare];
    let data_after = "";
    let isKwToCompare = false;

    switch (propertyToCompare) {
      case "cleanurl":
        propertyToCompare = "url";
        break;
      case "cleandescription":
        propertyToCompare = "description";
        break;
      case "cleankeywordsraw":
        propertyToCompare = "keywordsraw";
        isKwToCompare = true;
        break;
      case "cleancountry":
        propertyToCompare = "country";
        break;
      case "sthcAID":
        propertyToCompare = "aid";
        break;
      case "contacts":
        propertyToCompare = "getInTouch";
        break;

      default:
        propertyToCompare = propertyToCompare;
        break;
    }
    var valueOld;
    var valueNew;

    if (propertyToCompare === "getInTouch") {

      data_before = currentReqData["body"]["getintouch"].map(
        (contact) => contact.eid
      );
      valueOld = data_before.toString();
      let dataNew = props.currentData.getInTouch.value.map(
        (contact) => contact.eid
      );
      valueNew = dataNew.toString();

    } else if (isKwToCompare){

      valueOld = isArray
        ? currentReqData[propertyToCompare].toString()
        : currentReqData[propertyToCompare];

      valueNew = isArray
        ? props.currentData.keywordsObj.keywords.value.toString()
        : props.currentData.keywordsObj.keywords.value;

    } else {

      valueOld = isArray
        ? currentReqData[propertyToCompare].toString()
        : currentReqData[propertyToCompare];

      valueNew = isArray
        ? props.currentData[formPropertyName].value.toString()
        : props.currentData[formPropertyName].value;

    }


    data_after = valueOld === valueNew ? valueOld : valueNew;

    let newData = { before: data_before, after: data_after };
    return newData;
  };

  const getAddedOrRemovedData = (propertyToCompare, formPropertyName) => {
    let originalData = null;
    let currentReqData = null;

    if (props.originalReqData.tcData !== null) {
      originalData = { ...props.originalReqData.tcData };
    }

    currentReqData = { ...props.originalReqData.tcReqData };

    let addedData = null;
    let removedData = null;

    if(formPropertyName === "keyword"){
      addedData = props.currentData.keywordsObj.keywords.value.filter((kw) => {
        let newVal = "";
        if (!originalData[propertyToCompare].includes(kw)) {
          newVal = kw;
          return kw;
        }
      });
  
      addedData = addedData.toString();
  
      removedData = originalData[propertyToCompare].filter((kw) => {
        let newVal = "";
        if (!props.currentData.keywordsObj.keywords.value.includes(kw)) {
          newVal = kw;
          return kw;
        }
      });
  
      removedData = removedData.toString();
    }else {

      addedData = props.currentData[formPropertyName].value.filter((kw) => {
        let newVal = "";
        if (!originalData[propertyToCompare].includes(kw)) {
          newVal = kw;
          return kw;
        }
      });
  
      addedData = addedData.toString();
  
      removedData = originalData[propertyToCompare].filter((kw) => {
        let newVal = "";
        if (!props.currentData[formPropertyName].value.includes(kw)) {
          newVal = kw;
          return kw;
        }
      });
  
      removedData = removedData.toString();
    }


    let comparedData = { added: addedData, removed: removedData };

    return comparedData;
  };

  const getLineItemsData = () => {
    let originalData = null;
    let currentReqData = null;
    if (props.originalReqData.tcData !== null) {
      originalData = { ...props.originalReqData.tcData };
    }

    currentReqData = { ...props.originalReqData.tcReqData };

    let supportContent =
      props.currentData.supportContent.length > 0
        ? props.currentData.supportContent
        : currentReqData.body.getsupport;

    let sitesNews =
      props.currentData.sitesNews.length > 0
        ? props.currentData.sitesNews
        : currentReqData.body.newsandarticles;

    let learningMaterials =
      props.currentData.learningMaterials.length > 0
        ? props.currentData.learningMaterials
        : currentReqData.body.learn;

    let salesMaterial =
      props.currentData.salesMaterial.length > 0
        ? props.currentData.salesMaterial
        : currentReqData.body.salesmaterial;

    let accenturePolicies =
      props.currentData.accenturePolicies.length > 0
        ? props.currentData.accenturePolicies
        : currentReqData.body.accenturepolicies;

    let internalResources =
      props.currentData.internalResources.length > 0
        ? props.currentData.internalResources
        : currentReqData.body.internalresources;

    let externalResources =
      props.currentData.externalResources.length > 0
        ? props.currentData.externalResources
        : currentReqData.body.externalresources;

    let originalLineItemsTitles = [...originalData.titles];
    let originalLineItemsUrls = [...originalData.urls];

    let currentLineItems = [
      ...supportContent,
      ...sitesNews,
      ...learningMaterials,
      ...salesMaterial,
      ...accenturePolicies,
      ...internalResources,
      ...externalResources
    ];
    const currentTitles = currentLineItems.map((item) => item.title);
    const currentUrls = currentLineItems.map((item) => item.url);

    const addedTitles = currentTitles
      .filter((title) => !originalLineItemsTitles.includes(title))
      .toString();

    const removedTitles = originalLineItemsTitles
      .filter((title) => !currentTitles.includes(title))
      .toString();

    const addedUrls = currentUrls
      .filter((url) => !originalLineItemsUrls.includes(url))
      .toString();

    const removedUrls = originalLineItemsUrls
      .filter((url) => !currentUrls.includes(url))
      .toString();

    let comparedData = {
      titles: { added: addedTitles, removed: removedTitles },
      urls: { added: addedUrls, removed: removedUrls }
    };

    return comparedData;
  };

  useEffect(() => {
    //close modal with esc key
    let tableDiv = document.querySelector("#tc-comparison-table-main-div");

    tableDiv.addEventListener("keydown", (e) => {
      if (e.code === "Escape") {
        props.closeComparisonTable();
      }
    });

    tableDiv.focus();

    // generate data for tables
    // compare implemented data vs request data, if data on the form changes,
    // compare implemented data against the new changes

    // first table data (before/after)
    let titleData = getComparedData("title", "title");
    let urlData = getComparedData("cleanurl", "url");
    let descData = getComparedData("cleandescription", "description");
    let keywordsData = getComparedData("cleankeywordsraw", "keyword", true);
    let locationsData = getComparedData("cleancountry", "countries", true);
    let aidData = getComparedData("sthcAID", "refinerPages", true);
    let getInTouchData = getComparedData("contacts", "contacts", true);

    let trClass = "wrap-text-table-row";

    let firstTableRows = [
      <tr className={trClass} key={"title_before_after"} tabIndex="0">
        <td tabIndex="0">Title</td>
        <td tabIndex="0">{titleData.before}</td>
        <td tabIndex="0">{titleData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"url_before_after"}>
        <td tabIndex="0">Url</td>
        <td tabIndex="0">{urlData.before}</td>
        <td tabIndex="0">{urlData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"desc_before_after"}>
        <td tabIndex="0">Description</td>
        <td tabIndex="0">{descData.before}</td>
        <td tabIndex="0">{descData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"loc_before_after"}>
        <td tabIndex="0">Location</td>
        <td tabIndex="0">{locationsData.before.join(",")}</td>
        <td tabIndex="0">{locationsData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"aid_before_after"}>
        <td tabIndex="0">Refiner Page</td>
        <td tabIndex="0">{aidData.before.join(",")}</td>
        <td tabIndex="0">{aidData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"kw_before_after"}>
        <td tabIndex="0">Keywords</td>
        <td tabIndex="0">{keywordsData.before.join(",")}</td>
        <td tabIndex="0">{keywordsData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"get_in_touch_before_after"}>
        <td tabIndex="0">Contact Experts</td>
        <td tabIndex="0">{getInTouchData.before.join(",")}</td>
        <td tabIndex="0">{getInTouchData.after}</td>
      </tr>
    ];

    // second table data (added/removed)
    let keywordChanges = getAddedOrRemovedData("cleankeywordsraw", "keyword");
    let locationChanges = getAddedOrRemovedData("cleancountry", "countries");
    let aidChanges = getAddedOrRemovedData("sthcAID", "refinerPages");
    let lineItemsTitlesChanges = getLineItemsData("titles");
    let lineItemsUrlsChanges = getLineItemsData("urls");

    let secondTableRows = (
      <tr tabIndex="0">
        <td tabIndex="0">{keywordChanges.added}</td>
        <td tabIndex="0">{keywordChanges.removed}</td>
        <td tabIndex="0">{locationChanges.added}</td>
        <td tabIndex="0">{locationChanges.removed}</td>
        <td tabIndex="0">{aidChanges.added}</td>
        <td tabIndex="0">{aidChanges.removed}</td>
      </tr>
    );

    // third table (line items)
    let lineItemsRows = (
      <tr tabIndex="0">
        <td tabIndex="0">{lineItemsTitlesChanges.titles.added}</td>
        <td tabIndex="0">{lineItemsTitlesChanges.titles.removed}</td>
        <td className="table-cell-text-wrap" tabIndex="0">
          {lineItemsUrlsChanges.urls.added}
        </td>
        <td className="table-cell-text-wrap" tabIndex="0">
          {lineItemsUrlsChanges.urls.removed}
        </td>
      </tr>
    );

    setFirstTableRows(firstTableRows);
    setSecondTableRows(secondTableRows);
    setLineItemsTableRows(lineItemsRows);
  }, []);

  return (
    <div
      id="tc-comparison-table-main-div"
      className="table-holder"
      data-testid="compare-table"
      tabIndex="0"
    >
      <div
        id="tc-comparison-tables-div"
        className="container-fluid tc-comparison-tables-div"
      >
        <h2 className="tc-comparison-tables-title">Topic Card Comparison</h2>

        <div id="tc-comp-table-1-div" className="table_1_div">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th className={"tc-table-fields-col"}>Fields</th>
                <th className={"tc-table-before-col"}>Before</th>
                <th className={"tc-table-after-col"}>After</th>
              </tr>
            </thead>
            <tbody className={""}>{firstTableRows}</tbody>
          </table>
        </div>
        <div id="tc-comp-table-line-items-div" className="table_line_items_div">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className={""}>
                <th>Added Titles</th>
                <th>Removed Titles</th>
                <th>Added URLs</th>
                <th>Removed URLs</th>
              </tr>
            </thead>
            <tbody className={""}>{lineItemsTableRows}</tbody>
          </table>
        </div>
        <div id="tc-comp-table-2-div" className="table_2_div">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className={""}>
                <th>Added Keywords</th>
                <th>Removed Keywords</th>
                <th>Added Locations</th>
                <th>Removed Locations</th>
                <th>Added Refiner Pages</th>
                <th>Removed Refiner Pages</th>
              </tr>
            </thead>
            <tbody className={""}>{secondTableRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompareTableTC;
