import React, { useEffect, useState } from "react";
import FieldLabelAndIcon from "./FieldLabelAndIcon";

const DescriptionOrCommentField = ({
  reqType,
  inputType,
  labelText,
  placeholderText,
  ariaLabelText,
  tooltipText,
  isRequired,
  isDisabledProp,
  validateField,
  updateFormState,
  textAreaValueProp
}) => {
  const inputID = `${reqType}-${inputType}-input-field`;
  const errorMsgBoxID = `${reqType}-${inputType}-error-msg-box`;
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (textAreaValueProp === undefined || textAreaValueProp === null) return;
    const validationResult = validateField(textAreaValueProp);
    updateUIElements(validationResult, textAreaValueProp);
  }, [textAreaValueProp]);

  // methods
  const updateUIElements = (_validationResult, _userInput) => {
    let domField = null;
    let errorMessageDiv = null;

    domField = document.getElementById(inputID);
    if (_validationResult.isValid) {
      try {
        errorMessageDiv = document.getElementById(errorMsgBoxID);
        errorMessageDiv.textContent = "";
        errorMessageDiv.hidden = true;
        domField.classList.remove("is-invalid");
      } catch (error) {
        console.log("error 1 on description field.");
      }

      if (_userInput.length === 0) {
        domField.classList.remove("is-invalid");
        domField.classList.remove("is-valid");
      }else{
        domField.classList.add("is-valid");
      }

    } else if (!_validationResult.isValid) {
      try {
        domField.classList.remove("is-valid");
      } catch (error) {
        console.log("error 2 on description field.");
      }

      if (_userInput.length !== 0) {
        domField.classList.add("is-invalid");
        //show error msg if any
        try {
          if (_validationResult.errorMsg.length > 0) {
            errorMessageDiv = document.getElementById(errorMsgBoxID);
            errorMessageDiv.textContent = _validationResult.errorMsg;
            errorMessageDiv.hidden = false;
          }
        } catch (error) {
          console.log("error 3 on description field.");
        }
      } else if (_userInput.length === 0) {
        domField.classList.remove("is-invalid");
        domField.classList.remove("is-valid");
        errorMessageDiv = document.getElementById(errorMsgBoxID);
        errorMessageDiv.textContent = "";
        errorMessageDiv.hidden = true;
      }
    }
  };

  const processUserInput = async (_userInput) => {
    try {
      let validationResult = await validateField(_userInput);
      updateUIElements(validationResult, _userInput);
      updateFormState(inputType, _userInput, validationResult);
    } catch (error) {
      console.log("error processUserInput description field.", error);
    }
  };

  const getFieldValue = (event) => {
    const userInput = event.target.value;
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      processUserInput(userInput);
    }, 900);

    setTimer(newTimer);
  };

  return (
    <>
      {isDisabledProp && inputType === "commentBox" ? (
        <></>
      ) : (
        <div id={`${reqType}-${inputType}-input-field-div`}>
          <FieldLabelAndIcon
            reqType={reqType}
            inputType={inputType}
            inputID={`${inputID}-label`}
            isRequired={isRequired}
            labelText={labelText}
            tooltipText={tooltipText}
          />
          {/* consider adding the ctc description field in this component */}
          <textarea
            id={inputID}
            type="text"
            wrap="soft"
            className={`form-control`}
            data-testid={`${reqType}-${inputType}-text-area`}
            placeholder={placeholderText}
            aria-label={ariaLabelText}
            onChange={getFieldValue}
            disabled={isDisabledProp}
          />
          <div id={errorMsgBoxID} className="invalid-field-div"></div>
        </div>
      )}
    </>
  );
};

export default DescriptionOrCommentField;
