import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarDay } from "react-icons/fa";

const MetricsDate = ({
  dateLabelTitle,
  selected,
  handleChange,
  minDate,
  maxDate,
  sectionName
}) => {
  const handleDateChange = (event) => {
    handleChange(event);
  };

  return (
    <div className="from-date to-date">
      <label
        id={`metrics-${sectionName}-label`}
        className={"label-text label-date"}
      >
        {dateLabelTitle}
      </label>
      <div className="date-picker-and-calendar-btn-div">
        <ReactDatePicker
          id={"metrics-" + sectionName + "-date-picker"}
          onChange={handleDateChange}
          className={"form-control metrics-date"}
          selected={selected}
          minDate={minDate}
          maxDate={maxDate ?? ""}
          showMonthYearPicker
          placeholderText="mm/yyyy"
          dateFormat="MM/yyyy"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
        <button aria-label="metric-date" className="btn btn-primary calendar">
          <FaCalendarDay className="calendar-icon" />
        </button>
      </div>
    </div>
  );
};

export default MetricsDate;
