import Axios from "axios";
import SearchToolsService from "../services/SearchToolsService";
import TopicCardService from "../services/TopicCardService";
import HowToService from "../services/HowToService";
import URLCheckerService from "../services/URLCheckerService";
import YammerService from "../services/YammerService";
import BestBetService from "../services/BestBetService";
import ReportingService from "../services/ReportingService";
import SynonymService from "../services/SynonymsService";
import MetricsFeedbackService from "../services/MetricsFeedbackService";
import ActiveContent from "../services/ActiveContentService";
import GenIAService from "../services/GenIAService";
import AILogsService from "../services/AILogsService";

let apiUrl = process.env.REACT_APP_SERVICE_URL;

Axios.defaults.headers.common.Accept = "application/json";

export default {
  searchToolsService: new SearchToolsService(Axios, apiUrl),
  topicCardService: new TopicCardService(Axios, apiUrl),
  urlCheckerService: new URLCheckerService(Axios, apiUrl),
  yammerService: new YammerService(Axios, apiUrl),
  bestBetService: new BestBetService(Axios, apiUrl),
  reportingService: new ReportingService(Axios, apiUrl),
  howToService: new HowToService(Axios, apiUrl),
  synonymsService: new SynonymService(Axios, apiUrl),
  metricsFeedbackService: new MetricsFeedbackService(Axios, apiUrl),
  activeContentService: new ActiveContent(Axios, apiUrl),
  genIAService: new GenIAService(Axios, apiUrl),
  aiLogsService: new AILogsService(Axios, apiUrl)
};
