import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "../ToggleButton/ToggleButton";
import { Tooltip } from "bootstrap";

const HomeMenuOption = (props) => {
  const navigate = useNavigate();
  const [createdTooltips, setCreatedTooltips] = useState();
  const pageLocation = useLocation();

  const tooltipClass = props.theme === "dark" ? "bb-tooltip-dark" : "";
  const isMenuOpen = () => {
    let isOpen = sessionStorage.getItem("isHomeMenuOpen");
    return isOpen === "true";
  };
  const deleteOldTooltips = () => {
    let oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const initializeTitleTooltip = () => {
    let currMenuState = sessionStorage.getItem("isHomeMenuOpen");

    // show tooltips when menu is closed
    if (currMenuState === "true") {
      deleteOldTooltips();
      try {
        createdTooltips.map((t) => {
          t.dispose();
        });
      } catch (error) {}
      return;
    } else if (currMenuState === "false") {
      deleteOldTooltips();
      let tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="menu-opt-li-tooltip"]'
      );
      let newTooltips = [];
      let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
        let t = new Tooltip(tooltipTriggerEl, {
          trigger: "hover",
          customClass: tooltipClass
        });
        newTooltips.push(t);
      });
      setCreatedTooltips(newTooltips);
    }
  };

  let toggleAction = props.toggleOptions.action;

  const setActiveOption = (target) => {
    let liElements = document.querySelectorAll(".home-menu-option-li");
    for (let i = 0; i < liElements.length; i++) {
      let element = liElements[i];
      try {
        element.classList.remove("active-home-menu-option");
      } catch {}
    }

    let currLiElement = target.parentElement.parentElement;
    let validElement = null;
    if (currLiElement.classList.contains("home-menu-option-li")) {
      currLiElement.classList.add("active-home-menu-option");
      validElement = currLiElement;
    } else {
      currLiElement = target.parentElement;
      if (currLiElement.classList.contains("home-menu-option-li")) {
        currLiElement.classList.add("active-home-menu-option");
        validElement = currLiElement;
      } else {
        currLiElement = target;
        currLiElement.classList.add("active-home-menu-option");
        validElement = currLiElement;
      }
    }
    let currMenuState = sessionStorage.getItem("isHomeMenuOpen");
    if (currMenuState === "false") {
      validElement.classList.add("add-li-width");
    }
  };

  const redirectToPage = (event) => {
    event.preventDefault();
    if (props.toggleOptions.isToggle) {
      return "";
    } else {
      if (props.containsAction) {
        toggleAction();
        setActiveOption(event.target);
      }
      if (props.redirectWithRouter) {
        navigate(props.href);
        setActiveOption(event.target);
      } else {
        window.location.assign(props.href);
      }
    }
  };

  const changeIschecked = () => {
    let swElement = document.querySelector(`#${props.elemID}`);
    let currState = swElement.checked;
    swElement.checked = !currState;
    toggleAction();
  };

  const buttonAction = (event) => {
    if (props.toggleOptions.isToggle) {
      changeIschecked();
    } else {
      redirectToPage(event);
    }
  };

  let itemToReturn = <></>;

  let tooltipText = "";

  if (props.toggleOptions.toggleName === "dark-theme-toggle") {
    tooltipText = props.optionText + " On/Off";
  } else {
    tooltipText = props.optionText;
  }

  let darkModeActive = props.theme === "dark" ? "darkModeActive" : "";

  itemToReturn = (
    <li
      id={"home-li-" + props.optionText}
      role="presentation"
      className="home-menu-option-li home-menu-option-li-hover-effect"
      onClick={buttonAction}
      data-bs-toggle={"menu-opt-li-tooltip"}
      data-bs-placement="right"
      data-bs-title={tooltipText}
      data-pagesection={props.pageSection}
      onMouseEnter={initializeTitleTooltip}
    >
      <button
        className={"home-opt-action-btn " + darkModeActive}
        data-testid={props.testId}
        aria-label={props.ariaLabel}
      >
        {props.optionIcon}
        {isMenuOpen() && (
          <label
            htmlFor={"home-li-" + props.optionText}
            className="home-opt-li-label"
          >
            {props.optionText}
          </label>
        )}
      </button>
      {props.toggleOptions.isToggle && (
        <ToggleButton
          elemID={props.elemID}
          name={props.toggleOptions.toggleName}
          btnAction={toggleAction}
        />
      )}
    </li>
  );
  useEffect(() => {
    if (!sessionStorage.getItem("isHomeMenuOpen")) {
      sessionStorage.setItem("isHomeMenuOpen", "true");
    }
  }, []);
  useEffect(() => {
    // set active menu tab on page reload
    let liElements = document.querySelectorAll(".home-menu-option-li");
    let currPath =
      pageLocation.pathname === "/"
        ? "home"
        : pageLocation.pathname.split("/")[1];

    Array.from(liElements).map((el) => {
      if (el.dataset.pagesection === currPath) {
        el.classList.add("active-home-menu-option");
      } else {
        el.classList.remove("active-home-menu-option");
      }
      let currMenuState = sessionStorage.getItem("isHomeMenuOpen");
      if (currMenuState === "false") {
        el.classList.add("add-li-width");
      }
    });
  }, [window.location.pathname]);

  return itemToReturn;
};

export default HomeMenuOption;
