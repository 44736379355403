import { copyToClipboard } from "../../../../CopyToClipboard/CopyToClipboard";
import { triggerToast } from "../../../../Toast/TriggerToast";
import LocationOrRefinerPageField from "./LocationOrRefinerPageField";
import KeywordsField from "./KeywordsField";

const LocationRefinerAndKeywords = ({
  reqType,
  formName,
  fields,
  formValidations,
  updateFormState,
  currFormData,
  idFromUrl,
  readOnlyMode,
  displayGrayPills,
  tooltipsObj,
  showKwLoadingSpinner,
  synonymsServiceRef
}) => {
  const formPillsStyle = "form-pills-style";
  const formPillsBtnFontWeight = "form-pills-btn-font-weigth";

  // this are the names of each field, but specific to each form object
  // (used to pass as parameter for the updateFormState method)
  const locField = fields[0];
  const refField = fields[1];
  const greenKwField = fields[2];
  const redKwField = fields[3];
  const kwFieldsObj = {
    greenKwField: greenKwField,
    redKwField: redKwField
  };

  const keywordInputElID = `${reqType}-keyword-input`;

  const clipboardHandler = (event) => {
    copyToClipboard(event);
    triggerToast(1000, 0, "copyToClipboard");
  };

  // check if kw is from own request
  //  _resArr is an array of requests from the index
  const checkIfOwnKeyword = (_resArr) => {
    let reqCounter = 0;
    let reqIDpointer = null;
    if (idFromUrl === undefined || idFromUrl === null) return reqCounter;

    // check for reqType
    // to access correct values from result objects
    switch (reqType) {
      case "BB":
        reqIDpointer = "bbrequestid";
        break;

      default:
        break;
    }

    for (let cnt = 0; cnt < _resArr.length; cnt++) {
      const reqID = _resArr[cnt]._source[reqIDpointer];
      if (parseInt(reqID) === parseInt(idFromUrl)) {
        reqCounter++;
      }
    }
    return reqCounter;
  };

  const revalidateAllKeywords = async (
    _currLocationsSelected,
    _currRefinersSelected
  ) => {
    // the way the keywords field component is written enables this method
    // to take the keywords of each field
    //  (currFormData.keywords, and currFormData.keywordsforreview)
    // and revalidate them, later, it will rearrange the values
    // accoring to the results of the validations, once the formData is updated
    // the re-render will create the correct keywords on the dom
    // (that is done by the keywordsField component)
    // all forms must have this property to hanlde the keywords
    // keywordsObj: {
    //     keywords: { value : [] , isValid : null},
    //     keywordsforreview: { value : [] , isValid : null},
    //   }

    let greenKwsList = [...currFormData.keywordsObj.keywords.value];
    let redKwsList = [...currFormData.keywordsObj.keywordsforreview.value];
    let newGreenKwList = [];
    let newRedKwList = [];

    let currLocationsSelected = [...currFormData.countries.value];
    let currRefinersSelected = [...currFormData.refinerPages.value];

    for (let i = 0; i < greenKwsList.length; i++) {
      const kw = greenKwsList[i];

      let lowercaseKw = kw.trim().toLowerCase();

      let validationResult = null;
      
      switch (reqType) {
        case "BB":
            validationResult = await formValidations.validateKeyword(
              lowercaseKw,
              currLocationsSelected,
              currRefinersSelected
            );
          break;
        case "TC":
            validationResult = await formValidations.validateKeyword(
              lowercaseKw,
              currLocationsSelected,
              currRefinersSelected,
              currFormData.tcrequestid
            );
          break;
      
        default:
          break;
      }
      

      if (validationResult.isValid) {
        newGreenKwList.push(kw);
      } else {
        newRedKwList.push(kw);
      }
    }

    for (let j = 0; j < redKwsList.length; j++) {
      const kw = redKwsList[j];

      let lowercaseKw = kw.trim().toLowerCase();
      
      let validationResult = null;
      
      switch (reqType) {
        case "BB":
            validationResult = await formValidations.validateKeyword(
              lowercaseKw,
              currLocationsSelected,
              currRefinersSelected
            );
          break;
        case "TC":
            validationResult = await formValidations.validateKeyword(
              lowercaseKw,
              currLocationsSelected,
              currRefinersSelected,
              currFormData.tcrequestid
            );
          break;
      
        default:
          break;
      }

      if (validationResult.isValid) {
        newGreenKwList.push(kw);
      } else {
        newRedKwList.push(kw);
      }
    }

    let newKwsList = {
      greenKwList: [...newGreenKwList],
      redKwList: [...newRedKwList]
    };

    try {
      showKwLoadingSpinner(false);
    } catch (error) {}
    updateFormState("keywordsObj", newKwsList, true);
  };

  const onLocationOrAidChange = async () => {
    if (
      currFormData.countries.value.length > 0 &&
      currFormData.refinerPages.value.length > 0 &&
      (currFormData.keywordsObj.keywords.value.length > 0 ||
        currFormData.keywordsObj.keywordsforreview.value.length > 0)
    ) {
      showKwLoadingSpinner(true);
    }

    let currLocationsSelected = null;
    let currRefinersSelected = null;

    if (currFormData.countries.hasOwnProperty("value")) {
      currLocationsSelected = [...currFormData.countries.value];
    }
    if (currFormData.refinerPages.hasOwnProperty("value")) {
      currRefinersSelected = [...currFormData.refinerPages.value];
    }

    // revalidate keywords
    await revalidateAllKeywords(currLocationsSelected, currRefinersSelected);
  };

  return (
    <div
      id={`location-refiner-and-keywords-main-div`}
      className={"location-refiner-and-keywords-main-div"}
    >
      <LocationOrRefinerPageField
        reqType={reqType}
        inputType={"dropdown"}
        formName={formName}
        field={locField}
        fieldNameForLabel={"Location"}
        isLocationField={true}
        updateFormState={updateFormState}
        onLocationOrAidChange={onLocationOrAidChange}
        readOnlyMode={readOnlyMode}
        formPillsStyle={formPillsStyle}
        formPillsBtnFontWeight={formPillsBtnFontWeight}
        currFormData={currFormData.countries.value}
        displayGrayPills={displayGrayPills}
        tooltipText={tooltipsObj.locationFieldTooltip}
      />
      <LocationOrRefinerPageField
        reqType={reqType}
        inputType={"dropdown"}
        formName={formName}
        field={refField}
        fieldNameForLabel={"Refiner page"}
        isLocationField={false}
        updateFormState={updateFormState}
        onLocationOrAidChange={onLocationOrAidChange}
        readOnlyMode={readOnlyMode}
        formPillsStyle={formPillsStyle}
        formPillsBtnFontWeight={formPillsBtnFontWeight}
        currFormData={currFormData.refinerPages.value}
        displayGrayPills={displayGrayPills}
        tooltipText={tooltipsObj.refinerPageTooltip}
      />
      <KeywordsField
        reqType={reqType}
        inputType={"keyword-input"}
        formName={formName}
        fields={kwFieldsObj}
        updateFormState={updateFormState}
        readOnlyMode={readOnlyMode}
        formPillsStyle={formPillsStyle}
        formPillsBtnFontWeight={formPillsBtnFontWeight}
        formValidations={formValidations}
        currFormData={currFormData}
        displayGrayPills={displayGrayPills}
        tooltipText={tooltipsObj.keywordsFieldTooltip}
        showLoadingSpinner={showKwLoadingSpinner}
        clipboardHandler={clipboardHandler}
        synonymsServiceRef={synonymsServiceRef}
      />
    </div>
  );
};

export default LocationRefinerAndKeywords;
