import services from "../store/services";

const bbService = services.bestBetService;
const urlCheckerService = services.urlCheckerService;

const validateTitle = (title) => {
  let regex = urlCheckerService.textRegex;

  if (title.match(regex)) {
    return {
      isValid: false,
      errorMsg: 'Please do not include the special characters \\ and "'
    };
  }
  if (title.trim() === "") {
    return { isValid: false, errorMsg: "Please enter a title" };
  }
  if (title.length <= 65) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      errorMsg: "The title must be 65 characters or less"
    };
  }
};
const validateURL = async (url, urlCheckerServRef) => {
  url = url || "";
  let cleanurlLowercase = url.toLowerCase();

  let myLearningRx = urlCheckerServRef.myLearningRx;
  let collectionsRx = urlCheckerServRef.collectionsRx;
  let kxContributionRx = urlCheckerServRef.kxContributionRx;
  let internalSiteRx = urlCheckerServRef.internalSiteRx;
  let URLRegExp2 = urlCheckerServRef.URLRegExp2;
  let URLRegExp = urlCheckerServRef.URLRegExp;

  const invalidInternalSiteUrl =
    "Please enter a live/active site URL for this Best Bet.";
  const invalidMyLrnUrlMsg =
    "Please add an online training that is globally available.";
  let regex = urlCheckerServRef.urlRegex;
  if (!url.match(regex)) {
    return {
      isValid: false,
      errorMsg: "This is not a valid url format"
    };
  }
  //------
  if (cleanurlLowercase && myLearningRx.test(cleanurlLowercase)) {
    const myLearning = await urlCheckerServRef.GetMyLearningHits(url);
    const totalHits = myLearning.data.hits.total.value;
    if (totalHits === 0) {
      return { isValid: false, errorMsg: invalidMyLrnUrlMsg };
    } else {
      return { isValid: true, errorMsg: "" };
    }
  } else if (cleanurlLowercase && collectionsRx.test(cleanurlLowercase)) {
    const collections = await urlCheckerServRef.GetCollectionHits(url);
    let collectionsErrorMessage =
      "Please enter an official collection URL for this Best Bet; for assistance, please contact the KX team.";
    const totalHits = collections.data.hits.total.value;
    if (totalHits === 0) {
      return { isValid: false, errorMsg: collectionsErrorMessage };
    } else {
      return { isValid: true, errorMsg: "" };
    }
  } else if (cleanurlLowercase && kxContributionRx.test(cleanurlLowercase)) {
    const kx = await urlCheckerServRef.GetKXContributionHits(url);
    const kxContributionErrorMessage =
      "Please enter an approved KX contribution URL for this Best Bet; for approval assistance, please contact the KX team.";
    const totalHits = kx.data.hits.total.value;
    if (totalHits === 0) {
      return { isValid: false, errorMsg: kxContributionErrorMessage };
    } else {
      return { isValid: true, errorMsg: "" };
    }
  } else if (cleanurlLowercase && internalSiteRx.test(cleanurlLowercase)) {
    const internalSiteHome = getHomeUrl(cleanurlLowercase).toLowerCase();
    const internalSites = await urlCheckerServRef.GetInternalSitesData(
      url,
      internalSiteHome
    );
    const totalHits = internalSites.data.hits.total.value;
    if (totalHits === 0) {
      return {
        isValid: false,
        errorMsg: invalidInternalSiteUrl,
        internalSitesStatus: false,
        showDisclaimerUrl: false
      };
    } else {
      return {
        isValid: true,
        errorMsg: "",
        internalSitesStatus: true,
        showDisclaimerUrl: true
      };
    }
  } else if (
    cleanurlLowercase &&
    URLRegExp.test(cleanurlLowercase) &&
    URLRegExp2.test(cleanurlLowercase)
  ) {
    return { isValid: true, errorMsg: "" };
  } else {
    return {
      isValid: false,
      errorMsg: "This is not a valid url format"
    };
  }
};

const validateDescription = (description) => {
  let regex = urlCheckerService.textRegex;
  let descriptionCharacterLimit = process.env.REACT_APP_BB_DESCRIPTION_LIMIT;

  if (description.match(regex)) {
    return {
      isValid: false,
      errorMsg: 'Please do not include the special characters \\ and "'
    };
  }
  if (description.trim() === "") {
    return { isValid: false, errorMsg: "Please enter a description" };
  }
  if (description.length <= descriptionCharacterLimit) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      errorMsg: `The description must be ${descriptionCharacterLimit} characters or less.`
    };
  }
};
const validateComment = (comment) => {
  let regex = urlCheckerService.textRegex;

  if (comment.match(regex)) {
    return {
      isValid: false,
      errorMsg: 'Please do not include the special characters \\ and "'
    };
  }
  if (comment.length <= 1000) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      errorMsg: "Comments must be 1000 characters or less."
    };
  }
};

const validateKeyword = async (keyword, countries, acronyms) => {
  let req, totalHits;
  try {
    req = await bbService?.GetBestBetsByKeyword(keyword.toLowerCase());
    totalHits = req?.data?.hits?.total?.value;
  } catch (error) {
    console.error("Error fetching best bets by keyword:", error);
    totalHits = 0;
  }
  var isKeywordValid = true;
  var location;
  var refPage;

  if (totalHits > 0) {
    const matchingBBs = req.data.hits.hits;
    var allCombinations = {};
    /*get all (country : ref pages) combinations used for this keyword, in the format:   
                           {
                                Argentina : {
                                  sts: 3,
                                  kx: 1
                                }
                           }
      */
    matchingBBs.forEach((bb) => {
      bb._source.cleancountry.forEach((country) => {
        if (allCombinations[country]) {
          bb._source.aid.forEach((refPage) => {
            if (allCombinations[country][refPage]) {
              allCombinations[country][refPage]++;
            } else allCombinations[country][refPage] = 1;
          });
        } else {
          bb._source.aid.forEach((refPage) => {
            allCombinations[country] = {};
            allCombinations[country][refPage] = 1;
          });
        }
      });
    });
    const globalUsages = allCombinations["Global"];
    var globalTotal = 0;
    var countryTotal = 0;
    countries.forEach((selectedCountry) => {
      const allSelectedCountryCombinations = allCombinations[selectedCountry];

      acronyms.forEach((page) => {
        if (globalUsages && globalUsages[page]) {
          globalTotal = globalUsages[page];
        }
        if (
          allSelectedCountryCombinations &&
          allSelectedCountryCombinations[page]
        ) {
          countryTotal = allSelectedCountryCombinations[page];
        }
        if (selectedCountry === "Global") {
          //if i want to add 'Global' then total global usages must be less than 3 and no country reached the limit for that page
          isKeywordValid =
            isKeywordValid &&
            globalTotal < 3 &&
            Object.values(allCombinations).every((country) => {
              return !country[page] || country[page] < 3;
            });
        } else {
          isKeywordValid = isKeywordValid && globalTotal + countryTotal < 3;
        }
        if (!isKeywordValid) {
          location = location ? location : selectedCountry;
          refPage = refPage ? refPage : page;
          return;
        }
      });
    });
  }
  return {
    isValid: isKeywordValid,
    location: location,
    refPage: refPage
  };
};
const validateKeywordField = (validLenght, invalidLenght) => {
  if (validLenght === 0 && invalidLenght > 0) {
    return {
      isValid: false,
      errorMsg:
        "Please enter at least one valid, relevant keyword in order to successfully submit this Best Bet request."
    };
  } else if (validLenght + invalidLenght > 19) {
    return { isValid: false, errorMsg: "Only 20 keywords are allowed." };
  }

  return { isValid: true };
};

function getHomeUrl(url) {
  let newUrl = url.split("/");
  let internalSiteHome = "https://in.accenture.com/".concat(newUrl[3]);
  return internalSiteHome;
}

const BB_Validations = {
  validateTitle,
  validateURL,
  validateDescription,
  validateComment,
  validateKeyword,
  validateKeywordField
};

export default BB_Validations;
