import { useState, useEffect, useContext } from "react";
import TopicCardTooltips from "./TopicCardTooltips";
import rocketImage1 from "../../../../assets/images/rocket_01.svg";
import rocketImage2 from "../../../../assets/images/rocket_02.svg";
import rocketDisabled from "../../../../assets/images/rocket_disabled.svg";
import "../../FormUI/GenAIDescription/genAiModal&Button.css";
import "../TCForm.css";
import TooltipManager from "../../FormUI/TooltipManager";
import GenAiLoadingSpinnerTC from "./GenAiLoadingTC";
import StoreContext from "../../../../store/storecontext";
import ModalCustom from "../../../Modal/ModalCustom";

const TopicURL = ({
  value,
  setValue,
  urlErrorMessage,
  inputState,
  validateURL,
  disableField,
  tooltipFor,
  showGenAiSpinner,
  openGenAiModal,
  disableGenIaBtn,
  hideGenAiFeature,
  showGenAiWarning,
  showGenAiOptions,
  setShowGenAiOptions,
  selectedKeywords,
  keywordsForReview,
  selectedLocation,
  selectedRefinerPage,
  cssTheme
}) => {
  let titleURL = {
    tooltip: "Provide a main URL source to display (optional).",
    alt: "Title Info. For better Search results relevancy for this requested Topic Card, enter the site title as it appears when navigating to the entered URL"
  };
  const tooltipBody = `<div class="summarize-description" data-testid="summarize-tooltip">
        <p class="tooltip-header"><b>Gen AI</b></p>
        <p class="tooltip-info">
          Generate descriptions and keywords with Gen AI
        </p>
    </div>`;
  useEffect(() => {
    TooltipManager.instantiateTooltips("tc");
  });

  const store = useContext(StoreContext);

  const urlCheckerService = store.services.urlCheckerService;

  const [validInputClass, setValidInputClass] = useState("base");
  const [oldValue, setOldValue] = useState();

  const [showKwModal, setShowKwModal] = useState(false);
  const [kwModalMsg, setKwModalMsg] = useState("");

  const rocketImage = false ? rocketImage2 : rocketImage1;

  const rocketImg = () => {
    return disableGenIaBtn ? rocketDisabled : rocketImage;
  };

  const enableGenAiOptions = () => {
    return !disableGenIaBtn;
  };

  const onClickModalHandler = () => {
    setShowKwModal(false);
  };

  let regex = urlCheckerService.textRegex;
  const isUrlClean = () => {
    let finalClass = inputState["url"] ? "is-valid" : "is-invalid";
    if (
      !value.match(regex) &&
      value !== "" &&
      finalClass === "is-valid" &&
      !showGenAiSpinner
    ) {
      return showGenAiWarning;
    }
  };

  const genAiWarningMsg =
    "This URL cannot be reached by Gen AI. You may use the form traditionally.";

  useEffect(() => {
    var finalClass;
    if (value === "") {
      finalClass = "";
    } else {
      finalClass = inputState["url"] ? "is-valid" : "is-invalid";
    }
    setValidInputClass(finalClass);
  }, [inputState]);

  const errorMsg = () => {
    if (validInputClass === "is-invalid") {
      return (
        <div id="validate-input" className="invalid">
          {urlErrorMessage}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const onUrlChangeHandler = async (event) => {
    let value = event.target.value;
    setValue("url", value);
    await setTimeout(() => {
      validateURL(value);
    }, 450);
    setOldValue(value);
    return !showGenAiWarning;
  };

  const onBlurChangeHandler = () => {
    validateURL(value);
  };

  const genAiOptionsBox = (
    <div>
      <fieldset id="gen-ai-options-menu" className="gen-ai-options-menu">
        <div className="genai-button-div-top">
        <button 
            id="gen-ai-options-close"
            className="gen-ai-options-btn"
            aria-label="Close the gen ai options menu"
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              
              let currState = showGenAiOptions;
              let cssTrigger = !currState;
              setShowGenAiOptions(!currState);
    
              try {
                let divHolder = document.querySelector(".kw-div-elements-holder-selector");
                let divUrlAndGenAi = document.querySelector(".div-url-and-gen-ai-container-selector");
                if(cssTrigger){
                  divHolder.classList.add("show-genai-options-position");
                  divUrlAndGenAi.classList.add("div-url-and-gen-ai-container");
                }else{
                  divHolder.classList.remove("show-genai-options-position");
                  divUrlAndGenAi.classList.remove("div-url-and-gen-ai-container");
                }
              } catch (error) {}
            }}
            >x</button>
        </div>
        <div className="div-radiobtn-and-label">
          <input id="gen-ai-option-radio-btn-description" className="gen-ai-option-radio-btn" type="radio" name="optionRadioBtn" value="description" defaultChecked={true} />
          <label htmlFor="gen-ai-option-radio-btn-description">&nbsp;Generate Description</label>
        </div>

        <div className="div-radiobtn-and-label">
          <input id="gen-ai-option-radio-btn-keywords" className="gen-ai-option-radio-btn" type="radio" name="optionRadioBtn" value="keywords" />
          <label htmlFor="gen-ai-option-radio-btn-keywords">&nbsp;Extract Keywords</label>
        </div>
        <div className="gen-ai-options-separator-1"></div>
        <div className="genai-button-div">
          <button id="gen-ai-options-btn" className="gen-ai-options-btn" tabIndex={0} onClick={(e) => {
              e.preventDefault();

              let currLocationsSelected = null;
              let currRefinersSelected = null;
              let keywordsRadioButton = document.getElementById("gen-ai-option-radio-btn-keywords");
        
              currLocationsSelected = [...selectedLocation];
              currRefinersSelected = [...selectedRefinerPage];
        
              if (
                (currRefinersSelected.length === 0 ||
                currLocationsSelected.length === 0) && keywordsRadioButton.checked
              ) {
                setKwModalMsg("Please select a Location and a Refiner first.");
                setShowKwModal(true);
                return;
              }

              let greenLen = selectedKeywords.length;
              let redLen = keywordsForReview.length;
              let totalLen = parseInt(greenLen) + parseInt(redLen);
              if( totalLen >= 20 && keywordsRadioButton.checked){
                setKwModalMsg("Only 20 keywords are allowed");
                setShowKwModal(true);
                return;
              }
              openGenAiModal(e, disableGenIaBtn);
            }}>Generate</button>
        </div>
      </fieldset>
      <div className="gen-ai-options-separator-2-tc"></div>
        <div className="gen-ai-options-separator-top2-tc"></div>
    </div>
  );

  const genAIRocketBtn = (
    <>
      {showGenAiSpinner && !hideGenAiFeature ? (
        <GenAiLoadingSpinnerTC />
      ) : (
        <button
          className="gen-ai-rocket-button gen-ai-rocket-button-selector"
          onClick={(e) => {
            e.preventDefault();
            
            let currState = showGenAiOptions;
            let cssTrigger = !currState;
            setShowGenAiOptions(!currState);
  
            try {
              let divUrlAndGenAi = document.querySelector(".tcurl-and-rocket-styles");
              let genAiOptEl = null;
              setTimeout(() => {
                genAiOptEl = document.getElementById("gen-ai-options-menu");
                if(cssTrigger){
                  genAiOptEl.classList.add("show-genai-tc-options-position");
                  divUrlAndGenAi.classList.add("div-url-and-gen-ai-tc-container");
                }else{
                  genAiOptEl.classList.remove("show-genai-tc-options-position");
                  divUrlAndGenAi.classList.remove("div-url-and-gen-ai-tc-container");
                }
              }, 10);
              
            } catch (error) {}
          }}
          data-testid="gen-ai-rocket-button"
          id="gen-ai-rocket-button"
          aria-label="Generate Best Bet Description"
          aria-describedby="gen-ai-rocket-tooltip"
          disabled={disableGenIaBtn}
        >
          <img
            src={rocketImg()}
            alt="Rocket"
            role="tooltip"
            data-bs-html="true"
            data-bs-toggle="gen-ai-rocket-tooltip"
            data-bs-placement="right"
            data-bs-title={tooltipBody}
            id="rocket-image"
          />
        </button>
      )}
    </>
  );

  return (
    <>
      {showKwModal ? (
          <ModalCustom
            onClick={onClickModalHandler}
            onClose={onClickModalHandler}
            modal_title={"Info"}
            modal_msg={kwModalMsg}
            secondOption={false}
            btn_1_class={
              cssTheme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
          />
        ) : (
          <div></div>
      )}
      <div className="mb-3 url">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={titleURL.alt}
          tooltipText={titleURL.tooltip}
        />
        <label data-testid="topic-card-label" className="label-text">
          Topic URL
        </label>
        <div className="tcurl-and-rocket-styles">
          <input
            id="tc-form-url-input"
            type="text"
            data-testid="tc-input"
            className={`form-control ${validInputClass} tc-url-tag`}
            value={value}
            placeholder={"Enter a URL for this Topic Card (optional)"}
            onChange={onUrlChangeHandler}
            onBlur={onBlurChangeHandler}
            disabled={disableField}
          />
          <>{genAIRocketBtn}</>
          {showGenAiOptions && enableGenAiOptions() ? genAiOptionsBox : <></>}
        </div>

        {errorMsg()}
        {!hideGenAiFeature && showGenAiWarning && isUrlClean() && (
          <div
            id="genai-warning-msg-box"
            style={{
              visibility: "visible",
              color: "var(--light-mode-genaiwarning-text-color)"
            }}
            className="tc-genai-msg"
          >
            {genAiWarningMsg}
          </div>
        )}
      </div>
    </>
  );
};

export default TopicURL;
