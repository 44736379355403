import { useState, useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import HowToSteps from "./FormComponents/HowToSteps";
import "./HowToForm.css";
import { ThemeContext } from "../../../ThemeContext";
import { Tooltip } from "bootstrap";
import { formatDate } from "../../FormatDate/DateFormat";
import ModalCustom from "../../Modal/ModalCustom";
import HowToKwField from "./FormComponents/HowToKwField";
import HowToButtons from "./FormComponents/HowToButtons";
import StoreContext from "../../../store/storecontext";
import HowToDelegateField from "./FormComponents/HowToDelegateField";
import HowToLocationField from "./FormComponents/HowToLocationField";
import HowToRefinerField from "./FormComponents/HowToRefinerField";
import HowToTitleField from "./FormComponents/HowToTitleField";
import HowToURLField from "./FormComponents/HowToURLField";
import HowToCommentsField from "./FormComponents/HowToCommentsField";
import HowToValidations from "../../../validationClasses/HowToValidations";
import NotAuthorizedPage from "../../NotAuthorizedPage/NotAuthorizedPage";
import Preview from "../FormUI/Preview/Preview.jsx";
import HTPreview from "./FormComponents/Preview/HowToPreview";
import Synonyms from "../../Synonyms/Synonyms.jsx";
import CommentHistory from "../FormUI/CommentHistory/CommentHistory.jsx";
import Toast from "../../Toast/Toast";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest.jsx";

const HowToForm = () => {
  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);
  const store = useContext(StoreContext);
  const [theme] = useContext(ThemeContext);
  const [refinerPageInput, setRefinerPageInput] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [keywordInput, setKeywordInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [disableFormButtons, setDisableFormButtons] = useState(false);
  const [disableDraftButton, setDisableDraftButton] = useState(true);
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  const [formContainsData, setFormContainsData] = useState(false);
  const [stepsValue, setStepsValue] = useState({});
  const [acceptedTitle, setAcceptedTitle] = useState(null); //When loading a request avoid "repeated title" error
  const [notAuth, setNotAuth] = useState(null);
  const [showPreviewTrigger, setShowPreviewTrigger] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [clipboardPillType, setClipboardPillType] = useState("Keyword");
  const [notFoundMessage, setNotFoundMessage] = useState(false);

  const changeClipboardPillType = (pillType) => {
    setClipboardPillType(pillType);
  };

  const [keywordLimitModal, setKeywordLimitModal] = useState({
    show: false,
    msg: "Only 20 keywords are allowed"
  });
  const creationForm = {
    title: "",
    url: "",
    step: [{ step: "" }, { step: "" }, { step: "" }],
    aid: [],
    countries: [],
    keyword: [],
    keywordsForReview: [],
    delegates: [],
    userIdDelegates: [],
    comments: "",
    arrComments: [],
    history: [],
    organization: [],
    status: "New",
    howto_id: 0,
    howto_req_id: 0,
    yammerthreadid: null,
    submittedbyuserid: null,
    submittedbyenterpriseid: null,
    lastmodifiedby: [],
    creationdate: undefined
  };
  const loadedForm = { ...creationForm, supportContent: [] };

  const getForm = () => {
    if (idFromUrl) {
      return loadedForm;
    } else return creationForm;
  };

  const [form, setForm] = useState(getForm());

  useEffect(() => {
    //delete unparented tooltips from the dom
    let oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  }, []);

  const handleStepsChange = (value) => {
    setStepsValue(value);
  };

  const [originalReqData, setOriginalReqData] = useState({
    htData: null,
    htReqData: null,
    formData: null
  });

  const creationFormStates = {
    title: null,
    url: null,
    htStep: [{ state: null }, { state: null }, { state: null }],
    aid: null,
    countries: null,
    keyword: null,
    delegates: [],
    comments: null,
    comment: null
  };

  const loadedFormStates = { ...creationFormStates, htStep: [] };

  const getInputStates = () => {
    if (idFromUrl) {
      return loadedFormStates;
    } else return creationFormStates;
  };

  const [inputStates, setInputStates] = useState(getInputStates());

  const setFormData = async (htReqData) => {
    if ((formContainsData || htReqData === null) && !isLoading) return;

    let dataToDisplay = {
      title: "",
      url: "",
      step: [{ step: "" }],
      aid: [],
      countries: [],
      keyword: [],
      keywordsForReview: [],
      delegates: [],
      userIdDelegates: [],
      comments: "",
      arrComments: [],
      status: "New",
      howto_id: 0,
      howto_req_id: 0,
      yammerthreadid: null,
      yammerownerid: null,
      submittedbyuserid: null,
      lastmodifiedby: [],
      lastmodifieddateUTC: ""
    };

    dataToDisplay.title = htReqData.cleantitle;
    dataToDisplay.url = htReqData.cleanurl;
    dataToDisplay.step = [
      ...htReqData.cleansteps.map((step) => {
        return { step: step };
      })
    ];
    dataToDisplay.aid = [...htReqData.aid];
    dataToDisplay.countries = [...htReqData.cleancountry];
    dataToDisplay.keyword = [...htReqData.cleankeywordsraw];
    dataToDisplay.keywordsForReview =
      htReqData.cleaninvalidkeywordsraw !== undefined
        ? [...htReqData.cleaninvalidkeywordsraw]
        : [];
    dataToDisplay.delegates =
      htReqData.delegates !== undefined ? [...htReqData.delegates] : [];
    dataToDisplay.userIdDelegates = [];
    dataToDisplay.comments = "";
    dataToDisplay.arrComments = [...htReqData.comments];
    dataToDisplay.status = htReqData.cleanstatus;
    dataToDisplay.howto_id = htReqData.howto_id;
    dataToDisplay.howto_req_id = htReqData.howto_req_id;
    dataToDisplay.yammerthreadid = htReqData.yammerthreadid;
    dataToDisplay.submittedbyuserid = htReqData.requestedby;
    dataToDisplay.submittedbypeoplekey = htReqData.submittedbypeoplekey;
    dataToDisplay.lastmodifiedby = htReqData.lastmodifiedby;
    dataToDisplay.lastmodifieddateUTC = htReqData.lastmodifieddateUTC;
    dataToDisplay.creationdate = htReqData.cleancreationdate;

    await setFormStates(dataToDisplay);
    // set the form mode
    let isAdmin = store.state.isAdmin;
    let currEid = localStorage.getItem("searchtools.eid");
    let isOwner = currEid === dataToDisplay.submittedbyuserid;
    let delegateList = dataToDisplay.delegates.map((el) => {
      return el.eid;
    });
    let isDelegate = delegateList.includes(currEid);

    if (
      (isAdmin || isOwner || isDelegate) &&
      dataToDisplay.status !== "Inactive"
    ) {
      setIsFormReadOnly(false);
    } else {
      setIsFormReadOnly(true);
    }

    enableOrDisableButtons();

    return dataToDisplay;
  };

  const loadFormHandler = async (htReqID) => {
    if (formContainsData) return;

    let htQuery = null;
    let htData = null;
    let lastRequest = null;
    let htReqQuery = null;
    let htReqData = {};

    setIsLoading(true);
    setDisableFormButtons(true);

    htReqQuery = await store.services.howToService.GetHowToRequestById(htReqID);

    let totalHits = htReqQuery.data.hits.total.value;
    if (totalHits === 0) {
      setIsLoading(false);
      setNotFoundMessage(true);
    } else {
      if (htReqQuery.data.hits.hits.length > 1) {
        lastRequest = htReqQuery.data.hits.hits.pop();
        htReqData = lastRequest._source;
      } else {
        htReqData = htReqQuery.data.hits.hits[0]._source;
      }
      if (htReqData.cleanstatus === "Implemented" || htReqData.howto_id > 0) {
        htQuery = await store.services.howToService.GetHowToCardByID(
          htReqData.howto_id
        );

        if (htQuery.data.hits.hits.length > 1) {
          htData = htQuery.data.hits.hits.pop();
          htData = htData._source;
        } else {
          htData = htQuery.data.hits.hits[0]._source;
        }
      }

      let currentOriginalData = { ...originalReqData };
      let deepCopyHtData = JSON.parse(JSON.stringify(htData));
      let deepCopyHtReqData = JSON.parse(JSON.stringify(htReqData));
      let reqDataObj = await setFormData(deepCopyHtReqData);

      currentOriginalData.htData = deepCopyHtData;
      currentOriginalData.htReqData = deepCopyHtReqData;
      currentOriginalData.formData = JSON.parse(JSON.stringify(reqDataObj));

      await setOriginalReqData({ ...currentOriginalData });
      const noSteps = form.step.every((step) => step.step === "");
      if (parseInt(idFromUrl) > 0 && !formContainsData) {
        setAcceptedTitle(reqDataObj.title);
        await setForm(JSON.parse(JSON.stringify(reqDataObj)));
        setFormContainsData(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (parseInt(idFromUrl) > 0) {
      loadFormHandler(idFromUrl);
    }
  }, [idFromUrl]);

  useEffect(() => {
    if (isLoading) return;
    if (form.delegates.length === 0) {
      form.contacts = [];
      updateForm("contacts", []);
      return;
    }
    let delegatesNames = [];
    form.delegates.map((el) => {
      let delegate = el.eid || el;
      delegatesNames.push(delegate);
    });
    updateForm("contacts", delegatesNames);
  }, [form.delegates]);

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem("searchtools.config")) {
        let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));

        const refinerPageOptions = cfg[1].topiccard.application.map(
          (option) => {
            return (
              <option
                id={"ht-form-dropdown-refiner-page" + "-option-" + option.value}
                key={option.value}
                value={option.value}
              >
                {option.text}
              </option>
            );
          }
        );
        const locationOptions = cfg[1].topiccard.countries.map((option) => {
          return (
            <option
              id={"ht-form-dropdown-location" + "-option-" + option.value}
              key={option.value}
              value={option.value}
            >
              {option.text}
            </option>
          );
        });

        setRefinerPageInput(refinerPageOptions);
        setLocationInput(locationOptions);
      }
    })();
  }, []);

  const setKeywords = (state, action) => {
    switch (action.type) {
      case "reevaluateAll": {
        for (let index = 0; index < action.allKeywords.length; index++) {
          HowToValidations.validateKeyword(
            action.allKeywords[index],
            form.countries,
            form.aid,
            parseInt(idFromUrl)
          ).then(({ isKeywordValid, errorMessage }) => {
            if (isKeywordValid) {
              if (!form.keyword.includes(action.keyword)) {
                //This check is for dev envs, because of the extra render of StrictMode
                dispatchKeywordsReducer({
                  type: "addGreenKeyword",
                  keyword: action.allKeywords[index]
                });
              }
            } else {
              dispatchKeywordsReducer({
                type: "addRedKeyword",
                keyword: action.allKeywords[index]
              });
            }
          });
        }
        return { valid: [], invalid: [] };
      }
      case "addGreenKeyword": {
        const noRepeats = new Set(state.valid);
        noRepeats.add(action.keyword);
        return { valid: Array.from(noRepeats), invalid: state.invalid };
      } //Using a Set to avoid adding the same keyword
      case "addRedKeyword": //because of the <React.StrictMode> extra render
        const noRepeats = new Set(state.invalid);
        noRepeats.add(action.keyword);
        return { valid: state.valid, invalid: Array.from(noRepeats) };
    }
  };
  const [keywordsReducer, dispatchKeywordsReducer] = useReducer(setKeywords, {
    valid: [],
    invalid: []
  });
  useEffect(() => {
    if (!isLoading) {
      const revalidateKeywords = () => {
        const allKeywords = [...form.keyword, ...form.keywordsForReview];
        if (allKeywords.length > 0) {
          if (form.countries.length !== 0 && form.aid.length !== 0) {
            dispatchKeywordsReducer({
              type: "reevaluateAll",
              allKeywords: allKeywords
            });
          }
        }
      };
      revalidateKeywords();
    }
  }, [form.countries, form.aid]);
  useEffect(() => {
    if (!isLoading) {
      setForm({
        ...form,
        keyword: keywordsReducer.valid,
        keywordsForReview: keywordsReducer.invalid
      });
    }
  }, [keywordsReducer]);

  useEffect(() => {
    enableOrDisableButtons();
    enableOrDisableDraftBtn();
  }, [inputStates]);

  const enableOrDisableButtons = () => {
    let validationResult = isFormValid();
    setDisableFormButtons(!validationResult);
  };
  const enableOrDisableDraftBtn = () => {
    let spChRegex = store.services.urlCheckerService.textRegex;
    let titleNotEmpty = form.title !== "";
    let urlNotEmpty = form.url.length > 0;
    let stepsNotEmpty = form.step.find((step) => step.step !== "");
    let commentsNotEmpty = form.comments !== "";
    let refinerNotEmpty = form.aid.length > 0;
    let locationNotEmpty = form.countries.length > 0;
    let keywordsNotEmpty = form.keyword.length > 0;

    const anyFieldHasData =
      titleNotEmpty ||
      urlNotEmpty ||
      stepsNotEmpty ||
      refinerNotEmpty ||
      locationNotEmpty ||
      keywordsNotEmpty ||
      commentsNotEmpty;

    const noFieldHasSpChar =
      !form.title.match(spChRegex) &&
      !form.url.match(spChRegex) &&
      !form.step.find((step) => step.step.match(spChRegex)) &&
      !form.comments.match(spChRegex);
    setDisableDraftButton(!anyFieldHasData || !noFieldHasSpChar);
  };
  const isFormValid = () => {
    let isFormValid;
    let isTitleValid = form.title !== null && inputStates.title;
    let isURLValid = inputStates.url !== false && form.url.length > 0;
    let isStepValidation = inputStates.htStep.every(
      (stepState) => stepState.state
    );
    let isRefinerPageValid = form.aid.length > 0;
    let isLocationValid = form.countries.length > 0;
    let areKeywordsValid = form.keyword.length > 0;
    let isCommentValid = inputStates.comment !== false;

    isFormValid =
      isTitleValid &&
      isURLValid &&
      isStepValidation &&
      isLocationValid &&
      isRefinerPageValid &&
      areKeywordsValid &&
      isCommentValid;

    return isFormValid;
  };

  const updateStepState = (value, index = null) => {
    if (isLoading) return;
    let states = { ...inputStates };
    if (index === null) {
      states["htStep"] = value;
    } else {
      if (states["htStep"][index] !== undefined) {
        states["htStep"][index].state = value;
      }
    }
    setInputStates(states);
  };
  const setNewStepState = (value) => {
    if (isLoading) return;
    let statesArray = { ...inputStates };

    statesArray["htStep"].push({ state: value });
    setInputStates(statesArray);
  };

  const setFormStates = async (requestData) => {
    //Sets the input field states(valid or invalid)
    var stepsState = requestData.step.map((step) => {
      return { state: true };
    });
    const titleIsValid =
      HowToValidations.titleTooLong(requestData.title).isValid &&
      HowToValidations.invalidChars(requestData.title).isValid;
    let newFormStates = {
      title: titleIsValid,
      url: await validateURL(requestData.url),
      htStep: stepsState,
      aid: requestData.aid.length > 0,
      countries: true,
      keyword: true,
      delegates: requestData.delegates.length > 0 ? true : null,
      comments: null
    };
    setInputStates((prevStates) => newFormStates);
  };

  const [titleErrorMessage, setTitleErrorMessage] = useState("");

  const validateTitle = async (value) => {
    if (value.trim().length === 0 || value === null) {
      let states = { ...inputStates };
      states["title"] = null;
      setInputStates(states);
      return;
    }
    let states = { ...inputStates };
    var isInputValid = {};
    if (value !== acceptedTitle) {
      isInputValid = await HowToValidations.validateTitle(
        value,
        false,
        form.htrequestid
      );
      states["title"] = isInputValid.isValid;
      setTitleErrorMessage(isInputValid.errorMessage);
    } else {
      isInputValid =
        HowToValidations.invalidChars(value) ||
        HowToValidations.titleTooLong(value);

      states["title"] = isInputValid.isValid;
    }
    setInputStates(states);
    return isInputValid;
  };

  const validateURL = async (value) => {
    if (value.length === 0) {
      let states = { ...inputStates };
      states["url"] = null;
      setInputStates(states);
      return;
    }
    if (inputStates.url === null) {
      return disableFormButtons;
    }
    const isInputValid = await HowToValidations.validateURL(value);
    let states = { ...inputStates };
    states["url"] = isInputValid.isValid;
    setInputStates(states);
  };

  const updateField = (field, value) => {
    let formArray = { ...form };
    formArray[field] = value;
    setForm(formArray);
  };

  const updateFieldState = (field, value) => {
    let states = { ...inputStates };
    states[field] = value;
    setInputStates(states);
  };

  const updateSteps = (value) => {
    let formArray = { ...form };

    formArray["step"] = value;
    setForm(formArray);
  };

  const addSynonymAsKeyword = (synonym) => {
    if ([...form.keyword, ...form.keywordsForReview].length < 20) {
      setForm({
        ...form,
        keyword: [...form.keyword, synonym]
      });
      return true;
    } else {
      setKeywordLimitModal({ ...keywordLimitModal, show: true });
      return false;
    }
  };

  useEffect(() => {
    const tooltipClass = theme === "dark" ? "ht-tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  });

  const onSubmitHowTo = (event) => {
    event.preventDefault();
  };

  const updateForm = async (field, value) => {
    if (isLoading) return;
    let oldForm = { ...form };
    oldForm[field] = value;
    await setForm(oldForm);
  };

  const showSubmitModal = () => {
    setSubmitModalProps((prevModal) => ({ ...prevModal, show: true }));
  };
  const hideSubmitModal = () => {
    setSubmitModalProps((prevModal) => ({ ...prevModal, show: false }));
  };

  const setSubmitModalMsg = (msg) => {
    setSubmitModalProps((prevModal) => ({ ...prevModal, msg: msg }));
  };

  const setOnCloseSubmitModal = (method) => {
    setSubmitModalProps((prevModal) => ({ ...prevModal, onClose: method }));
  };
  const [submitModalProps, setSubmitModalProps] = useState({
    show: false,
    msg: "",
    onClose: hideSubmitModal
  });
  const closeKeywordsModal = () => {
    setKeywordLimitModal({ ...keywordLimitModal, show: false });
  };

  const showPreview = () => {
    setShowPreviewTrigger(true);
  };

  const closeHTPreview = () => {
    setShowPreviewTrigger(false);
  };

  return (
    <>
      {notFoundMessage ? (
        <NotFoundRequest />
      ) : (
        <div className="ht_container" id="ht_form" data-testid="howTo_form">
          {notAuth ? (
            <div className={"not_auth_div"}>
              <NotAuthorizedPage />
            </div>
          ) : (
            <form
              onSubmit={(event) => onSubmitHowTo(event)}
              className="control_group responsive-form-css"
            >
              {formContainsData && parseInt(idFromUrl) > 0 ? (
                <div
                  id="request-info-div"
                  className="ht-request-info-parent-div"
                >
                  <div className="ht-request-info-title-div">
                    How-to Request ID {form.howto_req_id}
                  </div>
                  <div className="request-info-child-div">
                    <div className="request-info-block">
                      Status: <p>{form.status}</p>
                    </div>
                    <div className="request-info-block">
                      Submit Time:{" "}
                      <p>
                        {formatDate(
                          originalReqData.htReqData.lastmodifieddateUTC
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {isLoading ? (
                <div className="overlay-spinner modal-fade" id="loading">
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-light-mode-purple"
                      role="status"
                    ></div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <HowToTitleField
                tooltipFor={"title-input"}
                value={form.title}
                setValue={updateForm}
                validateTitle={validateTitle}
                titleErrorMessage={titleErrorMessage}
                updateValue={updateField}
                inputState={inputStates}
                setInputState={updateFieldState}
                disableField={isFormReadOnly}
              />
              <HowToURLField
                tooltipFor={"url-input"}
                value={form.url}
                setValue={updateForm}
                updateValue={updateField}
                inputState={inputStates}
                setInputState={updateFieldState}
                validateURL={validateURL}
                disableField={isFormReadOnly}
              />
              <HowToSteps
                tooltipFor={"steps-input"}
                setValue={updateForm}
                onStepChange={handleStepsChange}
                field="htStep"
                state={inputStates}
                setState={updateStepState}
                setStepsArray={updateSteps}
                setNewStepState={setNewStepState}
                value={form.step}
                disableField={isFormReadOnly}
              />
              <HowToLocationField
                tooltipFor={"location-dropdown"}
                locationInput={locationInput}
                selectedLocation={form.countries}
                selectedKeywords={form.keyword}
                selectedRefinerPage={form.aid}
                keywordsForReview={form.keywordsForReview}
                updateValue={updateForm}
                setInputState={updateFieldState}
                disableField={isFormReadOnly}
                requestStatus={form.status}
              />
              <HowToRefinerField
                tooltipFor={"refiner-dropdown"}
                refinerPageInput={refinerPageInput}
                selectedRefinerPage={form.aid}
                selectedKeywords={form.keyword}
                selectedLocation={form.countries}
                keywordsForReview={form.keywordsForReview}
                updateValue={updateForm}
                setInputState={updateFieldState}
                disableField={isFormReadOnly}
                requestStatus={form.status}
              />
              <HowToKwField
                tooltipFor={"keywords-input"}
                keywordInput={keywordInput}
                selectedKeywords={form.keyword}
                setKeywordInput={setKeywordInput}
                updateFormField={updateForm}
                selectedRefinerPage={form.aid}
                selectedLocation={form.countries}
                inputState={inputStates}
                setInputState={updateFieldState}
                keywordsForReview={form.keywordsForReview}
                disableField={isFormReadOnly}
                revalidateForm={enableOrDisableButtons}
                htrequest={form.howto_req_id}
                requestStatus={form.status}
                changeClipboardPillType={changeClipboardPillType}
              />
              <Synonyms
                keywords={form.keyword}
                keywordsForReview={form.keywordsForReview}
                isFormReadOnly={isFormReadOnly}
                countries={form.countries}
                acronyms={form.aid}
                service={store.services.howToService}
                addKeyword={addSynonymAsKeyword}
                Validations={HowToValidations}
                formName="howto"
                reqid={form.howto_req_id}
              />
              <HowToDelegateField
                tooltipFor={"delegates-input"}
                selectedDelegates={form.contacts}
                placeholderText="Delegates who can edit this request (optional)"
                updateValue={updateForm}
                inputState={inputStates}
                setInputState={() => {}}
                field="delegates"
                contactArray={form.delegates}
                disableField={isFormReadOnly}
                requestStatus={form.status}
                searchToolsService={store.services.searchToolsService}
                changeClipboardPillType={changeClipboardPillType}
              />
              <HowToCommentsField
                tooltipFor={"comments-input"}
                setInputState={updateFieldState}
                disableField={isFormReadOnly}
                updateValue={updateForm}
              />
              {formContainsData && parseInt(idFromUrl) > 0 ? (
                <>
                  {form.arrComments.map((comment, idx) => (
                    <div className="comments" key={idx}>
                      <CommentHistory
                        user={comment.user}
                        date={comment.dateUTC}
                        comment={comment.comment}
                        commentCSS={"comment-ht"}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
              {submitModalProps.show ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={hideSubmitModal}
                  onClose={submitModalProps.onClose}
                  modal_msg={submitModalProps.msg}
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize=""
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              {showPreviewTrigger && (
                <Preview
                  preview={<HTPreview preview={previewContent} />}
                  closePreview={closeHTPreview}
                />
              )}
              {keywordLimitModal.show ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClose={closeKeywordsModal}
                  modal_msg={keywordLimitModal.msg}
                  modalSize=""
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              <Toast
                toastText={clipboardPillType + " copied to the clipboard!"}
                toastKey="copyToClipboard"
              />
            </form>
          )}
          {!isFormReadOnly && (
            <HowToButtons
              isAdmin={store.state.isAdmin}
              isFormReadOnly={isFormReadOnly}
              disableButton={disableFormButtons}
              disableDraftButton={disableDraftButton}
              form={form}
              setIsLoading={setIsLoading}
              originalData={originalReqData}
              idFromUrl={idFromUrl}
              openPreview={(e) => showPreview(e)}
              setPreviewContent={setPreviewContent}
              modal={{
                setMsg: setSubmitModalMsg,
                show: showSubmitModal,
                setOnClose: setOnCloseSubmitModal
              }}
              yammerService={store.services.yammerService}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HowToForm;
