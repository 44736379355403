import { useState } from "react";

const FormButtons = ({
  reqType,
  formName,
  disableFormButtons,
  disableDraftButton,
  cssTheme,
  isAdmin,
  btnBools,
  bntActionsHandler
}) => {
  // this is the buttons component, it will provide the ui elements
  // and the hooks to execute the methods in the wrapper.
  // enable the buttons according to the states of the form

  const primaryBtn =
    cssTheme === "dark" ? "dark-mode-purple" : "light-mode-purple";

  let htmlFormButtons = {
    previewBtn: (
      <button
        id={`${reqType}-preview-button`}
        data-testid={`data-testid-${reqType}-preview-button`}
        className="btn btn-secondary"
        disabled={disableFormButtons}
        onClick={(e) => {
          bntActionsHandler(e, "Show Preview Btn");
        }}
      >
        Preview
      </button>
    ),
    saveChangesAdminBtn: (
      <button
        id={`${reqType}-save-changes-btn`}
        data-testid={`data-testid-${reqType}-save-changes-btn`}
        className={`btn btn-secondary ${primaryBtn} `}
        onClick={(e) => {
          bntActionsHandler(e, "Save Changes Btn");
        }}
        disabled={disableFormButtons}
      >
        Save Changes
      </button>
    ),
    saveChangesRegularBtn: (
      <button
        id={`${reqType}-save-changes-owner-btn`}
        data-testid={`data-testid-${reqType}-save-changes-owner-btn`}
        className={`btn btn-lg btn-primary owner-css-save-changes-btn `}
        onClick={(e) => {
          bntActionsHandler(e, "Save Changes Btn");
        }}
        disabled={disableFormButtons}
      >
        Save Changes
      </button>
    ),
    implementBtn: (
      <button
        id={`${reqType}-implement-button`}
        data-testid={`data-testid-${reqType}-implement-button`}
        className={`btn btn-primary ${primaryBtn}`}
        onClick={(e) => {
          bntActionsHandler(e, "Implement Request Btn");
        }}
        disabled={disableFormButtons}
      >
        Implement
      </button>
    ),
    saveDrafBtn: (
      <button
        id={`${reqType}-save-draft-button`}
        data-testid={`data-testid-${reqType}-save-draft-button`}
        className="btn btn-lg btn-secondary save-draft "
        onClick={(e) => {
          bntActionsHandler(e, "Submit Draft Btn");
        }}
        disabled={disableDraftButton}
      >
        Save Draft
      </button>
    ),
    requestNewFormBtn: (
      <button
        id={`${reqType}-request-button`}
        data-testid={`data-testid-${reqType}-request-button`}
        className={`btn btn-primary ${primaryBtn}`}
        onClick={(e) => {
          bntActionsHandler(e, "Submit New Request Btn");
        }}
        disabled={disableFormButtons}
      >
        {`Request ${formName}`}
      </button>
    ),
    rejectBtn: (
      <button
        id={`${reqType}-reject-button`}
        data-testid={`data-testid-${reqType}-reject-button`}
        className={`btn btn-outline-danger red-form-btn`}
        onClick={(e) => {
          bntActionsHandler(e, "Reject Btn");
        }}
        disabled={disableFormButtons}
      >
        Reject
      </button>
    ),
    cancelRequestBtn: (
      <button
        id={`${reqType}-cancel-request-button`}
        data-testid={`data-testid-${reqType}-cancel-request-button`}
        className={`btn btn-outline-danger red-form-btn`}
        onClick={(e) => {
          bntActionsHandler(e, "Cancel Request Btn");
        }}
        disabled={disableFormButtons}
      >
        Cancel Request
      </button>
    ),
    submitForRemovalBtn: (
      <button
        id={`${reqType}-submit-for-removal-button`}
        data-testid={`data-testid-${reqType}-submit-for-removal-button`}
        className={`btn btn-outline-danger red-form-btn`}
        onClick={(e) => {
          bntActionsHandler(e, "Submit For Removal Btn");
        }}
        disabled={disableFormButtons}
      >
        Submit for Removal
      </button>
    ),
    requestUpdateBtn: (
      <button
        id={`${reqType}-request-update-button`}
        data-testid={`data-testid-${reqType}-request-update-button`}
        className={`btn btn-primary ${primaryBtn}`}
        onClick={(e) => {
          bntActionsHandler(e, "Request Update Btn");
        }}
        disabled={disableFormButtons}
      >
        Request Update
      </button>
    ),
    removeRequestBtn: (
      <button
        id={`${reqType}-remove-request-button`}
        data-testid={`data-testid-${reqType}-remove-request-button`}
        className={`btn btn-outline-danger red-form-btn`}
        onClick={(e) => {
          bntActionsHandler(e, "Remove Request Btn");
        }}
        disabled={disableFormButtons}
      >
        {`Remove ${formName}`}
      </button>
    ),
    compareBtn: (
      <button
        id={`${reqType}-compare-table-button`}
        data-testid={`data-testid-${reqType}-compare-table-button`}
        className="btn btn-secondary"
        onClick={(e) => {
          bntActionsHandler(e, "Compare Table Btn");
        }}
        disabled={disableFormButtons}
      >
        Compare
      </button>
    )
  };

  let showRejectButton = btnBools.bShowRejectButton;
  let showCompareButton = btnBools.bShowCompareButton;
  let showPreviewButton = btnBools.bShowPreviewButton;
  let showSaveChangesButton = btnBools.bShowSaveChangesButton;
  let showCancelRequestButton = btnBools.bShowCancelRequestButton;
  let showRemoveButton = btnBools.bShowRemoveButton;
  let showDraftButton = btnBools.bShowDraftButton;
  let showRequestUpdateBtn = btnBools.bShowRequestUpdateBtn;
  let showSubmitForRemovalBtn = btnBools.bShowSubmitForRemovalBtn;
  let showRequestNewFormBtn = btnBools.bShowRequestNewFormBtn;

  let adminButtons = (
    <div className="form-group buttons-group form-buttons-div form-buttons-div-selector">
      {showRejectButton ? htmlFormButtons.rejectBtn : <></>}
      {showRemoveButton ? htmlFormButtons.removeRequestBtn : <></>}
      {showCompareButton ? htmlFormButtons.compareBtn : <></>}
      {showPreviewButton ? htmlFormButtons.previewBtn : <></>}
      {showSaveChangesButton ? htmlFormButtons.saveChangesAdminBtn : <></>}
      {htmlFormButtons.implementBtn}
    </div>
  );

  let regularUserButtons = (
    <div className="form-group buttons-group form-buttons-div form-buttons-div-selector">
      {showSubmitForRemovalBtn ? htmlFormButtons.submitForRemovalBtn : <></>}
      {showCancelRequestButton ? htmlFormButtons.cancelRequestBtn : <></>}
      {showCompareButton ? htmlFormButtons.compareBtn : <></>}
      {showPreviewButton ? htmlFormButtons.previewBtn : <></>}
      {htmlFormButtons.saveDrafBtn}
      {showSaveChangesButton ? htmlFormButtons.saveChangesRegularBtn : <></>}
      {showRequestUpdateBtn ? htmlFormButtons.requestUpdateBtn : <></>}
      {showRequestNewFormBtn ? htmlFormButtons.requestNewFormBtn : <></>}
    </div>
  );

  return <>{isAdmin ? adminButtons : regularUserButtons}</>;
};

export default FormButtons;
