import BaseService from "./BaseService";

class AILogsService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  SaveAIUsage(data) {
    let self = this;
    return self.axios
      .post(`${self.baseUrl}savegenaiusage`, data, this.config)
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error in SaveAIUsage", error);
        throw error;
      });
  }
}

export default AILogsService;
