import BaseService from "./BaseService";

class SynonymService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }
  regex = new RegExp(/["\\]/);
  GetSynonyms() {
    let self = this;
    return self.axios.get(`${self.baseUrl}synonyms`, this.config);
  }
  async GetSynonymsByKeyword(keyword) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}synonyms?keyword=${encodeURIComponent(keyword)}`,
      this.config
    );
  }
  async GetSynonymsByKeywords(keywords, abortController = null) {
    let self = this;
    if (abortController !== null) {
      this.config = {
        ...this.config,
        signal: abortController.signal
      };
    }
    let queryResult = await self.axios.get(
      `${self.baseUrl}getsynonymskeywords?keywords=${encodeURIComponent(
        keywords
      )}`,
      this.config
    );

    this.config = {
      ...this.config,
      signal: null
    };

    return queryResult;
  }
  async GetPendingSynonymsReq(reqId = 0, id = 0) {
    let self = this;
    return await self.axios.get(
      `${self.baseUrl}synonyms/${reqId}?id=${id}`,
      this.config
    );
  }
  async GetImplementedSynonymsById(id) {
    let self = this;
    return await self.axios.get(
      `${self.baseUrl}synonyms/implemented/${id}`,
      this.config
    );
  }

  async SaveSynonyms(body, requestid) {
    let self = this;
    if (requestid) {
      return self.axios.post(
        `${self.baseUrl}synonyms/request/${requestid}`,
        body,
        this.config
      );
    } else {
      return self.axios.post(
        `${self.baseUrl}synonyms/request/`,
        body,
        this.config
      );
    }
  }
  async Update(body, requestid, id) {
    if (id) {
      return this.axios.post(
        `${this.baseUrl}synonyms/request/${requestid}?id=${id}`,
        body,
        this.config
      );
    } else {
      return this.axios.post(
        `${this.baseUrl}synonyms/request/${requestid}`,
        body,
        this.config
      );
    }
  }

  async DeleteSynonyms(requestBody, id) {
    let self = this;
    this.config.data = requestBody;
    return await self.axios.delete(
      `${self.baseUrl}synonyms/${id}`,
      this.config
    );
  }
  GetRequests(from, size, filteredBy, sortField, sortBy) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}synonyms/requests?from=${from}&size=${size}&filterBy=${filteredBy}&sortField=${sortField}&sortBy=${sortBy}`,
      this.config
    );
  }

  GetSynonymsByEID(eid) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}synonyms/requests/owner/${eid}`,
      self.config
    );
  }

  UpdateTemplate(request) {
    let self = this;
    return self.axios.put(
      `${self.baseUrl}synonyms/template`,
      request,
      self.config
    );
  }
  GetActiveSynonyms() {
    let self = this;
    return self.axios({
      url: `${self.baseUrl}synonyms/export/active`,
      method: "GET",
      headers: this.config.headers
    });
  }
}
export default SynonymService;
