const FormSpinners = () => {
  return (
    <>
      {/* loading spinner component:  
            now, both the spinner and the form will be on the dom.
            thanks to the new property .style.visibility ="visible" or "hidden"
            one can hide/show the form or the spinner without the need
            to unmount the components from the dom,
            making it easier to load the data on the components.
            the class "main-form-loading-spinner" will be used as the css selector
            */}
      <div
        className="d-flex justify-content-center loading-spinner-main-div main-form-loading-spinner"
        style={{ visibility: "hidden" }}
      >
        <div className="spinner-border text-light-mode-purple" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      {/* overlay spinner, this one is for the case when the data is being saved into the index */}
      <div
        className="overlay-spinner overlay-spinner-selector"
        style={{ visibility: "hidden" }}
      >
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="spinner-border text-light-mode-purple" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSpinners;
