import { useContext, useState, useEffect } from "react";
import StoreContext from "../../../../store/storecontext";
import { ThemeContext } from "../../../../ThemeContext";
import React from "react";

const SynonymsButtons = ({
  isBtnDisabled,
  form,
  submit,
  implementSynonyms,
  triggerModal
}) => {
  //The buttons commented are the placeholder of the US.
  const store = useContext(StoreContext);
  const [theme] = useContext(ThemeContext);
  const isAdmin = store.state.isAdmin;
  const primaryBtn = `${theme}-mode-purple`;

  const hasRequestID = parseInt(form.request_id) > 0;
  const hasImplementedID = parseInt(form.synonyms_implemented_id) > 0;

  const implementBtnRqstStatus = "Implemented";
  const implementBtnRqstType =
    form.status === "Implemented" ? "Update" : form.request_type;

  const adminRemove = (e) => {
    e.preventDefault();
    triggerModal(
      "Do you want to remove this Synonym?",
      () => implementSynonyms("New", "Removal", e),
      "Remove Synonym",
      "Cancel"
    );
  };

  const requestButton = () => {
    if (!isAdmin && !hasRequestID && !hasImplementedID) {
      return (
        <button
          type="button"
          id="requestSynonymBtn"
          data-testid="req-synonym-btn"
          className="btn btn-primary synonyms-buttons request-btn"
          disabled={isBtnDisabled}
          onClick={(e) => submit("New", form.request_type, e)}
        >
          Request Synonym
        </button>
      );
    } else return null;
  };
  const implementButton = () => {
    if (isAdmin && (form.status === "New" || form.status === "Implemented")) {
      return (
        <button
          className={`btn btn-primary synonyms-buttons ${primaryBtn}`}
          id="synonymImplementBtn"
          data-testid="syn-impl-btn"
          onClick={(e) => {
            implementSynonyms(implementBtnRqstStatus, implementBtnRqstType, e);
          }}
          disabled={isBtnDisabled}
        >
          Implement
        </button>
      );
    } else return null;
  };
  const saveChangesButton = () => {
    if (
      (isAdmin && hasRequestID && form.status !== "Implemented") ||
      (!isAdmin && (hasRequestID || hasImplementedID) && form.status === "New")
    ) {
      return (
        <button
          className="save-changes-btn"
          data-testid="syn-save-btn"
          id="synonymSaveChangesBtn"
          onClick={(e) => submit(form.status, form.request_type, e)}
          disabled={isBtnDisabled}
        >
          Save Changes
        </button>
      );
    } else return null;
  };
  const submitRemovalButton = () => {
    if (!isAdmin && form.status === "Implemented") {
      return (
        <button
          className={`btn btn-outline-danger remove-btn`}
          id="synonymSubmitForRemovalBtn"
          data-testid="syn-subm-rem-btn"
          onClick={(e) => submit("New", "Removal", e)}
          disabled={isBtnDisabled}
        >
          Submit for Removal
        </button>
      );
    }
  };
  const requestUpdateButton = () => {
    if (!isAdmin && form.status === "Implemented") {
      return (
        <button
          className={`btn btn-primary synonyms-buttons ${primaryBtn}`}
          id="synonymRequestUpdateBtn"
          data-testid="syn-req-upd-btn"
          onClick={(e) => submit("New", "Update", e)}
          disabled={isBtnDisabled}
        >
          Request Update
        </button>
      );
    }
  };
  const cancelReqButton = () => {
    if (!isAdmin && form.status === "New" && form.request_id > 0) {
      return (
        <button
          className="btn btn-outline-danger remove-btn"
          id="synonymCancelBtn"
          data-testid="syn-cancel-btn"
          onClick={(e) => submit("Cancelled", form.request_type, e)}
          disabled={isBtnDisabled}
        >
          Cancel Request
        </button>
      );
    }
  };

  const rejectReqButton = () => {
    if (isAdmin && form.status === "New" && form.request_id > 0) {
      return (
        <button
          className="btn btn-outline-danger remove-btn"
          id="synonymRejectBtn"
          data-testid="syn-rejected-btn"
          onClick={(e) => submit("Not Approved", form.request_type, e)}
          disabled={isBtnDisabled}
        >
          Reject
        </button>
      );
    }
  };

  const removeButton = () => {
    if (isAdmin && form.status === "Implemented") {
      return (
        <button
          className="btn btn-outline-danger remove-btn"
          id="synonymRemoveBtn"
          data-testid="syn-remove-btn"
          onClick={adminRemove}
          disabled={isBtnDisabled}
        >
          Remove
        </button>
      );
    }
  };
  const htmlFormButtons = {
    //Submit removal at the left, request update at the right
    reqCancelledBtn: cancelReqButton(),
    submitForRemovalBtn: submitRemovalButton(),
    requestUpdateBtn: requestUpdateButton(),

    //Remove/Reject ath the left, Save changes at center(or right if there is no request/implement), Request/Implement at the right
    rejectButton: rejectReqButton(),
    removeRequestBtn: removeButton(),
    saveChangesBtn: saveChangesButton(),
    requestButton: requestButton(),
    implementBtn: implementButton()
  };

  return (
    <div className="form-group button-group synonyms-buttons-container">
      {Object.values(htmlFormButtons).map((button, index) => (
        <React.Fragment key={index}>{button}</React.Fragment>
      ))}
    </div>
  );
};
export default SynonymsButtons;
