import BaseService from "./BaseService";

class TopicCardService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  GetMyTopicCardRequests(eid) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}topiccard/requests/owner/${eid}`,
      self.config
    );
  }

  GetTopicCardByID(id, isTcReq = null) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}topiccard/${id}?isTcReq=${isTcReq}`,
      this.config
    );
  }

  GetTopicCardRequest(id) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}topiccard/request/${id}`,
      this.config
    );
  }

  async GetTopicCardByTitle(title) {
    let self = this;
    return await self.axios.get(
      `${self.baseUrl}topiccard?title=${encodeURIComponent(title)}`,
      this.config
    );
  }
  GetTopicCardsByKeyword(keyword, aid, countries, abortController = null) {
    let self = this;
    if (abortController !== null) {
      this.config = {
        ...this.config,
        signal: abortController.signal
      };
    }
    return self.axios.get(
      `${self.baseUrl}topiccards?keyword=${encodeURIComponent(
        keyword
      )}&aid=${aid}&countries=${countries}`,
      this.config
    );
  }
  SaveTopicCard(body, id, tcid) {
    let self = this;
    this.config.headers.eid = localStorage.getItem("searchtools.eid");
    this.config.headers.pk = localStorage.getItem("searchtools.pk");
    if (id) {
      return self.axios.post(
        `${self.baseUrl}topiccard/request/${id}?tcid=${tcid}`,
        body,
        this.config
      );
    } else {
      return self.axios.post(
        `${self.baseUrl}topiccard/request`,
        body,
        this.config
      );
    }
  }
  GetTCDataMetrics(fromDate, toDate) {
    return this.axios.get(
      `${this.baseUrl}tc/metrics?fromDate=${fromDate}&toDate=${toDate}`,
      this.config
    );
  }

  GetRequests(from, size, filterBy, sortField, sortBy) {
    return this.axios.get(
      `${this.baseUrl}topiccard/requests?from=${from}&size=${size}&filterBy=${filterBy}&sortField=${sortField}&sortBy=${sortBy}`,
      this.config
    );
  }
  GenerateHtmlContent(reqData) {
    return this.axios.post(
      `${this.baseUrl}topiccard/preview`,
      reqData,
      this.config
    );
  }
  UpdateTopicCard(body, id, tcid) {
    this.config.headers.eid = localStorage.getItem("searchtools.eid");
    this.config.headers.pk = localStorage.getItem("searchtools.pk");
    if (tcid) {
      return this.axios.put(
        `${this.baseUrl}topiccard/request/${id}?tcid=${tcid}`,
        body,
        this.config
      );
    } else {
      return this.axios.put(
        `${this.baseUrl}topiccard/request/${id}`,
        body,
        this.config
      );
    }
  }

  GetTopicCardsRequests(from, size, filterBy, sortField, sortBy) {
    return this.axios.get(
      `${this.baseUrl}topiccard/requests?from=${from}&size=${size}&filterBy=${filterBy}&sortField=${sortField}&sortBy=${sortBy}`,
      this.config
    );
  }
}
export default TopicCardService;
