import BaseService from "./BaseService";

class ActiveContent extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  GetActiveContent(formToRequest) {
    let self = this;
    return self.axios({
      url: `${self.baseUrl}export/active?contentType=${formToRequest}`,
      method: "GET",
      headers: this.config.headers
    });
  }
}
export default ActiveContent;
